import { Component, Output, Input, EventEmitter  } from '@angular/core';
import { HttpClient, HttpRequest, HttpEventType, HttpResponse } from '@angular/common/http'

@Component({
  selector: 'app-upload-file',
  templateUrl: './upload-file.component.html',
  styleUrls: ['./upload-file.component.css']
})

export class UploadFileComponent {
  @Output() fileEvent = new EventEmitter();

  files: any = [];
  constructor(private http: HttpClient) {
  }


  uploadFile(event) {
    for (let index = 0; index < event.length; index++) {
      const element = event[index];
      this.files.push(element)
    }
    this.fileEvent.emit(this.files);
    
  }

  upload() {
    const formData = new FormData();
    formData.append(this.files[0].name, this.files[0]);

    const uploadReq = new HttpRequest('POST', `api/project/saveProject/`, formData, {
      reportProgress: true,
    });

    this.http.request(uploadReq).subscribe(event => {

      //if (event.type === HttpEventType.UploadProgress) {
      //  selectedFile.progress = Math.round(100 * event.loaded / event.total);
      //}
      //else if (event.type === HttpEventType.Response)
      //  selectedFile.message = event.body.toString();
    });
  }

  deleteAttachment(index) {
    this.files.splice(index, 1)
  }
}   
