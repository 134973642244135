export enum ProjectDetailTypeEnum {
  Make = 0,
  Buy = 1,
  Pcba = 2,
  Cable = 3,
  Unidentified = 4,
  NonStandardBuy = 5,
  Other = 6,
  Screw = 7,
}
