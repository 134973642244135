import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpEventType } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { ExportPdfModel } from '../models/export/export-pdf';

@Injectable({
  providedIn: 'root'
})
export class ExportService {
 

  myAppUrl = '';

  constructor(private http: HttpClient, private router: Router) {
  }

  exportExcelDetails(projectId: number): Observable<any> {
    //return this.http.get(`/api/export/exportDetailsExcel/${projectId}`)

    return this.http.get(`/api/export/exportDetailsExcel/${projectId}`, {
      responseType: 'blob',
      observe: 'response'
    }).pipe(
      map(
        (res) => {
          var blob = new Blob([res.body], { type: res.body.type })
          return blob;
        }));
  }

  downloadFile(projectDetailId: number, fileType: number) {
    return this.http.get(`/api/export/downloadFile/${projectDetailId}/${fileType}`, {
      responseType: 'blob',
      observe: 'response'
    }).pipe(
      map(
        (res) => {
          var blob = new Blob([res.body], { type: res.body.type })
          return blob;
        }));
  }

  exportPdfDetails(projectId: number): Observable<ExportPdfModel> {
    return this.http.get(`/api/export/exportDetailsPdf/${projectId}`).pipe(
      map(
        (res: ExportPdfModel) => {
          return res;
        }));
  }

  getDownloadFileName(projectDetailId: number, fileType: number) {
    return this.http.get<any>(`/api/export/getDownloadFileName/${projectDetailId}/${fileType}`)
      .pipe(map(response => {
        return response;
      }));
  }
}
