import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { first } from 'rxjs/operators';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'app-registered',
  templateUrl: './registered.component.html',
  styleUrls: ['./registered.component.css', '../login-common.css']
})
export class RegisteredComponent implements OnInit {

  email: string;
  isResendEmail = false;
  constructor(private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private authService: AuthService) { }

  ngOnInit() {
    this.email = localStorage.getItem("registerEmail");
  }

  resendEmail() {
    this.authService.resendConfirmEmail({ email: this.email }).subscribe(x => {
      this.isResendEmail = true;
    });
  }

}
