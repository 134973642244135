export enum IssueTypeEnum {
  text = 0,
  uploadFile = 1,
  noMakeMaterial = 2,
  noVendor = 3,
  noBuyPartNo = 4,
  unidentified = 5,
  screewMissedValues = 6,
  pcbaNotFinished = 7,
  drawingIsRequired = 8
}
