import { Component, HostListener, OnInit } from '@angular/core';
import { ProjectService } from '../services/project.service';
import { first } from 'rxjs/operators';
import { SignalRService } from '../services/signal-r.service';
import { Router } from '@angular/router';
import { User } from '../models/user';
import { AuthService } from '../services/auth.service';
import { Subscription } from 'rxjs/internal/Subscription';
import { DownloadProgressService } from '../services/download-service';

declare var $: any;

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})

export class DashboardComponent implements OnInit {
  interval = 1000;
  dto: any;
  pageLoaded: boolean;
  filter: any = { name: "", sort: "date desc" };
  filterApplied: any = { name: "", sort:"date desc" };
  userData = new User();
  userDataSubscription: any;
  currentProject: any;
  loading: boolean = false;
  processingPaging: boolean = false;
  refreshProjects: Subscription;
  scrollProjects: Subscription;
  progressTotal = 70;
  constructor(private authService: AuthService, private projectService: ProjectService,
    private router: Router, private signalr: SignalRService, private downloadService: DownloadProgressService) {

    this.userDataSubscription = this.authService.userData.asObservable().subscribe(data => {
      this.userData = data;
    });
  }
  

  ngOnDestroy() {
    if (this.refreshProjects != null) {
      this.refreshProjects.unsubscribe();
    }
    if (this.scrollProjects != null) {
      this.scrollProjects.unsubscribe();
    }
  }

  filterProjects() {
    this.filterApplied.name = this.filter.name;
    this.filterApplied.sort = this.filter.sort;
    this.getProjects(true, 0, 30, null);
  }

  filterProjectsRefresh() {
    this.filterApplied.name = "";
    this.filterApplied.sort = "";
    this.filter.name = "";
    this.filter.sort = "date desc";
    this.getProjects(true, 0, 30, null);
  }

  ngOnInit() {

    
    this.userDataSubscription = this.authService.userData.asObservable().subscribe(data => {
      this.userData = data;
      this.signalr.startConnection(this.userData.userId);
      var firstload = true;
      if (this.refreshProjects != null) { this.refreshProjects.unsubscribe(); }
      this.refreshProjects = this.signalr.addListenerRefreshProjects().subscribe((data: any) => {
        
        if (this.userData.userId) {
          this.getProjects(data == null || firstload, 0, data ? data.length : 30, data);
          firstload = false;
        }
      });
    });

    this.scrollProjects = this.signalr.addScrollEvent().subscribe((data: any) => {
      if (data && (data.target.scrollTop + data.target.offsetHeight) * 1.5 > data.target.scrollHeight)
        if (this.userData.userId) {
          if (this.dto) {
            this.getProjects(false, this.dto.length, 10, null);
          }
        }

    });
  }

  getProjects(isShowLoading: boolean, skip, take, ids) {

    if (isShowLoading) {
      this.loading = true;
      this.pageLoaded = false;
    }
    else if (this.processingPaging || this.pageLoaded) {
      return
    }
    if (ids != null && this.dto == null) {
      take = 30
      ids = null;
    }
    this.processingPaging = true;
    this.projectService.getProjects(this.filterApplied.name, this.filterApplied.sort, skip, take, ids)
      .pipe(first())
      .subscribe(
        (result: any) => {
          this.loading = false;
          this.processingPaging = false;
          if (result.projects.length == 0 && skip > 0) {
            this.pageLoaded = true;
          }
          if ((isShowLoading && skip == 0 && ids == null) || (this.dto == null)) {
            this.dto = result.projects;
          }
          else {
            var index = skip;
            for (var item in result.projects) {
              var project = result.projects[item];
              var existProject = this.dto.filter(x => x.id == project.id);
              if (existProject.length > 0) {
                var existIndex = this.dto.indexOf(existProject[0]);
                this.dto[existIndex] = project
              }
              else {
                this.dto[index++] = result.projects[item];
              }
            }
          }

        },
        () => {
          this.loading = false;
          this.processingPaging = false;
        });
  }

  getProjectTimeout() {
    this.interval += 10;
    setTimeout(() => {
      if (this.router.routerState.snapshot.url === '/dashboard') {
        this.getProjects(false, 0, 30, null);
      }
    }, this.interval);
  }


  goToCreateProject() {
    this.router.navigate(['/create-project']);
    localStorage.setItem('projectId', null);
  }

  goToEditPage(id) {
    if (id) {

      this.projectService.getProjectFileZipped(+id).subscribe(
        (data) => {
          this.downloadService.getStls(data.stlFiles);
          this.downloadService.getImages(data.imageFiles);
        });

      this.projectService.getProjectVisibleScreens(id).subscribe(
        (result) => {
          this.projectService.updateProjectVisibleScreens(result);
          this.router.navigate(['/edit-project/' + id]);
          localStorage.setItem('projectId', id);
        },
        (error) => {
        });
    }
  }

  deleteProject() {
    if (this.currentProject && this.currentProject.id) {
      this.projectService.deleteProject(this.currentProject.id).subscribe((data: any) => {
        this.dto = this.dto.filter(x => x.id != this.currentProject.id);
      });
    }
  }

  hideModal(): void {
    this.currentProject = null;
    $("#confirmModal").modal('hide');
  }

  showModal(project): void {
    this.currentProject = project;
    $("#confirmModal").modal('show');
  }
}

