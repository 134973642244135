import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpEventType } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Router } from '@angular/router';
import { BehaviorSubject, forkJoin, Observable, of } from 'rxjs';
import { NotificationService } from 'src/app/common/services/notification.service';
import { IGetProjectVisibleScreensListDto } from '../admin/models/IGetProjectVisibleScreensListDto';
import { ProjectDetailsFullModel, ProjectFileZippedModel } from '../models/project-details-full-model';

@Injectable({
  providedIn: 'root'
})
export class ProjectService {
  projectDetails: any = null;
  projectDetailsFull: ProjectDetailsFullModel = null;
  projectFileZipped: ProjectFileZippedModel = null;
  private visibleScreens = new BehaviorSubject<IGetProjectVisibleScreensListDto>(null);
  currentVisibleScreens = this.visibleScreens.asObservable();

  myAppUrl = '';

  constructor(private http: HttpClient, private router: Router, private notifyService: NotificationService) {
  }

  savePart(data) {
    return this.http.post<any>('/api/project/savePart/', data)
      .pipe(map(response => {
        return response;
      }));
  }

  getDefaultValues(material, process) {
    return this.http.post<any>('/api/project/getDefaultValues/', { material, process })
      .pipe(map(response => {
        return response;
      }));
  }

  getProjects(name, sort, skip, take, ids) {
    return this.http.get<any>('/api/project/GetProjects?name=' + name + '&sort=' + sort + '&skip=' + skip + "&take=" + take + (ids != null ? "&ids="+ids : ""), {})
      .pipe(map(response => {
        return response;
      }));
  }

  orderNow(projectId: number) {
    return this.http.post<any>('/api/project/orderNow/', { projectId: +projectId })
      .pipe(map(response => {
        return response;
      }));
  }

  saveProjectStatus(projectId: number, projectStatus: number) {
    return this.http.post<any>('/api/project/saveProjectStatus/', { projectId: +projectId, projectStatus: projectStatus })
      .pipe(map(response => {
        return response;
      }));
  }

  getProject(id, isUpdate: boolean = false): Observable<any> {
    if (this.projectDetails) {
      if (this.projectDetails.id != id) {
        this.projectDetails = null;
      }
    }

    return !!this.projectDetails && !isUpdate ? of(this.projectDetails) :
      this.http.post<any>('/api/project/getProjectDetails/', { projectId: +id })
        .pipe(map(response => {
          this.projectDetails = response;
          return response;
        })
      );
 }

  getStlPrtFile(uid, partName, projectPartId, projectDetailId) {
    return this.http.post<any>('/api/project/getPartStl/', {
      uid: uid, partName: partName, projectPartId: projectPartId
      , projectDetailId: projectDetailId})
      .pipe(map(response => {
        return response;
      }));
  }

  saveProjectPart(data) {
    return this.http.post<any>('/api/project/saveProjectPart/', data)
      .pipe(map(response => {
        return response;
      }));
  }

  get3DModel(uid) {
    return this.http.post<any>('/api/project/get3DModel/', { uid: uid })
      .pipe(map(response => {
        return response;
      }));
  }

  getDetails(id) {
    return this.http.post<any>('/api/project/getDetails/', { projectId: +id })
      .pipe(map(response => {
        return response;
      }));
  }

   getProjectDetailsFull(id: number, isReload: boolean = false) {

     if (this.projectDetailsFull) {
       if (this.projectDetailsFull.details) {
         if (this.projectDetailsFull.details.id != id) {
           this.projectDetailsFull = null;
         }
       }
     }

    if (isReload) {
      this.projectDetailsFull = null;
    }

    return !!this.projectDetailsFull ? of(this.projectDetailsFull) :
      this.http.post<any>('/api/project/getProjectDetailsFull/', { projectId: +id })
        .pipe(map(response => {
          this.projectDetailsFull = response;
          return response;
        })
      );
   }

   clearProjectDetails() {
    this.projectDetailsFull = null;
   }

  getProjectFileZipped(id: number, isReload: boolean = false): Observable<ProjectFileZippedModel> {
    if (this.projectFileZipped) {
      if (this.projectFileZipped.projectId != id) {
        this.projectFileZipped = null;
        }
    }

    if (isReload) {
      this.projectFileZipped = null;
    }

    return !!this.projectFileZipped ? of(this.projectFileZipped) :
      this.http.post<any>('/api/project/getProjectFileZipped/', { projectId: +id })
        .pipe(map(response => {
          this.projectFileZipped = response;
          return response;
        })
        );
  }

  saveProject(formData) {

    const uploadReq = new HttpRequest('POST', `api/project/saveProject/`, formData, {
      reportProgress: true,
    });

    return this.http.request(uploadReq).subscribe(event => {
      //if (event.type === HttpEventType.UploadProgress) {
      //  selectedFile.progress = Math.round(100 * event.loaded / event.total);
      //}
      if (event.type === HttpEventType.Response) {
        this.notifyService.showInfo("New project created", null)
        this.router.navigate(['/dashboard/']);
      }

    },
      error => {
        
      },);

    //return this.http.post('/api/project/SaveProject', data).pipe(map(result => result));
  }

  updateProject(formData) {

    const uploadReq = new HttpRequest('POST', `api/project/saveProject/`, formData, {
      reportProgress: true,
    });

    return this.http.request(uploadReq);
  }

  saveProjectDetailFile(formData: any) {
    const uploadReq = new HttpRequest('POST', `api/project/saveProjectDetailFile/`, formData, {
      reportProgress: true,
    });

    return this.http.request(uploadReq);
  }

  uploadProjectDetailFile(formData: any) {
    const uploadReq = new HttpRequest('POST', `api/project/uploadProjectDetailFile/`, formData, {
      reportProgress: true,
    });

    return this.http.request(uploadReq);
  }

  uploadSWFile(formData) {

    const uploadReq = new HttpRequest('POST', `api/project/uploadSWFile/`, formData, {
      reportProgress: true,
    });

    this.http.request(uploadReq).subscribe(event => {
      //if (event.type === HttpEventType.UploadProgress) {
      //  selectedFile.progress = Math.round(100 * event.loaded / event.total);
      //}
      if (event.type === HttpEventType.Response) {

      }
    });
  }

  getProjectPriceSummary(id) {
    return this.http.get<any>('/api/project/getProjectPriceSummary/' +id )
      .pipe(map(response => {
        return response;
      }));
  }

  updateAndGetPrices(projectId: number, qty: number, addComment: boolean) {
    return this.http.post<any>('/api/priceCalculator/updateAndGetPrices/', { projectId: +projectId, qty: +qty, addComment: addComment })
      .pipe(map(response => {
        return response;
      }));
  }

  loadPrices(projectId: number) {
    return this.http.post<any>('/api/priceCalculator/loadPrices/', { projectId: +projectId})
      .pipe(map(response => {
        return response;
      }));
  }

  getProjectNotes(id) {
    return this.http.post<any>('/api/project/getProjectNotes/', { projectId: +id })
      .pipe(map(response => {
        return response;
      }));
  }

  saveProjectNotes(id, specialRequirements: string, cosmeticInspectionRequirements: string, packagingRequirements: string, functionalTestingrequirements: string) {
    return this.http.post<any>('/api/project/saveProjectNotes/', {
      projectId: +id, specialRequirements: specialRequirements,
      cosmeticInspectionRequirements: cosmeticInspectionRequirements,
      packagingRequirements: packagingRequirements,
      functionalTestingrequirements: functionalTestingrequirements
    })
      .pipe(map(response => {
        return response;
      }));
  }

  getProjectIssues(id) {
    return this.http.post<any>('/api/project/getProjectIssues/', { projectId: +id })
      .pipe(map(response => {
        return response;
      }));
  }

  getProjectSubAssembly(id) {
    return this.http.post<any>('/api/project/getProjectSubAssembly/', { projectId: +id })
      .pipe(map(response => {
        return response;
      }));
  }

  getProjectZipFileNames() {
    return this.http.get<any>('/api/project/getProjectZipFileNames/')
      .pipe(map(response => {
        return response;
      }));
  }

  getProjectZipFile(id: number, name: string) {
    return this.http.post('/api/project/getProjectZipFile/', {id: id, name: name }, { responseType: 'blob' })
  }

  saveProjectDetailType(projectDetailId: number, type: number, projectId: number) {
    return this.http.post('/api/project/saveProjectDetailType/', { projectId: +projectId, projectDetailId: +projectDetailId, type: +type })
  }

  deleteProject(projectId) {
    return this.http.delete<any>('/api/project/deleteProject/' + projectId)
      .pipe(map(response => {
        this.notifyService.showInfo("The project was successfully deleted", null)
        // return response;
      }));
  }

  getProjectVisibleScreens(projectId: number) {
    return this.http.post<IGetProjectVisibleScreensListDto>('/api/project/getProjectVisibleScreens', { projectId: projectId})
      .pipe(map(response => {
        return response;
      }));
  }

  updateProjectVisibleScreens(data: IGetProjectVisibleScreensListDto) {
    this.visibleScreens.next(data);
  }

  toFiles() {
    return this.http.get<any>('/api/project/files')
      .pipe(map(response => {
        return response;
      }));
  }
  
}
