import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpEventType } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { NotificationService } from 'src/app/common/services/notification.service';
import { IGetProjectVisibleScreensListDto } from '../../admin/models/IGetProjectVisibleScreensListDto';
import { IBodyConversation, IIntercomConversation } from './models/add-conversation';

@Injectable({
  providedIn: 'root'
})
export class IntercomService {
  projectDetails: any = null;

  private visibleScreens = new BehaviorSubject<IGetProjectVisibleScreensListDto>(null);
  currentVisibleScreens = this.visibleScreens.asObservable();

  myAppUrl = '';

  constructor(private http: HttpClient, private router: Router, private notifyService: NotificationService) {
  }

  createConverstation(data: IBodyConversation) {
    return this.http.post<IBodyConversation>('/api/intercom/create-converstation', data)
      .pipe(map(response => {
        return response;
      }));
  }
  updateConverstation(data: IIntercomConversation) {
    return this.http.post<IIntercomConversation>('/api/intercom/update-converstation', data)
      .pipe(map(response => {
        return response;
      }));
  }
}
