import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpEventType } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Router } from '@angular/router';
import { observable, of, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DictionaryService {

  myAppUrl = '';
  private countsSource = new Subject();
  makeDdlValues: any;
  constructor(private http: HttpClient, private router: Router) {

  }

  getMakeDdlValues() {

    if (this.makeDdlValues) {
      return of(this.makeDdlValues);
    }
    else {
      return this.http.get<any>('/api/dictionary/getMakeDdlValues/')
        .pipe(map(response => {
          this.makeDdlValues = response;
          return response;
        }));
    }
  }
}
