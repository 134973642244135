import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { ProjectService } from '../services/project.service';
import { first } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { ProjectIssueModel } from './models/projectIssueModel';
import { NotificationService } from 'src/app/common/services/notification.service';
import { IssueTypeEnum } from '../common/enums/issue-type-enum';
import { KeyValuePair } from '../common/model/key-value-pair';
import { EnumUtils } from '../common/utils/enum-utils';
import { DictionaryService } from '../services/dictionary.service';
import { IntercomService } from '../services/intercom/intercom.service';
import { SignalRService } from '../services/signal-r.service';
import { IBodyConversation, IIntercomConversation } from '../services/intercom/models/add-conversation';
import { User } from '../models/user';
import { AuthService } from '../services/auth.service';
import { Subject, Subscription } from 'rxjs';
import { ProjectDetailTypeEnum } from '../common/enums/project-detail-type-enum';
import { ChatTypeEnum } from "../common/enums/chat-type-enum"
import { ProjectScreenVisibleEnum } from '../common/enums/project-scree-vnisible-enum';
import { Utils } from '../common/utils/utils';
import { ProjectDetailsFullModel } from '../models/project-details-full-model';
import { ActionType, DownloadProgressService, RequestType } from '../services/download-service';
import { Zip } from '../common/utils/zip';
import { DomSanitizer } from '@angular/platform-browser';
declare function runCofactor(element, userId, customerId, boardId): any;

declare var $: any;

@Component({
    selector: 'app-issues',
    templateUrl: './issues.component.html',
    styleUrls: ['./issues.component.css']
})
export class IssuesComponent implements OnDestroy {

  projectId: any;
  issues: ProjectIssueModel[];
  readonly: boolean;
  issueType: typeof IssueTypeEnum = IssueTypeEnum
  undefinedValues: KeyValuePair[] = [];
  makeDropDownValueProcess: [];
  makeDropDownValueDtos: [];
  dafaultValuesDtos: [];
  vendorItemsAll = [] as any;
  textChat = "";
  model: any;
  userData = new User();
  userDataSubscription: Subscription;
  userActivity;
  userInactive: Subject<any> = new Subject()
  subscription: Subscription;
    loading: boolean;

  constructor(private authService: AuthService, private intercomService: IntercomService,
    private route: ActivatedRoute, private projectService: ProjectService, private router: Router, private notifyService: NotificationService,
    private dictionaryService: DictionaryService, private signalR: SignalRService, private downloadService: DownloadProgressService, private domSanitizer: DomSanitizer) {
    this.undefinedValues = EnumUtils.getProjectDetailTypes();
    this.getMakeItemDropDownValues();

    this.route.params.subscribe(params => {
      if (params['id']) {
        this.projectId = +params['id'];
        this.init();
      }
    });

    this.vendorItemsAll.push({ value: 'digikey', text: 'Digikey' });
    this.vendorItemsAll.push({ value: 'mcmaster', text: 'McMaster' });
    this.setTimeout();
    this.userInactive.subscribe(() => {
      if (window && window.Intercom) {
        window.Intercom('shutdown');
      }
      console.log('user has been inactive for 60s')
    });

    this.userDataSubscription = this.authService.userData.asObservable().subscribe(data => {
      this.userData = data;
    });
  }

  setTimeout() {
    this.userActivity = setTimeout(() => this.userInactive.next(undefined), 60000);
  }
  @HostListener('window:mousemove') refreshUserState() {
    clearTimeout(this.userActivity);
    this.setTimeout();
  }

  goToDetails() {
    this.router.navigate(['/details']);
  }

  saveMaterial(model) {
    var data = {};
    data["manufacturingProcess"] = model.process;
    data["material"] = model.material;

    this.projectService.savePart({ valueJson: JSON.stringify(data), type: 0, projectDetailId: model.projectDetailId }).subscribe(
      (result: any) => {
        this.getProjectIssues(true);
        this.notifyService.showInfo("Saved", null)
      }
    );
  }

  saveVendor(model) {
    var data = {};
    if (model.vendor == "digikey") {
      data["digiKey"] = model.partNo;
    }
    else {
      data["mcMasterKey"] = model.partNo;
    }

    this.projectService.savePart({ valueJson: JSON.stringify(data), type: 1, projectDetailId: model.projectDetailId }).subscribe(
      (result: any) => {
        this.getProjectIssues(true);
        this.notifyService.showInfo("Saved", null)
      }
    );
  }

  getMakeItemDropDownValues() {
    this.dictionaryService.getMakeDdlValues().subscribe(
      (result: any) => {
        this.makeDropDownValueDtos = result["makeDropDownValueDtos"];
        this.makeDropDownValueProcess = result["processes"];
      }
    );
  }

  getProcess(model): string {
    for (var i = 0; i < this.makeDropDownValueDtos.length; i++) {
      var item = this.makeDropDownValueDtos[i];
      if (item["material"] === model.material) {
        return item["process"];
      }
    }

    return "";
  }

  getFirstMaterialByProcess(process): string {
    for (var i = 0; i < this.makeDropDownValueDtos.length; i++) {
      var item = this.makeDropDownValueDtos[i];
      if (item["process"] === process) {
        return item["material"];
      }
    }
    return '';
  }

  fillMaterialDdl(model): void {
    var materials = [];
    for (var i = 0; i < this.makeDropDownValueDtos.length; i++) {
      var item = this.makeDropDownValueDtos[i];
      if (item["process"] === model.process) {
        if (!materials.includes(item["material"])) {
          materials.push(item["material"]);
        }
      }
    }
    model["materials"] = materials;
  }

  setMakeddValues(model, process) {
    if (process) {
      model.material = this.getFirstMaterialByProcess(model.process);
        this.projectService.getDefaultValues(model.material, model.process).subscribe((result: any) => {
        this.fillMaterialDdl(model);
      });

      return;
    }

    if (model.material) {
      let activeRow = this.getActiveMakeValuesRow(model);
      model.process = activeRow.process;
      this.fillMaterialDdl(model);
    }
  }

  getActiveMakeValuesRow(model): any {

    for (var i = 0; i < this.makeDropDownValueDtos.length; i++) {
      var item = this.makeDropDownValueDtos[i];
      if (item["material"] === model.material) {
        return item;
      }
    }

    return "";
  }

  ngOnDestroy(): void {
    if (this.subscription != null) { this.subscription.unsubscribe(); }
    this.userDataSubscription.unsubscribe();
  }

  init() {
    if (this.subscription != null) {
      this.subscription.unsubscribe();
    }

    this.subscription = this.signalR.addListenerRefreshIssues().subscribe((data: any) => {
      if (this.projectId && localStorage.getItem('authToken')) {
      
        if (data && data.isNewMessenge) {
          this.getProjectIssues();
        } else if (data == null) {
          this.getProjectIssues();
        }
      }
    });
    this.projectService.getProjectFileZipped(this.projectId).subscribe(
      (data) => {
        this.downloadService
          .downloadFile(data.imageFiles, RequestType.get, ActionType.download, null)
          .subscribe({
            next: ({ progress, response }) => {
              if (progress == 100 && response.status === 200 && response.body) {
                /*debugger;*/
                var fileReader = new FileReader();
                fileReader.onload = (e: any) => {
                  Zip.unzipFilesArrayBuffer(e.target.result).then((blobs) => {
                    this.downloadService.setImageCache(blobs);
                  });

                };
                fileReader.readAsArrayBuffer(response.body);
              }
            },
            error: (error: any) => {
              // handle errors
            }
          });
      })
  }

  domSanitize(imagePath: string) {
    return this.domSanitizer.bypassSecurityTrustUrl(this.downloadService.getImage(imagePath));
  }


  getProjectIssues(isReload:boolean = false) {
    this.projectService.getProjectDetailsFull(this.projectId, isReload)
      .subscribe(
        (result: ProjectDetailsFullModel) => {
          this.issues = result.projectIssues;
          this.readonly = result.details.readonly;
        },
        () => {

        });
  }

  setUndefined(model) {
    this.projectService.saveProjectDetailType(+model.projectDetailId, +model.type, this.projectId).subscribe(
      (result: string) => {
        this.notifyService.showInfo("Saved", null)
        this.getProjectIssues(true);
      }
    );
  }

  uploadFile(fileInput: any, item: ProjectIssueModel) {
    if (fileInput.target.files && fileInput.target.files[0]) {
      const formData = new FormData();
      formData.append(fileInput.target.files[0].name, fileInput.target.files[0]);
      formData.append("projectDetailId", item.projectDetailId + '');
      formData.append("fileType", item.projectDetailOptionType == 20 ? 'true' : 'false');
      this.projectService.saveProjectDetailFile(formData).subscribe(event => {
        if (event['status'] == 200) {
          this.notifyService.showInfo("Uploaded", null)
          this.getProjectIssues(true);
        }
      });
    }
  }

  getCountMessage(countMessage) {
    return Math.abs(countMessage);
  }

  createConverstation() {
    if (this.model && this.model.intercomConversationId) {
      // window.Intercom('showNewMessage');
      // window.Intercom('showMessages');
      const fullName = localStorage.getItem("fullName");
      window["initChat"](this.userData.firstName, fullName);
      setTimeout(() => {
        // window.Intercom('showNewMessage');
        window.Intercom('showMessages');
      }, 100);
      if (this.model && this.model.countMessage != 0) {
        this.model.countMessage = 0;
        this.intercomService.updateConverstation(<IIntercomConversation>{ conversationId: this.model.intercomConversationId }).subscribe(
          (result: any) => {
            // this.hideChatModal();
            //this.getProjectIssues();
          });
      } else {
        this.hideChatModal();
      }
    } else {
      let bodyText = ProjectDetailTypeEnum[this.model.classifierType] + " - Part Name '" + this.model.partName + "' Assembly '" + this.projectService.projectDetails.name + "' body message (" + this.textChat + ")";
      this.intercomService.createConverstation(
        <IBodyConversation>{
          bodyText: bodyText,
          entityId: this.model.projectDetailId,
          conversation_id: this.model.intercomConversationId,
          chatType: ChatTypeEnum.issue
        }).subscribe(
          (result: any) => {
            this.hideChatModal();
            this.model.countMessage = 0;
            this.getProjectIssues(true);
            this.notifyService.showInfo("Your create a converstation for issue " + this.model.partName + ", we will reply to you as soon", null)
          }
        );
    }
  }

  hideChatModal(): void {
    $("#chatModal").modal('hide');
    this.textChat = "";
  }

  showChatModal(): void {
    $("#chatModal").modal('show');
  }

  clickChat(model): void {
    this.model = model;
    if (model && model.intercomConversationId) {
      this.createConverstation();
    } else {
      this.showChatModal();
    }
  }

  onKeypressEventChat(event: any) {}

}
