import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-spinner',
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.scss'],
})
export class SpinnerComponent {
  @Input() active: boolean;
  @Input() backgroundColor = 'transparent';
  @Input() small: boolean;
  @Input() hideContentWhileLoading = true;

  isHidingTriggered = false;
}
