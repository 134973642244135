import { Component, OnInit, PipeTransform, Pipe } from '@angular/core';
import { ManufactureProjectService } from '../manufacture-services/manufacture-project.service';
import { first } from 'rxjs/operators';
import { Router } from '@angular/router';
import { Subscription, timer } from 'rxjs';
import { UserSettingEnum } from '../../common/enums/user-setting-enum';
import { NotificationService } from '../../common/services/notification.service';

@Component({
  selector: 'app-manufacture-settings',
  templateUrl: './manufacture-settings.component.html',
  styleUrls: ['./manufacture-settings.component.css']
})
export class ManufactureSettingsComponent implements OnInit {
  tabIndex = 1;
  dto: any = [];
  loading: boolean = false;
  userSettingEnum: typeof UserSettingEnum = UserSettingEnum;

  constructor(private manufactureProjectService: ManufactureProjectService, private router: Router, private notifyService: NotificationService) { }

  ngOnInit() {
    this.getProjects(true);
  }

  getProjects(isShowLoading: boolean) {

    if (isShowLoading) {
      this.loading = true;
    }

    this.manufactureProjectService.getUserSettings()
      .pipe(first())
      .subscribe(
        (result: any) => {
          this.dto = result;
          if (this.dto && this.dto.desiredGrossMargin && this.dto.desiredGrossMargin != 0 && this.dto.desiredGrossMargin > 0) {
            this.dto.desiredGrossMargin = this.dto.desiredGrossMargin * 100;
          }
          this.loading = false;
        },
        () => {
          this.loading = false;
        });
  }

  settingChange(type: UserSettingEnum, value: any) {
    if (type == UserSettingEnum.DesiredGrossMargin && value) {
      value = (value / 100).toString();
    }
    this.manufactureProjectService.saveUserSetting({ key: type, value: value }).subscribe(
      (result: string) => {
        this.notifyService.showInfo("Saved", null)
      });
  }

  public onChangeTab(index) {
    this.tabIndex = index;
  }

}
