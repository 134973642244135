export enum UserSettingEnum {

  DefaultDeliveryDay = 10, // 10
  BuyItemCMPercent = 20, // 0.9
  BuyItemCustomerPercent = 30, // 1.15
  BuyItemCMDeliveryDateGoalHours = 40, // 16 hours
  BuyNonStandartItemCMPercent = 50,// 0.9
  BuyNonStandartItemCustomerPercent = 60,// 1.15
  BuyNonStandartItemCMDeliveryDateGoalHours = 70, // 16 hours

  PCBAItemCMPercent = 80, // 0.9
  PCBAItemCustomerPercent = 90,// 1.15
  PCBAItemCMDeliveryDateGoalHours = 100, // 16 hours

  CablesItemCMPercent = 110, // 0.9
  CablesItemCustomerPercent = 120,// 1.15
  CablesItemCMDeliveryDateGoalHours = 130, // 16 hours

  DLHourlyRateDefault = 180, //45
  IDLQA = 190, //45
  IDLWarehouse = 191, //40
  IDLMaterialHandler = 192, //45
  IDLPMTPM = 193, //45
  OverHeadRate = 194, //45
  NumberOfShifts_Day = 195,
  HoursPerShift = 196,
  DesiredGrossMargin = 197,

  PMHourlyRateDefault = 200, //50
  OtherText = 210,

  Warehousing100 = 220, // 2
  ICI100 = 230, // 1
  LineSetUp100 = 240, //2
  MaterialHandlingDuringProduction100 = 250, // 0.15
  FinalGoodsPackagingMinutes100 = 260, // 1
  PostProductionFoldDown100 = 270, // 1
  PostProductionReportsDocumentation100 = 280, // 1
  ProjectManagementPercent100 = 290, // 0.25
  ProjectManagementAdditionalHours100 = 300, // 8

  Warehousing100500 = 310, // 4
  ICI100500 = 320, // 2
  LineSetUp100500 = 330, // 4

  FinalGoodsPackagingMinutes100500 = 340, //1
  PostProductionFoldDown100500 = 350, // 2
  PostProductionReportsDocumentation100500 = 360, //2

  Warehousing5001000 = 370, // 6
  ICI5001000 = 380, // 3
  LineSetUp5001000 = 390, //6

  FinalGoodsPackagingMinutes5001000 = 400, //1
  PostProductionFoldDown5001000 = 410, //3
  PostProductionReportsDocumentation5001000 = 420, // 3

  Warehousing10005000 = 430, //8
  ICI10005000 = 440, //4
  LineSetUp10005000 = 450, //8

  FinalGoodsPackagingMinutes10005000 = 460, //1
  PostProductionFoldDown10005000 = 470, //4
  PostProductionReportsDocumentation10005000 = 480, //4

  CustomerProposalSummaryAssemblyPercent = 490, // 1.15
  CustomerProposalSummaryOtherExpensesPercent = 500, // 1.15
  CustomerProposalSummaryWarrentyPercent = 510, //0.10

  CMMargin = 520, // 0.2

  MakeItemDeliveryShiftHour = 530,  // 16
  BuyItemDeliveryShiftHour = 540, // 8
  NonStandardBuyItemDeliveryShiftHour = 550, // 24
  PCBAItemDeliveryShiftHour = 560, // 8
  ProductionPreparationDeliveryShiftHour = 570, // 16
  ProductionAndAssemblyShiftHour = 580, // 8
  PackagingAndReadinessForShipping_1_100ShiftHour = 590, // 8
  PackagingAndReadinessForShipping_101_500ShiftHour = 600, // 16
  PackagingAndReadinessForShipping_501_1000ShiftHour = 610, //24
  ShippingShiftHour = 620, // 8

  AssemblyCMHourlyRate = 630, // 40
  AssemblyLPHourlyRatePercent = 640, // 1.15
  CablesItemDeliveryShiftHour = 650, // 8

  MacMasterInStockDeliveryHours = 660, // 40hrs (5 days)

  CycleTimePickAndPlace = 670, // 40 sec
  CycleTimeScrewing = 680, // 35 sec
  CycleTimeVisualInspection = 690, // 25 sec
  CycleTimeGlueDispensing = 700, // 45 secs

}
