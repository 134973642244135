import * as JSZip from "jszip";

export abstract class Zip {
  static base64ToArrayBuffer(base64) {
    var binary_string = window.atob(base64);
    var len = binary_string.length;
    var bytes = new Uint8Array(len);
    for (var i = 0; i < len; i++) {
      bytes[i] = binary_string.charCodeAt(i);
    }
    return bytes.buffer;
  }
  
  static unzipFilesBase64(zipped: string) {
    var byteArray = this.base64ToArrayBuffer(zipped);
    let blobs = [];
    return JSZip.loadAsync(byteArray).then((zipResult) => {
      var promises = [];

      Object.keys(zipResult.files).forEach((filename) => {
        promises.push(zipResult.file(filename).async('blob').then((fileData) => {
          var objectUrl = URL.createObjectURL(fileData);
          blobs.push({ name: filename, blobUrl: objectUrl });
          return fileData;
        }));
      });

      return Promise.all(promises).then((data) => {
        return blobs;
      });
    });
  }

  static unzipFilesArrayBuffer(byteArray: any) {
    let blobs = [];
    return JSZip.loadAsync(byteArray).then((zipResult) => {
      var promises = [];

      Object.keys(zipResult.files).forEach((filename) => {
        promises.push(zipResult.file(filename).async('blob').then((fileData) => {
          var objectUrl = URL.createObjectURL(fileData);
          blobs.push({ name: filename, blobUrl: objectUrl });
          return fileData;
        }));
      });

      return Promise.all(promises).then((data) => {
        return blobs;
      });
    });
  }
}
