import { Component, HostListener, OnInit } from '@angular/core';
import { AuthService } from './services/auth.service';
import { User } from './models/user';
import { Router } from '@angular/router';
import { ProjectService } from './services/project.service';
import { first } from 'rxjs/operators';
import { UserRole } from './models/roles';
import { SignalRService } from './services/signal-r.service';
import { IGetProjectVisibleScreensListDto } from './admin/models/IGetProjectVisibleScreensListDto';
import { ProjectScreenVisibleEnum } from './common/enums/project-scree-vnisible-enum';
import { Subscription } from 'rxjs/internal/Subscription';
import { BehaviorSubject } from 'rxjs';
import { Utils } from './common/utils/utils';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent implements OnInit {
  title = 'app';
  userData = new User();
  userDataSubscription: any;
  showLeftMenu: boolean = false;
  fullScreen: boolean = false;
  showLeftMenuProjectUserCM = false;
  projectId?: number;
  projectIdFromUrl? = null;
  issueCount: number = 0;
  userRole = UserRole;
  projectScreenVisibleEnum = ProjectScreenVisibleEnum;
  visibleScreensDto: IGetProjectVisibleScreensListDto;
  subscription: Subscription;
  
  constructor(private authService: AuthService, private router: Router, private projectService: ProjectService, private signalR: SignalRService) {
    if (localStorage.getItem('authToken')) {
      this.authService.setUserDetails();
    } else if (!window.location.pathname.includes("reset-password") && !window.location.pathname.includes("confirm-email")
      && !window.location.pathname.includes("register")) {
      this.router.navigate(['/login/']);
    }

    this.userDataSubscription = this.authService.userData.asObservable().subscribe(data => {
      this.userData = data;
      this.signalR.startConnection(this.userData.userId);
        if (this.subscription != null) { this.subscription.unsubscribe(); }

        this.subscription = this.signalR.addListenerRefreshIssues().subscribe((data: any) => {
          if (this.userData.role != 'ManufactureUser') {
            if (localStorage.getItem('projectId') && localStorage.getItem('authToken')) {
              this.projectId = +localStorage.getItem('projectId');
              console.log(this.projectId);
              this.getProjectIssues();
            }
          }
        });
      
      setInterval(() => {
        if (localStorage.getItem('projectId')) {
          if (this.projectId != +localStorage.getItem('projectId') && +localStorage.getItem('projectId')) {
            this.projectId = +localStorage.getItem('projectId');
            if (this.userData.role != 'ManufactureUser') {
              this.getProjectIssues();
            }
          }
          
        }
      }, 100);
    });

    router.events.subscribe((val) => {

      if (val["url"]) {
        this.projectIdFromUrl = val["url"].replace(/^\D+/g, '');
        if (val["url"].indexOf('edit-project') != -1 ||
          val["url"].indexOf('assembly-project') != -1 ||
          val["url"].indexOf('issues') != -1 ||
          val["url"].indexOf('details') != -1 ||
          val["url"].indexOf('notes') != -1 ||
          val["url"].indexOf('order-summary') != -1) {
          this.showLeftMenu = true;
        }
        else {
          this.showLeftMenu = false;
        }
        console.log(val);

        if (val["url"].indexOf('manufacture-product-overview') != -1 ||
          val["url"].indexOf('manufacture-assembly-parts') != -1 ||
          val["url"].indexOf('manufacture-assembly') != -1 ||
          val["url"].indexOf('manufacture-product-assembly') != -1 ||
          val["url"].indexOf('manufacture-other-requirments') != -1 ||
          val["url"].indexOf('manufacture-view-price') != -1 ||
          val["url"].indexOf('manufacture-summary') != -1) {
          this.showLeftMenuProjectUserCM = true;
        }
        else {
          this.showLeftMenuProjectUserCM = false;
        }

        //if (val["url"].indexOf('account-settings') != -1 || val["url"].indexOf('manufacture-profile') != -1) {
        //  this.fullScreen = true;
        //} else {
        //  this.fullScreen = false;
        //}

      }
    });
  }

  @HostListener('scroll', ['$event'])
  onScrollEvent($event) {
    this.signalR.scroll($event);
  }

  ngOnInit() {
    this.projectService.currentVisibleScreens.subscribe((data) => {
      this.visibleScreensDto = data;
      if (!data && this.userData && this.userData.isLoggedIn && this.projectId) {
        this.projectService.getProjectVisibleScreens(this.projectId).subscribe((data) => {
          this.visibleScreensDto = data;
        });
      }
    });
  }

  ngOnDestroy() {
    if (this.subscription != null) { this.subscription.unsubscribe(); }
  }

  isDisableCustomerMenuItem(screen: ProjectScreenVisibleEnum): boolean {
    return Utils.isDisableCustomerMenuItem(screen, this.userData, this.visibleScreensDto);
      }

  goToManufactureScreen(event, router) {
    event.preventDefault();
    event.stopPropagation();
    if (router == "/manufacture-product-overview/") {
      this.router.navigate(['/manufacture-product-overview/' + this.projectIdFromUrl]);
    } else if (router == "/manufacture-assembly-parts/") {
      this.router.navigate(['/manufacture-assembly-parts/' + this.projectIdFromUrl]);
    } else if (router == "/manufacture-assembly/") {
      this.router.navigate(['/manufacture-assembly/' + this.projectIdFromUrl]);
    } else if (router == "/manufacture-product-assembly/") {
      this.router.navigate(['/manufacture-product-assembly/' + this.projectIdFromUrl]);
    } else if (router == "/manufacture-other-requirments/") {
      this.router.navigate(['/manufacture-other-requirments/' + this.projectIdFromUrl]);
    } else if (router == "/manufacture-view-price/") {
      this.router.navigate(['/manufacture-view-price/' + this.projectIdFromUrl]);
    } else if (router == "/manufacture-summary/") {
      this.router.navigate(['/manufacture-summary/' + this.projectIdFromUrl]);
    }
  } 

  isNotUserRole() {
    return this.userData.role != UserRole.User;
  }

  isActiveMenu(menuName) {
    return window.location.pathname.indexOf(menuName) != -1
  }

  logout($event) {
    event.preventDefault();
    event.stopPropagation();
    this.authService.logout();
    this.router.navigate(['/login/']);
  }

  goToAssembly() {
    window.location.href = "/assembly-project";
  }

  getProjectIssues() {
    if (this.userData.role) {
      this.projectService.getProjectIssues(this.projectId)
        .pipe(first())
        .subscribe(
          (result: any) => {
            this.issueCount = result.length;
          },
          () => {
            this.issueCount = 0
          });
    }
  }

  clickLogo() {
    if (this.userData.role == this.userRole.User) {
      this.router.navigate(['/dashboard']);
    }
    if (this.userData.role == this.userRole.ManufactureUser) {
      this.router.navigate(['/manufacture-dashboard']);
    }
    if (this.userData.role == this.userRole.Admin) {
      this.router.navigate(['/admin-dashboard']);
    }
  }
}
