import { Options } from '@angular-slider/ngx-slider';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { combineLatest, forkJoin, Subscription } from 'rxjs';
import { NotificationService } from 'src/app/common/services/notification.service';
import { Zip } from '../common/utils/zip';
import { AdminProjectService } from '../admin/admin-services/admin-project.service';
import { IGetProjectVisibleScreensListDto } from '../admin/models/IGetProjectVisibleScreensListDto';
import { GetProjectDTO, ProjectDetailsFullModel } from '../models/project-details-full-model';
import { User } from '../models/user';
import { AuthService } from '../services/auth.service';
import { ProjectService } from '../services/project.service';
import { ActionType, DownloadProgressResult, DownloadProgressService, RequestType } from '../services/download-service';
import { DomSanitizer } from '@angular/platform-browser';


declare var $: any;

declare var loadedStlModels: any;
declare function asmClick(): any;
declare function disamClick(): any;
declare function pauseClick(): any;
declare function repeatClick(): any;
declare function continueClick(): any;
declare function nextStepClick(): any;
declare function prevStepClick(): any;
declare function isNextPrevEnabled(isNext): any;
declare function STLViewerEnable(name, partListPaths, isAsembly, heigth, width, blobs): any;

@Component({
  selector: 'app-assembly-project',
  templateUrl: './assembly-project.component.html',
  styleUrls: ['./assembly-project.component.css']
})
export class AssemblyProjectComponent implements OnDestroy, OnInit {
  id: any;
  asmId: any;
  isEdit: boolean = false;
  loading: boolean = false;
  dto: GetProjectDTO;
  stlFilePath: any;
  headerName: any;
  pageHeader: string = '';
  show3dButtons: boolean = false;
  doDisasmEnabled: boolean;
  doAsmEnabled: boolean;
  doPauseEnabled: boolean;
  doContinueEnabled: boolean;
  doRepeatEnabled: boolean;
  doPrevStepClickEnabled: boolean;
  doNextStepClickEnabled: boolean;
 
  HideDisassem: boolean = false;
  HideAssem: boolean = false;
  HidePause: boolean = false;
  HideContinue: boolean = false;
  HideRepeat: boolean = false;
  subSssembly: any[];
  hasSubAsm: boolean = false;
  readonly: boolean = false;
  parentFileName: string = ''

  showPlay: boolean = false;
  isOpen: boolean = false;
  
  lightValue: number = 5;
  userDataSubscription: Subscription;
  userData = new User();
  visibleScreensDto: IGetProjectVisibleScreensListDto;
  options: Options = {
    floor: 1,
    ceil: 9
  };
    projectDetailsFullModel: ProjectDetailsFullModel;
  blobs: Blob[] = [];
    progress: number;
  constructor(private projectService: ProjectService, private route: ActivatedRoute,
    private notifyService: NotificationService, private authService: AuthService, private adminProjectService: AdminProjectService, private downloadService: DownloadProgressService, private domSanitizer: DomSanitizer
  )
  {
    this.route.params.subscribe(params => {
      this.id = +params['id'];
      this.isEdit = true;
      this.loadData();
    });

    this.downloadService.stlsLoadData.asObservable().subscribe((data: DownloadProgressResult) => {
      if (data) {
        this.progress = data.progress;
        if (data.finished) {
          this.blobs = data.blobs;
          setTimeout(() => {
            this.load3dModel();
          }, 10);
        }
      }
    });

    this.downloadService.imagesLoadData.asObservable().subscribe((data: DownloadProgressResult) => {
      if (data) {

      }
    });

    }
 
  ngOnInit(): void {
    this.userDataSubscription = this.authService.userData.asObservable().subscribe(data => {
      this.userData = data;
      this.adminProjectService.getProjectVisibleScreens(this.id).subscribe(
        (result) => {
          this.loading = false;
          this.visibleScreensDto = result;

          if (!this.readonly) {
            this.readonly = result.isDemoMode
          }
          
        },
        (error) => {
          this.loading = false;
        });
    });

  }

  domSanitize(imagePath) {
    return this.domSanitizer.bypassSecurityTrustUrl(this.downloadService.getImage(imagePath));
  }
  
  loadData() {
    if (isNaN(this.id)) {
      return;
    }
    
    if (this.isEdit) {
        this.loading = true;
      loadedStlModels = [];

      this.projectService.getProjectDetailsFull(this.id).subscribe(
        (data) => {
          this.initData(data);
          this.projectDetailsFullModel = data;
          setTimeout(() => {
            this.load3dModel();
          }, 10);
          this.loading = false;
        },
        (error) => {
        });
      }
    }

  initData(data: ProjectDetailsFullModel) {
    loadedStlModels = [];
    this.asmId = this.id;
    this.dto = data.details;

    if (!this.readonly) {
      this.readonly = data.details.readonly;
    }
    
    this.pageHeader = 'Assembly - ' + this.dto.zipFileName ? this.dto.zipFileName : 'No Data';
    this.parentFileName = this.dto.zipFileName;

    this.subSssembly = data.subProjects;
    if (data.subProjects.length > 0) {
      this.hasSubAsm = true;
    }

    this.dto.model3d.filter(x => x.pathToSTLFile)

    if (this.hasSubAsm) {
      if (!this.subSssembly.some(v => v.name == 'Main Assembly')) {
        this.subSssembly.splice(0, 0, ({ id: this.id, name: 'Main Assembly' }));
      }
    }

  }

  loadDetails(project) {
    this.asmId = project.Id;
    if (project.name == 'Main Assembly') {
      this.pageHeader = 'Assembly - ' + this.parentFileName ? this.parentFileName : 'No Data';
      this.dto = this.projectDetailsFullModel.details;
    }
    else {
      this.pageHeader = 'Sub assembly - ' + project.name ? project.name : 'No Data';
      this.dto = project;
    }
    this.show3dButtons = false;
    this.showPlay = false;
    this.load3dModel();
  }

  hideModal(): void {
    $("#myModal").modal('hide');
  }

  onChange(model) {
    this.projectService.saveProjectPart(model)
      .subscribe(
        (result: string) => {
          this.notifyService.showInfo("Saved", null);
        },
        () => {
        });
  }

  showModal(part): void {
    this.projectService.getStlPrtFile(this.dto.uid, part.name, part.id, null)
      .subscribe(
        (result: any) => {
          this.headerName = name;
          var filePath = this.downloadService.getStl(result.path);
          $(".stl-modal-viewer").empty();
          if (result.path) {
            $(".stl-modal-viewer").append("<div class='stlviewer' data-src='" + filePath + "' data-color='" + result.color + "' width='600px' height='600px' style='display:none'></div>")
            const height = $(window).height() - 360;
            STLViewerEnable("stlviewer", undefined, false, height, $(window).width() - (parseInt($(".content-wrapper").css("padding-left")) + 180), this.blobs);
            $("#myModal").modal('show');
            $(".stl-modal-viewer .stlviewer").show();
          }
          else {
            $(".stl-modal-viewer").append("<img src='/assets/images/no-image.png' />")
          }
        },
        () => {
        });
  }

  load3dModel() {
    if (this.blobs.length == 0 || !this.dto) {
      return;
    }

    if ($('.cart-drawer').hasClass('cart-drawer-open')) {
      $('.assembly-button').click();
    }

    this.setButtonsStatus(null);
    $(".stl-modal-viewer-all").empty();
    $(".stl-modal-viewer-all").append("<div class='stlviewerAll' width='6000px' height='6000px'></div>");
    window['isReadyDisassembled'] = false;

    let files = this.dto.model3d.filter(x => x.pathToSTLFile);
    if (files.length == 0) {
      this.show3dButtons = false;
      return;
    }
    
    const height = $(window).height() - 360;
    var cartDrawerW = $(window).width() / 100 * 64.62;
    const width = cartDrawerW - 50;

    STLViewerEnable("stlviewerAll", files, true, height, $(window).width() - (parseInt($(".content-wrapper").css("padding-left")) + 180), this.blobs);
    let asmInterval;
    clearInterval(asmInterval);
    var self = this;
    asmInterval = setInterval(() => {
      if (window['isReadyDisassembled'] == true) {
        clearInterval(asmInterval);
        self.showPlay = true;
        self.show3dButtons = true;
      }
    }, 10);
  }

  showPlayButton() {
    var width = 1260; 
    var cartDrawerW = $(window).width() / 100 * 64.62;
    $('.cart-drawer').css('width', cartDrawerW);
    var assemblyButton = cartDrawerW -100; 
    $('.assembly-button').css('right', $(".cart-drawer-open")[0] ? assemblyButton : -99); // assembly-button
    
    var height = $('.stlviewerAll').height();
    
    setTimeout( () => {
      if (width) {
        $('.stl-modal-viewer-all-alt').css('width', cartDrawerW - 50);
      }
      if (height) {
        $('.stl-modal-viewer-all-alt').css('height', height);
      }
      this.changeSeze(cartDrawerW - 50);
    }, 100);
  }

  changeSeze(width) {
    width = $(window).width() - (parseInt($(".content-wrapper").css("padding-left")) + 130);
    this.isOpen = !this.isOpen;
    const height = $(window).height() - 360;
    // const width = $(window).width() - 374;
    if (this.isOpen) {
      $(".cart-drawer").attr('style', 'width: ' + width + "px");
      $(".stl-modal-viewer-all-alt").attr('style', 'width: ' + width + "px");
      $(".assembly-button").attr('style', 'right: ' + (width - 100) + "px");
      $(".product-img-box").attr('style', 'width: ' + width + "px");
      $(".product-img-box").attr('style', 'height: ' + height + "px");
    } else {
      $(".cart-drawer").attr('style', 'width: ' + -width + "px");
      $(".assembly-button").attr('style', 'right: ' + - 100 + "px"); 
    }
  }

  doAsm() {
    this.showPlay = false;
    this.setButtonsStatus('doAsm');
    asmClick();
  }

  doDisasm() {
    this.setButtonsStatus('doDisasm');
    if (disamClick()) {
    }
  }

  doPause() {
    this.setButtonsStatus('doPause');
    pauseClick();
  }

  doRepeat() {
    this.setButtonsStatus('doRepeat');
    repeatClick();
  }

  doContinue() {
    this.setButtonsStatus('doContinue');
    continueClick();
  }

  doNextStepClick() {
    nextStepClick();
    this.setButtonsStatus('doNextStepClick');
  }

  doPrevStepClick() {
    prevStepClick();
    this.setButtonsStatus('doPrevStepClick');
  }
  
  setButtonsStatus(mode = null) {
    this.doDisasmEnabled = false;
    this.doAsmEnabled = false;
    this.doPauseEnabled = false;
    this.doContinueEnabled = false;
    this.doRepeatEnabled = false;
    this.doPrevStepClickEnabled = false;
    this.doNextStepClickEnabled = false;

    if (mode == null) {
      this.doAsmEnabled = true;
    }
    else if (mode == 'doAsm' || mode == 'doDisasm' || mode == 'doContinue' || mode == 'doRepeat') {
      this.doDisasmEnabled = true;
      this.doAsmEnabled = true;
      this.doPauseEnabled = true;
      this.doRepeatEnabled = true;
      this.doPrevStepClickEnabled = isNextPrevEnabled(false);
      this.doNextStepClickEnabled = isNextPrevEnabled(true);
    }
    else if (mode == 'doPause' || mode == 'doPrevStepClick' || mode == 'doNextStepClick') {
      this.doDisasmEnabled = true;
      this.doAsmEnabled = true;
      this.doContinueEnabled = true;
      this.doRepeatEnabled = true;
      this.doPrevStepClickEnabled = isNextPrevEnabled(false);
      this.doNextStepClickEnabled = isNextPrevEnabled(true);
    }
  }

  ngOnDestroy(): void {
    this.userDataSubscription.unsubscribe();
    pauseClick();
  }
}
