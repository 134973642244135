import { HttpClient, HttpEvent, HttpEventType, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Interface } from "readline";
import { BehaviorSubject, forkJoin, Observable, of } from "rxjs";
import { map } from "rxjs/operators";
import { Zip } from "../common/utils/zip";
import { ProjectFileZippedModel } from "../models/project-details-full-model";

export enum RequestType {
  get = 'GET',
  post = 'POST',
  put = 'PUT',
  delete = 'DELETE',
}

export enum ActionType {
  download = 1,
  upload
}

export class DownloadProgressResult {
  progress: number;
  blobs: any[];
  finished: boolean;

}

@Injectable({
  providedIn: 'root'
})
export class DownloadProgressService {

  percentDone = 0;
  actionType: ActionType;
  progress: number;

  constructor(private http: HttpClient) { }

  imagesLoadData = new BehaviorSubject<DownloadProgressResult>(new DownloadProgressResult());
  stlsLoadData = new BehaviorSubject<DownloadProgressResult>(new DownloadProgressResult());

  stlsBlobs: any[] = [];
  setStlsCache(bs: any[]) {
    bs.forEach(b => { this.stlsBlobs.push(b); });
  }

  getStl(name: string) {
    for (var i = 0; i < this.stlsBlobs.length; i++) {
      if (this.stlsBlobs[i].name == name) {
        return this.stlsBlobs[i].blobUrl;
      }
    }

    return "assets/images/no-image.png";
  }

  blobs: any[] = [];
  setImageCache(bs: any[]) {
    bs.forEach(b => { this.blobs.push(b); });
  }

  getImage(imageName: string) {
    for (var i = 0; i < this.blobs.length; i++) {
      if (this.blobs[i].name == imageName) {
        return this.blobs[i].blobUrl;
      }
    }

    return "assets/images/no-image.png";
  }

  getStls(stlFilesUri: string) {
      return  this
          .downloadFile(stlFilesUri, RequestType.get, ActionType.download, null)
            .subscribe({
              next: ({ progress, response }) => {
                this.stlsLoadData.next({ progress, blobs: null, finished: false });
                if (progress == 100 && response.status === 200 && response.body) {

                  var fileReader = new FileReader();
                  fileReader.onload = (e: any) => {

                    Zip.unzipFilesArrayBuffer(e.target.result).then((blobs) => {
                      this.setStlsCache(blobs);
                      this.stlsLoadData.next({ progress, blobs, finished: true });
                    });

                  };
                  fileReader.readAsArrayBuffer(response.body);
                } else if (progress == 100 && response.status === 404) {
                  this.stlsLoadData.next({ progress, blobs: null, finished: true });
                }
              },
              error: (error: any) => {
                // handle errors
              }
            });
  }

  getImages(imageFilesUri: string) {
    return  this
      .downloadFile(imageFilesUri, RequestType.get, ActionType.download, null)
      .subscribe({
        next: ({ progress, response }) => {
          this.imagesLoadData.next({ progress, blobs: null, finished: false });
          if (progress == 100 && response.status === 200 && response.body) {

            var fileReader = new FileReader();
            fileReader.onload = (e: any) => {

              Zip.unzipFilesArrayBuffer(e.target.result).then((blobs) => {
                this.setImageCache(blobs);
                this.imagesLoadData.next({ progress, blobs, finished: true });
              });

            };
            fileReader.readAsArrayBuffer(response.body);
          } else if (progress == 100 && response.status === 404) {
            this.imagesLoadData.next({ progress, blobs: null, finished: true });
          }

        },
        error: (error: any) => {
          // handle errors
        }
      });
  }

  getImagesAndStls(data: ProjectFileZippedModel) {
    return forkJoin({
      stlFiles: this.getStls(data.stlFiles),
      imageFiles: this.getImages(data.imageFiles)
    });
  }

  downloadFile(uri: string, requestType: RequestType, actionType: ActionType, body: any): Observable<{ progress: number, response : any }> {
    if (!uri) {
      return of({ progress: 100, response: { status: 404 } });
    }

    this.actionType = actionType;

    const req = new HttpRequest(requestType, uri, body, {
      reportProgress: true,
      responseType: 'blob'
    });

    return this.http
      .request(req)
      .pipe(
        map(event => ({
          progress: this.getPercentage(event),
          response: event
        })),
      );

  }

  public getPercentage(event: HttpEvent<any>): number {
    
    switch (event.type) {

      case HttpEventType.UploadProgress:

        // Downloading files will still trigger upload progress due to the back-and-forth, ignore this
        if (this.actionType !== ActionType.upload) {
          return 0;
        }

        // Compute and show the % done:
        if (event && event.total) {
          this.percentDone = Math.round(100 * event.loaded / event.total);
          return this.percentDone;
        } else {
          return 0;
        }

      case HttpEventType.DownloadProgress:

        if (event && event.total) {
          this.percentDone = Math.round(100 * event.loaded / event.total);
        }

        return this.percentDone;

      default:

        // Not an event we care about
        return this.percentDone;

    }
  }
}
