import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { first } from 'rxjs/operators';
import { ExternalLoginModel } from 'src/app/models/external-login';
import { debug } from 'util';
import { LoginFacebook } from '../../models/login-facebook';
import { AuthService } from '../../services/auth.service';


declare global {
  interface Window { intercomSettings: any; }
  interface Window { Intercom: any; }
  interface Window { attachEvent: any; }
  interface Window { ic: any; }
}

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css', '../login-common.css']
})
export class LoginComponent implements OnInit {

  loading = false;
  loginForm: FormGroup;
  submitted = false;
  returnUrl: string;

  constructor(private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private authService: AuthService) { }

  ngOnInit() {
    this.loginForm = this.formBuilder.group({
      username: ['', Validators.required],
      password: ['', Validators.required]
    });
  }

  get loginFormControl() { return this.loginForm.controls; }

  onSubmit() {
    this.submitted = true;

    if (this.loginForm.invalid) {
      return;
    }

    this.loading = true;

    this.authService.login(this.loginForm.value)
      .pipe(first())
      .subscribe(
        (result) => {
          if (result.userDetails.userRole === 2) {
            this.router.navigate(["/manufacture-dashboard"]);

          }
          else if (result.userDetails.userRole === 1) {
            this.router.navigate(["/dashboard"]);
            const name = !result.userDetails.firstName || !result.userDetails.lastName ? result.userDetails.email : result.userDetails.firstName + " " + result.userDetails.lastName;
            localStorage.setItem('fullName', name);

            setTimeout(() => {
              window["initChat"](result.userDetails.email, name);
            }, 100)
          }
          else if (result.userDetails.userRole === 0) {
            this.router.navigate(["/admin-dashboard-approve"]);
          }
        },
        () => {
          this.loading = false;
          this.loginForm.setErrors({
            invalidLogin: true
          });
        });
  }

  loginWithGoogle = () => {
    this.authService.signInWithGoogle()
      .then(res => {
        const externalLoginModel: ExternalLoginModel = {
          provider: res.provider,
          idToken: res.idToken
        };
        this.externalLogin(externalLoginModel);
      }, error => console.error(error))
  }

  loginWithFacebook = () => {
    this.authService.signInFacebookLogin()
      .then(res => {
        const externalLoginModel: LoginFacebook = {
          token: res.authToken,
          id: res.response.id,
          userName: res.response.name,
          email: res.response.email,
          firstName: res.response.first_name,
          lastName: res.response.last_name,
          photoUrl: res.photoUrl
        };
        this.externalLoginFacebook(externalLoginModel);
      }, error => console.error(error))
  }

  externalLoginFacebook(externalLoginModel: LoginFacebook) {
    this.authService.externalLoginFacebook('api/login/facebook-login', externalLoginModel)
      .subscribe(res => {
        const name = !res.userDetails.firstName || !res.userDetails.lastName ? res.userDetails.email : res.userDetails.firstName + " " + res.userDetails.lastName;
        localStorage.setItem('fullName', name);
        localStorage.setItem('photoUrl', externalLoginModel.photoUrl);
        this.router.navigate(["/dashboard"]);
      },
        error => {
          console.error(error);
          this.authService.signOutExternal();
        });
  }

  externalLogin(externalLoginModel: ExternalLoginModel) {
    this.authService.externalLogin('api/login/external-login', externalLoginModel)
      .subscribe(res => {
        const name = !res.userDetails.firstName || !res.userDetails.lastName ? res.userDetails.email : res.userDetails.firstName + " " + res.userDetails.lastName;
        localStorage.setItem('fullName', name);
        this.router.navigate(["/dashboard"]);
      },
      error => {
        console.error(error);
        this.authService.signOutExternal();
      });
  }

  goToPage(pageRour: string) {
    this.router.navigate([pageRour]);
  }
}
