import { Object3dModel } from "./object-3d-model";
import { ProductLineTaskEnum } from "../common/enums/product-line-task-enum";

export class GetManufactureProjectDetailsModel {
  id: number;
  name: string;
  uid: string;
  imagePath: string;
  secondsLeft: number;
  status: number;
  number: string;
  dimensions: string;
  weight: string;
  qty: number | null;
  deliveryIn: string;

  specialRequirements: string;
  cosmeticInspectionRequirements: string;
  packagingRequirements: string;
  functionalTestingrequirements: string;
  availableStationPersons: number;
  notIncludePrices: string;

  object3dModels: Object3dModel[];
  project3dModels: Project3dModels[];
  makeItems: GetManufactureMakeItem[];
  standartBuyItems: GetManufactureBuyItem[];
  nonStandartBuyItems: GetManufactureBuyItem[];
  cablesItems: GetManufactureCableItem[];
  pcbaItems: GetManufacturePCBAItem[];
  assemblyPartsReview: AssemblyPartsReviewModel[];
  assembly: AssemblyModel;
  summary: SummaryModel;
  makeItemsOverview: TextValue[];
  standartBuyItemsOverview: TextValue[];
  nonStandartBuyItemsOverview: TextValue[];
  cablesItemsOverview: TextValue[];
  requiredDl: number;
  totalAreaRequiredProdFloor: number;
  files: FileProjectModel[];
  zipFileName: string;
  readonly: boolean;
}

export interface Project3dModels {
  projectId: number;
  models: Object3dModel[];
}

export interface FileProjectModel {
  name: string;
}

export interface TextValue {
  text: string;
  value: string;
}

export interface GetManufactureMakeItem {
  id: number;
  name: string;
  description: string;
  process: string;
  material: string;
  finish: string;
  qty: number | null;
  cmPriceGoal: number;
  cmTotalPriceGoal: number;
  cmDeliveryDateGoal: string;
  selected: boolean;
}

export interface GetManufactureBuyItem {
  id: number;
  name: string;
  itemNo: string;
  description: string;
  vendor: string;
  qty: number | null;
  cmPriceGoal: number;
  cmTotalPriceGoal: number;
  cmDeliveryDateGoal: string;
  selected: boolean;
}

export interface GetManufacturePCBAItem {
  id: number;
  gerberFilePath: string;
  bomFilePath: string;
  description: string;
  name: string;
  qty: number;
  cmPriceGoal: number;
  cmTotalPriceGoal: number;
  cmDeliveryDateGoal: string;
  dimensions: string;
  layers: string;
  sMTComponents: string;
  tHComponents: string;
  sides: string;
  selected: boolean;
}

export interface GetManufactureCableItem {
  id: number;
  name: string;
  itemNo: string;
  description: string;
  qty: number;
  cmPriceGoal: number;
  cmTotalPriceGoal: number;
  cmDeliveryDateGoal: string;
  selected: boolean;
}

export interface AssemblyPartsReviewModel {
  id: number;
  name: string;
  pathImg: string;
  handling: string;
  inspection: string;
  color: string;
}

export interface AssemblyModel {
  items: AssemblyItemModel[];
  stationItems: [StationItemDto[]];
  totalAssemblyTimeHrs: number;
  totalAssemblyShifts: number;
  requiredDl: number;
  totalAreaRequiredProdFloor: number;
  totalSecs: number;
}

export interface AssemblyItemModel {
  id: number;
  taskNo: string;
  description: string;
  type: number;
  cycleTime: number;
  specialToolRequired: boolean,
  fixtureRequired: boolean,

}

export interface AssemblyItemModel {
  id: number;
  taskNo: string;
  description: string;
  type: number;
  cycleTime: number;
  specialToolRequired: boolean,
  fixtureRequired: boolean,

}

export interface StationItemDto {
  time: number;
  stationNumber: string;
  partName: string;
  description: string;
  type: number;
  descriptionType: ProductLineTaskEnum;
  fixtureRequired: boolean;
  printImagePath: string;
}

export interface SummaryModel {
  bomMakeItemPrice: number;
  bomStandartBuyItemPrice: number;
  bomNonStandartBuyItemPrice: number;
  bomPCBAItemPrice: number;
  bomCablesItemPrice: number;
  assembly: number;
  otherExpenses: number;
  shipping: number;
  total: number;
  cmMargin: number;
  totalCost: number;
  unitlCost: number;
}

export interface SubAssemblyModel {
  id: number;
  name: string;
  stations: number;
  totalAreaRequiredProdFloor: number;
  requiredDl: number;
}
