import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { first } from 'rxjs/operators';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css', '../login-common.css']
})
export class ResetPasswordComponent implements OnInit {

  loading = false;
  loginForm: FormGroup;
  submitted = false;
  returnUrl: string;
  userId = "";
  code = "";

  constructor(private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private authService: AuthService) {
    this.route.queryParams.subscribe(params => {
      this.userId = params['userId'];
      this.code = params['code'];
    });
  }

  ngOnInit() {
    this.loginForm = this.formBuilder.group({
      password: ['', Validators.required],
      confirmpassword: ['', Validators.required]
    });
  }

  get loginFormControl() { return this.loginForm.controls; }

  onSubmit() {
    this.submitted = true;

    if (this.loginForm.invalid) {
      return;
    }

    this.loading = true;
    this.loginForm.value.userId = this.userId;
    this.loginForm.value.code = this.code;

    this.authService.resetPassword(this.loginForm.value)
      .pipe(first())
      .subscribe(
        (result: string) => {
          this.loading = false;

          if (result) {
            this.loginForm.setErrors({
              invalidLogin: true,
              errors: result
            });
          }
          else {
            this.router.navigate(["/login"]);
          }

          
        },
        () => {
          this.loading = false;
          //this.loginForm.reset();
          this.loginForm.setErrors({
            invalidLogin: true
          });
        });
  }

}
