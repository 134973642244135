import { Component, OnInit } from '@angular/core';
import { ManufactureProjectService } from '../manufacture-services/manufacture-project.service';
import { debounce, first, map } from 'rxjs/operators';
import { Router, ActivatedRoute } from '@angular/router';
import { GetManufactureProjectDetailsModel } from '../../models/manufacture-project-details';

@Component({
  selector: 'app-manufacture-product-overview',
  templateUrl: './manufacture-product-overview.component.html',
  styleUrls: ['./manufacture-product-overview.component.css']
})
export class ManufactureProductOverviewComponent implements OnInit {

  dto: GetManufactureProjectDetailsModel;
  projectId: number;
  dimensions: string;
  loading: boolean = true;

  constructor(private manufactureProjectService: ManufactureProjectService, private router: Router, private route: ActivatedRoute) { }

  ngOnInit() {
    this.loading = true;
    this.route.params.subscribe(params => {
      this.projectId = params['id'];
      this.getProjectDetail();
    });
  } 

  getProjectDetail() {
    this.manufactureProjectService.getProjectDetails(this.projectId)
      .pipe(first())
      .subscribe(
        (result: any) => {
          this.dto = result;
          this.loading = false;
          if ((this.dto && this.dto.object3dModels && this.dto.object3dModels.length > 0) && this.dto && this.dto.object3dModels[0].bbox && this.dto.object3dModels[0].bbox.lower) {
            
            let lowerX = this.dto.object3dModels[0].bbox.lower.x;
            let lowerY = this.dto.object3dModels[0].bbox.lower.y;
            let lowerZ = this.dto.object3dModels[0].bbox.lower.z;
            let upperX = this.dto.object3dModels[0].bbox.upper.x;
            let upperY = this.dto.object3dModels[0].bbox.upper.y;
            let upperZ = this.dto.object3dModels[0].bbox.upper.z;
            this.dto.object3dModels.forEach((m) => {
              if (m.bbox && m.bbox.lower && m.bbox.upper) {
                if (m.bbox.lower.x < lowerX) {
                  lowerX = m.bbox.lower.x;
                }
                if (m.bbox.lower.y < lowerY) {
                  lowerY = m.bbox.lower.y;
                }
                if (m.bbox.lower.z < lowerZ) {
                  lowerZ = m.bbox.lower.z;
                }
                if (m.bbox.lower.x > upperX) {
                  upperX = m.bbox.upper.x;
                }
                if (m.bbox.lower.y > upperY) {
                  upperY = m.bbox.upper.y;
                }
                if (m.bbox.lower.z > upperZ) {
                  upperZ = m.bbox.upper.z;
                }
              }
            });
            const absX = (Math.abs(lowerX) + Math.abs(upperX)).toFixed(1);
            const absY = (Math.abs(lowerY) + Math.abs(upperY)).toFixed(1);
            const absZ = (Math.abs(lowerZ) + Math.abs(upperZ)).toFixed(1);
            this.dimensions = "x=" + absX + "; y=" + absY + "; z=" + absZ + ";";
          }
        },
        () => {

        });
  }
}
