import { Component, OnInit } from '@angular/core';
import { ProjectService } from '../services/project.service';
import { first } from 'rxjs/operators';
import { Router } from '@angular/router';
import { NotificationService } from 'src/app/common/services/notification.service';
import { CustomerNotesModel } from './customer-notes-model';
import { IBodyConversation, IIntercomConversation } from '../services/intercom/models/add-conversation';
import { IntercomService } from '../services/intercom/intercom.service';
import { AuthService } from '../services/auth.service';
import { User } from '../models/user';
import { ChatTypeEnum } from '../common/enums/chat-type-enum';
import { SignalRService } from '../services/signal-r.service';
import { ProjectScreenVisibleEnum } from '../common/enums/project-scree-vnisible-enum';
import { Utils } from '../common/utils/utils';
import { ProjectDetailsFullModel } from '../models/project-details-full-model';

declare var $: any;

@Component({
  selector: 'app-notes',
  templateUrl: './notes.component.html',
  styleUrls: ['./notes.component.css']
})
export class NotesComponent implements OnInit {
  dto: CustomerNotesModel = null;
  projectId: any;
  readonly: boolean;
  model: any;
  userDataSubscription: any;
  userData = new User();
  textChat = "";
  chatTypeEnums = ChatTypeEnum;
  chatType: ChatTypeEnum;
  selectedNote: string;

  constructor(
    private authService: AuthService,
    private intercomService: IntercomService,
    private projectService: ProjectService,
    private router: Router,
    private notifyService: NotificationService,
    private signalR: SignalRService
  ) {
    this.userDataSubscription = this.authService.userData.asObservable().subscribe(data => {
      this.userData = data;
    });
  }

  ngOnInit() {
    if (localStorage.getItem('projectId')) {
      this.projectId = +localStorage.getItem('projectId');
    }

    this.signalR.addListenerRefreshIssues().subscribe((data: any) => {

      if (localStorage.getItem('projectId') && localStorage.getItem('authToken')) {
        this.projectId = +localStorage.getItem('projectId');
        if (data && data.isNewMessenge) {
          this.getProjectNotes();
        }
      }
    });

    this.projectService.getProjectDetailsFull(this.projectId)
      .subscribe(
        (result: ProjectDetailsFullModel) => {
          this.readonly = result.details.readonly;
        },
        (error) => {
        });
    this.isUserCanOpenMe();

  }

  isUserCanOpenMe() {
    this.projectService.getProjectVisibleScreens(this.projectId).subscribe((data) => {
      if (Utils.isDisableCustomerMenuItem(ProjectScreenVisibleEnum.Notes, this.userData, data)) {
        this.router.navigate([`/edit-project/${this.projectId}`]);
      }
      else {
        this.getProjectNotes();
      }
    });
  }

  onChange() {
    this.projectService.saveProjectNotes(this.projectId, this.dto.specialRequirements, this.dto.cosmeticInspectionRequirements, this.dto.packagingRequirements, this.dto.functionalTestingrequirements)
      .pipe(first())
      .subscribe(
        (result: any) => {
          this.notifyService.showInfo("Saved", null);
        },
        () => {

        });
  }

  getProjectNotes() {
    this.projectService.getProjectNotes(this.projectId)
      .pipe(first())
      .subscribe(
        (result: CustomerNotesModel) => {
          this.dto = result;
        },
        () => {

        });
  }

  openChat() {
    const fullName = localStorage.getItem("fullName");
    window["initChat"](this.userData.firstName, fullName);

    setTimeout(() => {
      window.Intercom('showMessages');
    }, 100);
  }

  sendChatMessage(bodyText: string) {
    this.intercomService.createConverstation(
      <IBodyConversation>{
        bodyText: bodyText,
        entityId: Number(this.projectId),
        conversation_id: null,
        chatType: this.chatType
      }
    ).subscribe(
      (result: any) => {
        this.hideChatModal();
        //this.model.countMessage = 0;
        //this.getProjectIssues();
        this.getProjectNotes();
        this.notifyService.showInfo("Your create a converstation for issue " + this.model.partName + ", we will reply to you as soon", null)
      }
    );
  }

  converstationUpdate(id, countMessage, bodyText) {
    if (this.model && this.model[id]) {

      this.openChat();
      if (this.model && this.model[countMessage] != 0) {
        this.intercomService.updateConverstation(<IIntercomConversation>{ intercomConversationId: this.model[id] }).subscribe((result: any) => { });
      } else {
        this.hideChatModal();
      }
    } else {
      let body = bodyText + "' Assembly '" + this.projectService.projectDetails.name + "' body message (" + this.textChat + ")";
      this.sendChatMessage(body);
    }
    this.model[countMessage] = 0;
  }

  // 
  getCountMessage(countMessage) {
    return Math.abs(countMessage);
  }

  createConverstation() {
    if (this.chatType == ChatTypeEnum.chatSpecialRequirement) {
      this.converstationUpdate("chatSpecialRequirementId", "chatSpecialRequirementCountMessage", "General notes & requirements");
    }
    if (this.chatType == ChatTypeEnum.chatPackagingRequirement) {
      this.converstationUpdate("chatPackagingRequirementId", "chatPackagingRequirementCountMessage", "Packaging requirements");
    }
    if (this.chatType == ChatTypeEnum.chatCosmeticInspectionRequirement) {
      this.converstationUpdate("chatCosmeticInspectionRequirementId", "chatCosmeticInspectionRequirementCountMessage", "Cosmetic visual inspection requirements");
    }
    if (this.chatType == ChatTypeEnum.chatFunctionalTestingrequirement) {
      this.converstationUpdate("chatFunctionalTestingrequirementId", "chatFunctionalTestingrequirementCountMessage", "Functional Testing requirements");
    }
  }

  updateCountMessage(chatType: ChatTypeEnum) {
    if (chatType == ChatTypeEnum.chatSpecialRequirement) {
      this.model.chatSpecialRequirementCountMessage
    }

  }

  hideChatModal(): void {
    $("#chatModal").modal('hide');
    this.textChat = "";
  }

  showChatModal(): void {
    $("#chatModal").modal('show');
  }

  clickChat(model, chatType: ChatTypeEnum, selectedNote: string): void {

    this.model = model;
    this.chatType = chatType;
    this.selectedNote = selectedNote;

    if (this.chatType == ChatTypeEnum.chatSpecialRequirement) {
      if (model && model.chatSpecialRequirementId) {
        this.createConverstation();
      } else {
        this.showChatModal();
      }
    } else if (this.chatType == ChatTypeEnum.chatCosmeticInspectionRequirement) {
      if (model && model.chatCosmeticInspectionRequirementId) {
        this.createConverstation();
      } else {
        this.showChatModal();
      }
    } else if (this.chatType == ChatTypeEnum.chatFunctionalTestingrequirement) {
      if (model && model.chatFunctionalTestingrequirementId) {
        this.createConverstation();
      } else {
        this.showChatModal();
      }
    } else if (this.chatType == ChatTypeEnum.chatPackagingRequirement) {
      if (model && model.chatPackagingRequirementId) {
        this.createConverstation();
      } else {
        this.showChatModal();
      }
    }
  }
}

