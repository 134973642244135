import { Component, Input, OnInit, EventEmitter, Output } from '@angular/core';
import { ProjectDetailTypeEnum } from '../../common/enums/project-detail-type-enum';
import { EnumUtils } from '../../common/utils/enum-utils';
import { KeyValuePair } from '../../common/model/key-value-pair';
import { ProjectTypeSwitcherModel } from './project-type-switcher-model';

@Component({
  selector: 'app-project-type-switcher',
  templateUrl: './project-type-switcher.component.html',
  styleUrls: ['./project-type-switcher.component.scss'],
})
export class ProjectTypeSwitcherComponent implements OnInit {
  @Input() selectedType: ProjectDetailTypeEnum;
  @Input() projectDetailId: number;
  @Input() ngReadonly: boolean;
  @Output() changed = new EventEmitter<ProjectTypeSwitcherModel>();

  projectDetailTypeEnum: typeof ProjectDetailTypeEnum = ProjectDetailTypeEnum;
  types: KeyValuePair[] = [];

  ngOnInit(): void {
    this.types = EnumUtils.getProjectDetailTypes();
  }

  change(val: string) {
    this.selectedType = +val;
    this.changed.emit({ projectDetailId: this.projectDetailId, type: this.selectedType});
  }
}
