import { Component, OnInit, PipeTransform, Pipe } from '@angular/core';
import { first } from 'rxjs/operators';
import { Router } from '@angular/router';
import { Subscription, timer } from 'rxjs';
import { UserSettingEnum } from '../../common/enums/user-setting-enum';
import { NotificationService } from '../../common/services/notification.service';
import { AdminProjectService } from '../admin-services/admin-project.service';
import { IAdminSettingDto } from '../models/IAdminSettingDto';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'app-admin-settings',
  templateUrl: './admin-settings.component.html',
  styleUrls: ['./admin-settings.component.css']
})
export class AdminSettingsComponent implements OnInit {
  tabIndex = 1;
  fullList: IAdminSettingDto[] = [];
  dto: IAdminSettingDto[] = [];
  groups: string[] = ["less then 100 qty", "between 101 1000 qty", "between 1001 5000 qty", "more then 5001 qty"]
  groupRows: string[] = [];
  loading: boolean = false;
  userSettingEnum: typeof UserSettingEnum = UserSettingEnum;
  changePasswordForm: FormGroup;
  submittedChangePassword = false;
 

  constructor(
    private adminProjectService: AdminProjectService,
    private router: Router,
    private notifyService: NotificationService,
    private authService: AuthService,
    private formBuilder: FormBuilder  ) { }

  ngOnInit() {
    this.getProjects(true);
    this.changePasswordForm = this.formBuilder.group({
      oldpassword: ['', Validators.required],
      newpassword: ['', Validators.required],
      confirmpassword: ['', Validators.required]
    });
  }

  getProjects(isShowLoading: boolean) {

    if (isShowLoading) {
      this.loading = true;
    }

    this.adminProjectService.getAdminSettings()
      .pipe(first())
      .subscribe(
        (result: any) => {
          this.fullList = result.filter(r => r.key != 197 && r.key != 20 && r.key != 30 && r.key != 50 && r.key != 60 && r.key != 80 && r.key != 90 && r.key != 110 && r.key != 120 && r.key != 190 && r.key != 490 && r.key != 500); // *** 197 = Desired Gross Margin, decimal *** && r.key != 50 && r.key != 194 && r.key != 199
          for (var i in this.fullList) {
            var row = this.fullList[i];
            var gr = this.groups.filter(x => row.text.indexOf(x) > -1)
            if (gr.length) {
              var groupRow = row.text.replace(gr[0], "");
              if (this.groupRows.indexOf(groupRow) == -1) {
                this.groupRows.push(groupRow);
              }
            }
            else {
              this.dto.push(row);
            }
          }
          this.loading = false;
        },
        () => {
          this.loading = false;
        });
  }

  getGroupRows(group: string): IAdminSettingDto[] {
    return this.groups.map(x => {
      var setting = this.fullList.filter(y => y.text.indexOf(x) > -1 && y.text.indexOf(group) > -1);
      if (setting.length) {
        return setting[0];
      }
      else {
        return null;
      }
    });
  }

  settingChange(type: UserSettingEnum, value: any) {
    this.adminProjectService.saveAdminSetting({ key: type, value: value }).subscribe(
      (result: string) => {
        this.notifyService.showInfo("Saved", null)
      });
  }

  public onChangeTab(index) {
    this.tabIndex = index;
  }


  get changePasswordFormControl() { return this.changePasswordForm.controls; }

  onChangePassword() {
    this.submittedChangePassword = true;

    if (this.changePasswordForm.invalid) {
      return;
    }
    this.changePasswordForm.setErrors({
      invalidLogin: false,
      errors: []
    });

    this.loading = true;
    this.authService.changePassword(this.changePasswordForm.value)
      .pipe(first())
      .subscribe(
        (result: string) => {
          this.loading = false;
          if (result) {
            this.changePasswordForm.setErrors({
              invalidLogin: true,
              errors: result
            });
          }
          else {
            this.submittedChangePassword = false;

            this.changePasswordForm.reset();
            this.changePasswordForm.setErrors({
              invalidLogin: false,
              sucessLogin: true,
              errors: result
            });

          }
        },
        () => {
          this.loading = false;
          this.changePasswordForm.setErrors({
            invalidLogin: true
          });
        });
  }

}
