import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, NgModule, Pipe, PipeTransform } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { ChartsModule, ThemeService  } from 'ng2-charts';

import { AppComponent } from './app.component';
import { NavMenuComponent } from './nav-menu/nav-menu.component';
import { HomeComponent } from './home/home.component';

import { LoginComponent } from './account/login/login.component';
import { AccountSettingsComponent } from './account/account-settings/account-settings.component';
import { ConfirmedEmailComponent } from './account/confirmed-email/confirmed-email.component';
import { ConfirmEmailComponent } from './account/confirm-email/confirm-email.component';
import { RegisterComponent } from './account/register/register.component';
import { ResetPasswordComponent } from './account/reset-password/reset-password.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { ForgotPasswordComponent } from './account/forgot-password/forgot-password.component';
import { DesignComponent } from './design/design.component';

import { UserHomeComponent } from './user-home/user-home.component';
import { UserGuard } from './guards/user.guard';
import { HttpInterceptorService } from './services/http-interceptor.service';
import { ErrorInterceptorService } from './services/error-interceptor.service';
import { AdminGuard } from './guards/admin.guard';

import { DragDropDirective } from './directives/drag-drop.directive';
import { UploadFileComponent } from './components/upload-file/upload-file.component';
import { SpinnerComponent } from './components/spinners/spinner/spinner.component';
import { ProjectTypeSwitcherComponent } from './components/project-type-switcher/project-type-switcher.component';


import { EditProjectComponent } from './edit-project/edit-project.component';
import { CreateProjectComponent } from './create-project/create-project.component';
import { AssemblyProjectComponent } from './assembly-project/assembly-project.component';

import { NotesComponent } from './notes/notes.component';
import { IssuesComponent } from './issues/issues.component';
import { OrderSummaryComponent } from './order-summary/order-summary.component';
import { DetailsComponent } from './details/details.component';
import { ManufactureUserGuard } from './guards/manufacture-user.guard';
import { ManufactureDashboardComponent } from './manufacture/manufacture-dashboard/manufacture-dashboard.component';
import { ManufactureArchiveComponent } from './manufacture/manufacture-archive/manufacture-archive.component';
import { ManufactureStatisticsComponent } from './manufacture/manufacture-statistics/manufacture-statistics.component';
import { ManufactureAssemblyPartsComponent } from './manufacture/manufacture-assembly-parts/manufacture-assembly-parts.component';
import { ManufactureProductAssemblyComponent } from './manufacture/manufacture-product-assembly/manufacture-product-assembly.component';
import { ManufactureProductAssemblyStationsComponent } from './manufacture/manufacture-product-assembly/manufacture-product-assembly-stations.component';
import { ManufactureAssemblyComponent } from './manufacture/manufacture-assembly/manufacture-assembly.component';
import { ManufactureOtherRequirmentsComponent } from './manufacture/manufacture-other-requirments/manufacture-other-requirments.component';
import { ManufactureViewPricesComponent } from './manufacture/manufacture-view-price/manufacture-view-price.component';
import { ManufactureSummaryComponent } from './manufacture/manufacture-summary/manufacture-summary.component';
import { ManufactureProductOverviewComponent } from './manufacture/manufacture-product-overview/manufacture-product-overview.component';
import { ManufactureSettingsComponent } from './manufacture/manufacture-settings/manufacture-settings.component';
import { ManufactureProfileComponent } from './manufacture/manufacture-profile/manufacture-profile.component';

import { FormatTimerPipe } from './pipes/format-timer-formatter.pipe';
import { NgxSliderModule } from '@angular-slider/ngx-slider';
import { NgSwitchComponent } from 'src/app/common/component/form/switcher/switcher.component';
import { AdminDashboardComponent } from './admin/admin-dashboard/admin-dashboard.component';
import { AdminDashboardAssignedComponent } from './admin/admin-dashboard-assigned/admin-dashboard-assigned.component';
import { AdminSettingsComponent } from './admin/admin-settings/admin-settings.component';
import { AdminCustomerUsersGuard } from './guards/admin-customer-users.guard';
import { AdminOrderSummaryComponent } from './admin/admin-order-summary/admin-order-summary.component';
import { RegisteredComponent } from './account/registered/registered.component';
import { TwoDigitDecimaNumberDirective } from './directives/two-digit-decima-number.directive';
import { DownloadFileComponent } from './download-file/download-file.component';
//import { SharedToasterService } from './services/shared.toaster.service';
import { SocialLoginModule, SocialAuthServiceConfig, FacebookLoginProvider } from 'angularx-social-login';
import { GoogleLoginProvider } from 'angularx-social-login';
import { environment } from '../environments/environment';
import { AngularFontAwesomeModule } from 'angular-font-awesome';
import { NgxMaskModule } from 'ngx-mask-2'
import { AccessDeniedComponent } from './access-denied/access-denied.component';
import { ScrollTopComponent } from './scroll-top/scroll-top.component';

@NgModule({
  declarations: [
    AppComponent,
    NavMenuComponent,
    HomeComponent,
    LoginComponent,
    UserHomeComponent,
    AccountSettingsComponent,
    ConfirmedEmailComponent,
    ConfirmEmailComponent,
    RegisterComponent,
    RegisteredComponent,
    ResetPasswordComponent,
    DashboardComponent,
    ForgotPasswordComponent,
    DesignComponent,
    DragDropDirective,
    TwoDigitDecimaNumberDirective,
    UploadFileComponent,
    SpinnerComponent,
    ProjectTypeSwitcherComponent,
    EditProjectComponent,
    CreateProjectComponent,
    AssemblyProjectComponent,
    NotesComponent,
    IssuesComponent,
    OrderSummaryComponent,
    DetailsComponent,
    ManufactureDashboardComponent,
    ManufactureArchiveComponent,
    ManufactureStatisticsComponent,
    ManufactureAssemblyPartsComponent,
    ManufactureProductAssemblyComponent,
    ManufactureProductAssemblyStationsComponent,
    ManufactureAssemblyComponent,
    ManufactureOtherRequirmentsComponent,
    ManufactureViewPricesComponent,
    ManufactureSummaryComponent,
    ManufactureProductOverviewComponent,
    ManufactureSettingsComponent,
    ManufactureProfileComponent,
    NgSwitchComponent ,
    FormatTimerPipe,
    AdminDashboardComponent,
    AdminDashboardAssignedComponent,
    AdminSettingsComponent,
    AdminOrderSummaryComponent,
    DownloadFileComponent,
    AccessDeniedComponent,
    ScrollTopComponent
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
    HttpClientModule,
    FormsModule,
    AngularFontAwesomeModule,
    ReactiveFormsModule,
    NgxSliderModule,
    ChartsModule,
    NgxMaskModule.forRoot(),
    SocialLoginModule,
    RouterModule.forRoot([
      { path: '', component: HomeComponent, pathMatch: 'full' },
      { path: 'login', component: LoginComponent },
      { path: 'reset-password', component: ResetPasswordComponent },
      { path: 'confirm-email', component: ConfirmEmailComponent },
      { path: 'confirmed-email', component: ConfirmedEmailComponent },
      { path: 'register', component: RegisterComponent },
      { path: 'registered', component: RegisteredComponent },
      { path: 'forgot-password', component: ForgotPasswordComponent },

      { path: 'create-project', component: CreateProjectComponent, canActivate: [UserGuard] },
      { path: 'edit-project/:id', component: EditProjectComponent, canActivate: [AdminCustomerUsersGuard] },
      { path: 'assembly-project/:id', component: AssemblyProjectComponent, canActivate: [AdminCustomerUsersGuard] },
      { path: 'issues/:id', component: IssuesComponent, canActivate: [AdminCustomerUsersGuard] },
      { path: 'details/:id', component: DetailsComponent, canActivate: [AdminCustomerUsersGuard] },
      { path: 'notes/:id', component: NotesComponent, canActivate: [AdminCustomerUsersGuard] },
      { path: 'order-summary/:id', component: OrderSummaryComponent, canActivate: [UserGuard] },

      { path: 'account-settings', component: AccountSettingsComponent, canActivate: [UserGuard] },
      { path: 'user-home', component: UserHomeComponent, canActivate: [UserGuard] },
      { path: 'dashboard', component: DashboardComponent, canActivate: [UserGuard] },
      { path: 'design', component: DesignComponent, canActivate: [UserGuard] },
      { path: 'downloadFile/:projectDetailId/:typeFile', component: DownloadFileComponent, canActivate: [AdminCustomerUsersGuard] },

      //manufacture
      { path: 'manufacture-dashboard', component: ManufactureDashboardComponent, canActivate: [ManufactureUserGuard] },
      { path: 'manufacture-archive', component: ManufactureArchiveComponent, canActivate: [ManufactureUserGuard] },
      { path: 'manufacture-statistics', component: ManufactureStatisticsComponent, canActivate: [ManufactureUserGuard] },
      { path: 'manufacture-assembly-parts/:id', component: ManufactureAssemblyPartsComponent, canActivate: [ManufactureUserGuard] },
      { path: 'manufacture-product-assembly/:id', component: ManufactureProductAssemblyComponent, canActivate: [ManufactureUserGuard] },
      { path: 'manufacture-assembly/:id', component: ManufactureAssemblyComponent, canActivate: [ManufactureUserGuard] },
      { path: 'manufacture-other-requirments/:id', component: ManufactureOtherRequirmentsComponent, canActivate: [ManufactureUserGuard] },
      { path: 'manufacture-view-price/:id', component: ManufactureViewPricesComponent, canActivate: [ManufactureUserGuard] },
      { path: 'manufacture-summary/:id', component: ManufactureSummaryComponent, canActivate: [ManufactureUserGuard] },
      { path: 'manufacture-product-overview/:id', component: ManufactureProductOverviewComponent, canActivate: [ManufactureUserGuard] },
      { path: 'manufacture-settings', component: ManufactureSettingsComponent, canActivate: [ManufactureUserGuard] },
      { path: 'manufacture-profile', component: ManufactureProfileComponent, canActivate: [ManufactureUserGuard] },

      //admin
      { path: 'admin-dashboard', component: AdminDashboardComponent, canActivate: [AdminGuard] },
      { path: 'admin-dashboard-approve', component: AdminDashboardComponent, canActivate: [AdminGuard] },
      { path: 'admin-dashboard-assigned', component: AdminDashboardAssignedComponent, canActivate: [AdminGuard] },
      { path: 'admin-settings', component: AdminSettingsComponent, canActivate: [AdminGuard] },
      { path: 'admin-order-summary/:id', component: AdminOrderSummaryComponent, canActivate: [AdminGuard] },

      // access-denied
      { path: 'access-denied', component: AccessDeniedComponent },

    ]),
    BrowserAnimationsModule,
    ToastrModule.forRoot()
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: HttpInterceptorService, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptorService, multi: true },
    ThemeService,
   // SharedToasterService,
   {
    provide: 'SocialAuthServiceConfig',
    useValue: {
      autoLogin: false,
      providers: [
        {
          id: GoogleLoginProvider.PROVIDER_ID,
          provider: new GoogleLoginProvider(
            '126499869880-8lh8i141dpdcor6pjqp9g8vdt92lbt9j.apps.googleusercontent.com'
          )
        },
        {
          id: FacebookLoginProvider.PROVIDER_ID,
          provider: new FacebookLoginProvider(environment.facebookAppId)
        }
  ],
    } as SocialAuthServiceConfig
  }
  ],
  bootstrap: [AppComponent]
})


export class AppModule { }


