import { Component, OnInit } from '@angular/core';
import { ManufactureProjectService } from '../manufacture-services/manufacture-project.service';
import { first, ignoreElements } from 'rxjs/operators';
import { Router, ActivatedRoute } from '@angular/router';
import { GetManufactureProjectDetailsModel, SubAssemblyModel } from '../../models/manufacture-project-details';
import { NotificationService } from '../../common/services/notification.service';
import { ProductLineTaskEnum } from '../../common/enums/product-line-task-enum';
import { ActionType, DownloadProgressService, RequestType } from '../../services/download-service';
import { Zip } from '../../common/utils/zip';
import { ProjectService } from '../../services/project.service';
declare var $: any;

@Component({
  selector: 'app-manufacture-product-assembly',
  templateUrl: './manufacture-product-assembly.component.html',
  styleUrls: ['./manufacture-product-assembly.component.css']
})
export class ManufactureProductAssemblyComponent implements OnInit {

  dto: GetManufactureProjectDetailsModel;
  projectId: number;
  subAssemblies: SubAssemblyModel[];
  activetab = 0;
  loading: boolean = true;
  bodyStationsMaxLenght: number = 0;
  constructor(private manufactureProjectService: ManufactureProjectService, private router: Router, private route: ActivatedRoute,
    private notifyService: NotificationService,
    private downloadService: DownloadProgressService,
    private projectService: ProjectService
  ) { }

  ngOnInit() {
    this.route.params.subscribe(params => {
      this.projectId = params['id'];
      this.loadData(true);
    });
  }

  getProjectDetail(reload = false) {

    this.bodyStationsMaxLenght = 0;
    this.manufactureProjectService.getProjectDetails(this.projectId, reload)
      .pipe(first())
      .subscribe(
        (result: any) => {
          this.loading = false;
          this.dto = result;
          this.dto.assembly.totalAreaRequiredProdFloor = this.areSum();
          this.dto.assembly.requiredDl = this.stationsSum();
          this.loading = false;
        },
        () => {

        });

    this.projectService.getProjectFileZipped(this.projectId).subscribe(
      (data) => {
        this.downloadService
          .downloadFile(data.imageFiles, RequestType.get, ActionType.download, null)
          .subscribe({
            next: ({ progress, response }) => {
              if (progress == 100 && response.status === 200 && response.body) {
                /*debugger;*/
                var fileReader = new FileReader();
                fileReader.onload = (e: any) => {
                  Zip.unzipFilesArrayBuffer(e.target.result).then((blobs) => {
                    this.downloadService.setImageCache(blobs);
                  });

                };
                fileReader.readAsArrayBuffer(response.body);
              }
            },
            error: (error: any) => {
              // handle errors
            }
          });

      },
      (error) => {
      });
  }

  loadData(isInit = false) {
    this.manufactureProjectService.getSubAssemblies(this.projectId).pipe(first())
      .subscribe((result: SubAssemblyModel[]) => {
        var active = $('.nav-item.nav-link.active').attr('aria-controls');
        this.subAssemblies = result;
        this.getProjectDetail(true);
        setTimeout(() => {
          $('[aria-controls=' + active + ']').addClass('active');
          if (isInit) {
            $('ul.nav-tabs li.nav-item:first a').addClass('active');
          }
        }, 300);
      });
  }

  changeTab(tabId: number) {
    this.activetab = tabId;
  }

  stationsSum() {
    return this.subAssemblies.reduce((acc, cur) => acc + cur.stations, 0);
  }

  areSum() {
    return this.subAssemblies.reduce((acc, cur) => acc + cur.totalAreaRequiredProdFloor, 0);
  }

  totalAreaRequiredProdFloorChange(subAsm: SubAssemblyModel, val: number) {
    subAsm.totalAreaRequiredProdFloor = val;
    var self = this;
    var changeFunc = function () {
      if (self.dto != null) {
        self.dto.totalAreaRequiredProdFloor = self.areSum();;
        self.dto.requiredDl = self.stationsSum();
      }
      else {
        setTimeout(changeFunc, 100); //wait if dto was not loaded yet.
      }
    }
    changeFunc();
  }

  onChangeAvailableDls(subAsm: SubAssemblyModel) {
    this.manufactureProjectService.saveProjectAvailableStationPersons(subAsm.id, subAsm.stations).subscribe(
      (result: string) => {
        this.notifyService.showInfo("Saved", null)
        this.loadData();
      }
    );
  }

  counter(i: number) {
    return new Array(i);
  }

  plus(subAsm: SubAssemblyModel) {
    subAsm.stations = subAsm.stations + 1;
    this.onChangeAvailableDls(subAsm);
  }

  minus(subAsm: SubAssemblyModel) {
    subAsm.stations = subAsm.stations - 1;
    this.onChangeAvailableDls(subAsm);
  }
  
}
