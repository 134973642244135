import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { first } from 'rxjs/operators';
import { NotificationService } from 'src/app/common/services/notification.service';
import { OrderSummaryDto, PriceCalculatorDto, ProjectDetailsFullModel, ProjectQuoteStatusEnum } from '../models/project-details-full-model';
import { User } from '../models/user';
import { AuthService } from '../services/auth.service';
import { ProjectService } from '../services/project.service';

@Component({
  selector: 'app-order-summary',
  templateUrl: './order-summary.component.html',
  styleUrls: ['./order-summary.component.css']
})

export class OrderSummaryComponent implements OnDestroy, OnInit {

  dto: OrderSummaryDto;
  id: any;
  loading: boolean = true;
  dtoPrices: PriceCalculatorDto;
  userDataSubscription: Subscription;
  userData = new User();

  constructor(private projectService: ProjectService, private router: Router, private route: ActivatedRoute, private notifyService: NotificationService, private authService: AuthService) {

    this.route.params.subscribe(params => {
      if (params['id']) {
        this.id = +params['id']; 
        this.loadData();
      }
    });
  }

  ngOnInit(): void {
    this.userDataSubscription = this.authService.userData.asObservable().subscribe(data => {
      this.userData = data;
    });
  }

  loadData(isReload: boolean = false) {
    if (isNaN(this.id)) {
      return;
    }

    this.loading = true;
    this.projectService.getProjectDetailsFull(this.id, isReload)
      .subscribe(
        (result: ProjectDetailsFullModel) => {
          this.loading = false;
          this.dto = result.orderSummary;
          this.dtoPrices = result.prices;
        },
        (error) => {
          this.loading = false;
        });
  }

  orderNow() {

    this.projectService.orderNow(this.id)
      .pipe(first())
      .subscribe(
        (result: any) => {
          this.notifyService.showInfo("Ordered", null);
          this.projectService.clearProjectDetails();
          this.router.navigate(['/dashboard']);
        },
        () => {

        });
  }

  getPrices() {
    this.dtoPrices.quoteStatus = ProjectQuoteStatusEnum.InProgress;
    this.loading = true;
    this.projectService.updateAndGetPrices(this.id, this.dto.volume, true)
      .subscribe(
        () => {
          this.notifyService.showInfo("Quantity updated", null);
          this.loading = false;
          this.loadData(true);
        },
        () => {
          this.loading = false;
        });
  }

  ngOnDestroy(): void {
    this.userDataSubscription.unsubscribe();
  }

  public get ProjectQuoteStatusEnum(): typeof ProjectQuoteStatusEnum {
    return ProjectQuoteStatusEnum;
  }
}
