
export enum ProjectScreenVisibleEnum {
  Assembly,
  Details,
  Notes,
  Issues,
  OrderNow,
  All,
  None
}
