import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ExportService } from '../services/export.service';
import { saveAs } from 'file-saver';

@Component({
  selector: 'app-login',
  templateUrl: './download-file.component.html',
  styleUrls: ['./download-file.component.css', ]
})
export class DownloadFileComponent implements OnInit {

  loading = false;
  projectDetailId: number;
  typeFile: number;

  constructor(
    private route: ActivatedRoute,
    private exportService: ExportService) { }

  ngOnInit() {
    this.route.params.subscribe(params => {
      this.projectDetailId = +params['projectDetailId'];
      this.typeFile = +params['typeFile'];
    });

    this.exportExcel(this.projectDetailId, this.typeFile);
  }

  exportExcel(projectDetailId, typeFile ) {
    this.loading = true;
    this.exportService.getDownloadFileName(projectDetailId, typeFile)
      .subscribe(
        data => {
          this.exportService.downloadFile(projectDetailId, typeFile)
            .subscribe(
              success => {
                saveAs(success, data);
                this.loading = false;
              },
              err => {
                this.loading = false;
                console.log("Server error while downloading file.");
              }
            );

        },
        err => {
          this.loading = false;
          console.log("Server error while downloading file.");
        }
    );
  }
}
