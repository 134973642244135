import { Component, OnDestroy, OnInit } from '@angular/core';
import { ProjectService } from '../../services/project.service';
import { first } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { NotificationService } from 'src/app/common/services/notification.service';
import { AdminProjectService } from '../admin-services/admin-project.service';
import { AdminOverrideCalcValueEnum } from '../models/AdminOverrideCalcValueEnum';
import { OrderSummaryDto, PriceCalculatorDto, ProjectDetailsFullModel, ProjectQuoteStatusEnum } from '../../models/project-details-full-model';
import { Subscription } from 'rxjs';


@Component({
  selector: 'app-admin-order-summary',
  templateUrl: './admin-order-summary.component.html',
  styleUrls: ['./admin-order-summary.component.css']
})

export class AdminOrderSummaryComponent implements OnDestroy, OnInit {

  dto: ProjectDetailsFullModel;
  projectId: any;
  loading: boolean = true;
  dtoPrices: any = {};
  inProgress: boolean = true;
  public totalAssemblyCostd = 2.3453454353;

  constructor(private projectService: ProjectService, private adminProjectService: AdminProjectService, private route: ActivatedRoute,
    private router: Router, private notifyService: NotificationService) {

    this.route.params.subscribe(params => {
      if (params['id']) {
        this.projectId = +params['id'];
        this.loadData();
      }
    });


  }
  ngOnInit(): void {
  }

  loadData() {
    if (isNaN(this.projectId)) {
      return;
    }

    this.loading = false;
    if (this.dto) {
      if (this.dto.details.id != this.projectId) {
        this.dto = null;
      }
    }

    if (!this.dto) {
      this.loading = true;

      this.projectService.getProjectDetailsFull(this.projectId)
        .subscribe(
          (result: ProjectDetailsFullModel) => {
            this.loading = false;
            this.dto = result;
            this.initData();
          },
          (error) => {
            this.loading = false;
          });
    }

  }

  silenceReloadData() {
    this.projectService.getProjectDetailsFull(this.projectId, true)
      .subscribe(
        (result: ProjectDetailsFullModel) => {
          this.loading = false;
          this.dto = result;
          this.initData();
        },
        (error) => {
          this.loading = false;
        });
  }

  initData() {
    if (this.dto) {
      this.dto.prices.bom = this.dto.prices.bom && Number(this.dto.prices.bom).toFixed(2);
      this.dto.prices.assembly.totalAssemblyCost = this.dto.prices.assembly && this.dto.prices.assembly.totalAssemblyCost && Number(this.dto.prices.assembly.totalAssemblyCost).toFixed(2);
      this.dto.prices.otherExpenses.totalCost = this.dto.prices.assembly && this.dto.prices.otherExpenses.totalCost && Number(this.dto.prices.otherExpenses.totalCost).toFixed(2);
      this.dto.prices.customerProposal.cmMargin = this.dto.prices.customerProposal && this.dto.prices.customerProposal.cmMargin && Number(this.dto.prices.customerProposal.cmMargin).toFixed(2);
      this.dto.prices.shipping.shippingPrice = this.dto.prices.shipping && this.dto.prices.shipping.shippingPrice && Number(this.dto.prices.shipping.shippingPrice).toFixed(2);
      this.dto.prices.shipping.shipping = this.dto.prices.shipping && this.dto.prices.shipping.shipping && Number(this.dto.prices.shipping.shipping).toFixed(2);
      this.inProgress = this.dto.prices.quoteStatus == ProjectQuoteStatusEnum.InProgress;
    }
  }

  saveAdminNotes() {
    this.loading = true;
    this.adminProjectService.saveAdminNotes({ projectId: +this.projectId, notes: this.dto.prices.adminNotes }).subscribe(
      (result: any) => {
        this.notifyService.showInfo("Notes saved", null)
        this.loading = false;
      },
      () => {
        this.loading = false;
      });
  }

  saveAdminPrice(item: any) {
    this.loading = true;
    this.adminProjectService.saveAdminPriceDetails({ projectDetailId: item.projectDetailId, unitPrice: +item.unitPrice, deliveryDays: null }).subscribe(
      (result: any) => {
        this.notifyService.showInfo("Price saved", null)
        this.loading = false;
        this.silenceReloadData();
      },
      () => {
        this.loading = false;
      });
  }

  saveAdminDelivery(item: any) {
    this.loading = true;
    this.adminProjectService.saveAdminPriceDetails({ projectDetailId: item.projectDetailId, unitPrice: null, deliveryDays: +item.customerDeliveryDays }).subscribe(
      (result: any) => {
        this.notifyService.showInfo("Delivery day saved", null)
        this.loading = false;
        this.silenceReloadData();
      },
      () => {
        this.loading = false;
      });
  }

  resetAdminPriceDetails(item: any) {
    this.loading = true;
    this.adminProjectService.resetAdminPriceDetails({ projectDetailId: item.projectDetailId }).subscribe(
      (result: any) => {
        this.notifyService.showInfo("Reseted", null)
        this.loading = false;
        this.silenceReloadData();
      },
      () => {
        this.loading = false;
      });
  }

  saveAdminOverrideValues(value, type: AdminOverrideCalcValueEnum) {
    this.loading = true;
    this.adminProjectService.saveAdminOverrideValues({ projectId: +this.projectId, value: value, type: type }).subscribe(
      (result: any) => {
        this.notifyService.showInfo("Saved", null)
        this.loading = false;
        this.silenceReloadData();
      },
      () => {
        this.loading = false;
      });
  }

  removeAdminOverrideValue(type: AdminOverrideCalcValueEnum) {
    this.loading = true;
    this.adminProjectService.removeAdminOverrideValue({ projectId: +this.projectId, type: type }).subscribe(
      (result: any) => {
        this.notifyService.showInfo("Reseted", null)
        this.loading = false;
        this.silenceReloadData();
      },
      () => {
        this.loading = false;
      });
  }

  getPrices() {    
    this.loading = true;
    this.projectService.updateAndGetPrices(this.projectId, this.dto.prices.quantity, false)
      .subscribe(
        (result: any) => {
          this.dto.prices.quoteStatus = ProjectQuoteStatusEnum.InProgress;
          this.inProgress = true;
          this.notifyService.showInfo("Prices updated", null);
          this.loading = false;
          this.silenceReloadData();
        },
        () => {
          this.loading = false;
        });
  }

  setPriceStatus(quoteStatus: ProjectQuoteStatusEnum) {
    this.loading = true;
    this.adminProjectService.setPriceStatus(this.projectId, quoteStatus)
      .subscribe(
        () => {
          this.dto.prices.quoteStatus = quoteStatus;
          this.inProgress = quoteStatus == ProjectQuoteStatusEnum.InProgress;
          this.notifyService.showInfo(`Prices set ${this.inProgress ? 'In Progress' : 'Released'}`, null);
          this.loading = false;
        },
        () => {
          this.loading = false;
        });
  }

  ngOnDestroy(): void {
  }

  public get ProjectQuoteStatusEnum(): typeof ProjectQuoteStatusEnum {
    return ProjectQuoteStatusEnum;
  }
}
