import { Component, OnInit } from '@angular/core';
import { ProjectService } from '../services/project.service';
import { HttpClient, HttpRequest, HttpEventType, HttpResponse } from '@angular/common/http'
import { ActivatedRoute, Router } from '@angular/router';
import { first } from 'rxjs/operators';

declare var $: any;
declare function STLViewerEnable(name, partListPaths, isAsembly): any;

declare function asmClick(): any;
declare function disamClick(): any;

@Component({
  selector: 'app-create-project',
  templateUrl: './create-project.component.html',
  styleUrls: ['./create-project.component.css']
})
export class CreateProjectComponent {

  files3d: any = [];
  name: string = '';
  quantity: number;
  id: number;
  loading: boolean = false;

    constructor(private projectService: ProjectService, private route: ActivatedRoute, private router: Router) {

  }

  receive3DFile($event) {
    this.files3d = $event;
    if ($event.length) {
      var d = new Date();
      if (!this.name) {
        this.name = $event[0].name.replace('.zip', '')
      }
      this.name = this.name + ' ' + d.toLocaleString();
    }
    this.save();
  }

  save() {
    this.loading = true;
    const formData = new FormData();
    if (this.files3d) {
      for (var i = 0; i < this.files3d.length; i++) {
        formData.append(this.files3d[i].name, this.files3d[i]);
      }
    }

    var q = (this.quantity > 0 ? this.quantity : 50);
    formData.append("quantity", q.toString());
    formData.append("name", this.name);
    formData.append("id", this.id + '');
    this.projectService.saveProject(formData);
  }

  goToAssembly() {
    this.router.navigate(['/assembly-project/' + this.id]);
  }

    doAsm() {
        asmClick();
    }

  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

    doDisasm() {
       disamClick();
    }
}
