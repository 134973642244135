import { IGetProjectVisibleScreensListDto } from "../../admin/models/IGetProjectVisibleScreensListDto";
import { User } from "../../models/user";
import { ProjectScreenVisibleEnum } from "../enums/project-scree-vnisible-enum";

export abstract class Utils {

  static isDisableCustomerMenuItem(screen: ProjectScreenVisibleEnum, userData: User, visibleScreensDto: IGetProjectVisibleScreensListDto): boolean {
    if (userData) {
      if (userData.role == 'Admin') {
        return false;
      }
    }

    if (visibleScreensDto) {
      for (var i = 0; i < visibleScreensDto.items.length; i++) {
        if (screen == visibleScreensDto.items[i].visibleScreen) {
          return !visibleScreensDto.items[i].isEnable;
        }
      }
    }

    return false;
  }
}
