import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpEventType } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Router } from '@angular/router';
import { BehaviorSubject, pipe, Observable, of } from 'rxjs';
import { GetManufactureProjectDetailsModel, SubAssemblyModel } from '../../models/manufacture-project-details';
import { ProjectStatistic } from '../../models/manufacture-project-statistic';
import { GetManufactureProjectStationsModel } from '../../models/manufacture-project-stations';
import { ISelectItemDto } from '../models/ISelectItemDto';
import { IGetProjectVisibleScreensListDto } from '../models/IGetProjectVisibleScreensListDto';
import { ISetProjectVisibleScreensDto } from '../models/ISetProjectVisibleScreensDto';
import { IProjectIdDto } from '../models/IProjectIdDto';
import { ISetProjectManufactureDto } from '../models/ISetProjectManufactureDto';
import { IAdminSettingDto } from '../models/IAdminSettingDto';
import { IPriceApiStatusDto } from '../models/IPriceApiStatusDto';
import { RemoveAdminOverrideValuesDto, SaveAdminOverrideValuesDto } from '../models/SaveAdminOverrideValuesDto';
import { SaveAdminPriceDetailsDto } from '../models/SaveAdminPriceDetailsDto';
import { SaveAdminNotesDto } from '../models/SaveAdminNotesDto';
import { ProjectQuoteStatusEnum } from '../../models/project-details-full-model';

@Injectable({
  providedIn: 'root'
})
export class AdminProjectService {

  myAppUrl = '';
  projectDetails: GetManufactureProjectDetailsModel = null;
 /* manufactureProjectData = new BehaviorSubject<GetManufactureProjectDetailsModel>(new GetManufactureProjectDetailsModel());*/

  constructor(private http: HttpClient, private router: Router) {
  }

  getProjects(isAll: boolean = false, name, sort, skip, take) {
    // return this.http.get<any>('/api/project/getDefaultValues/' + material + '/' + process)
    return this.http.post<any>('/api/adminProject/GetProjects/' + isAll +'?name=' + name + '&sort=' + sort + '&skip=' + skip + "&take=" + take, {})
      .pipe(map(response => {

        return response;
      }));
  }

  getAssignedProjects(name, sort, skip, take) {
    return this.http.post<any>('/api/adminProject/getAssignedProjects?name=' + name + '&sort=' + sort + '&skip=' + skip + "&take=" + take, {})
      .pipe(map(response => {

        return response;
      }));
  }

  getAdminSettings() {
    return this.http.get<IAdminSettingDto[]>('/api/adminProject/getAdminSettings')
      .pipe(map(response => {
        return response;
      }));
  }

  saveAdminSetting(dto: any) {
    return this.http.post('/api/adminProject/saveAdminSetting', dto)
      .pipe(map(response => {
        return response;
      }));
  }

  getProjectManufacture() {
    return this.http.get<ISelectItemDto[]>('/api/adminProject/getProjectManufacture')
      .pipe(map(response => {
        return response;
      }));
  }
  
  setProjectManufacture(dto: ISetProjectManufactureDto) {
    return this.http.post('/api/adminProject/setProjectManufacture', dto)
      .pipe(map(response => {
        return response;
      }));
  }

  approveProject(dto: IProjectIdDto) {
    return this.http.post('/api/adminProject/approveProject', dto)
      .pipe(map(response => {
        return response;
      }));
  }

  deleteProject(dto: IProjectIdDto) {
    return this.http.post('/api/adminProject/deleteProject', dto)
      .pipe(map(response => {
        return response;
      }));
  }

  setProjectVisibleScreens(dto: ISetProjectVisibleScreensDto) {
    return this.http.post('/api/adminProject/setProjectVisibleScreens', dto)
      .pipe(map(response => {
        return response;
      }));
  }

  getProjectVisibleScreens(projectId : number) {
    return this.http.post<IGetProjectVisibleScreensListDto>('/api/adminProject/getProjectVisibleScreens', { projectId: projectId })
      .pipe(map(response => {
        return response;
      }));
  }

  getUsersForImpersonate() {
    return this.http.get<ISelectItemDto[]>('/api/adminProject/getUsersForImpersonate')
      .pipe(map(response => {
        return response;
      }));
  }

  getPriceApiStatuses() {
    return this.http.get<IPriceApiStatusDto[]>('/api/adminProject/getPriceApiStatuses')
      .pipe(map(response => {
        return response;
      }));
  }

  saveAdminPriceDetails(data: SaveAdminPriceDetailsDto) {
    return this.http.post('/api/adminProject/saveAdminPriceDetails', data)
      .pipe(map(response => {
        return response;
      }));
  }

  resetAdminPriceDetails(data: SaveAdminPriceDetailsDto) {
    return this.http.post('/api/adminProject/resetAdminPriceDetails', data)
      .pipe(map(response => {
        return response;
      }));
  }

  saveAdminOverrideValues(data: SaveAdminOverrideValuesDto) {
    return this.http.post('/api/adminProject/saveAdminOverrideValues', data)
      .pipe(map(response => {
        return response;
      }));
  }

  saveAdminNotes(data: SaveAdminNotesDto) {
    return this.http.post('/api/adminProject/saveAdminNotes', data)
      .pipe(map(response => {
        return response;
      }));
  }

  removeAdminOverrideValue(data: RemoveAdminOverrideValuesDto) {
    return this.http.post('/api/adminProject/removeAdminOverrideValue', data)
      .pipe(map(response => {
        return response;
      }));
  }

  setPriceStatus(projectId: any, quoteStatus: ProjectQuoteStatusEnum) {
    return this.http.post<any>('/api/adminProject/setPriceStatus/', { projectId: +projectId, quoteStatus: quoteStatus})
      .pipe(map(response => {
        return response;
      }));
  }

}
