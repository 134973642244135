import { Component, OnInit, PipeTransform, Pipe } from '@angular/core';
import { ManufactureProjectService } from '../manufacture-services/manufacture-project.service';
import { first } from 'rxjs/operators';
import { Router } from '@angular/router';
import { Subscription, timer } from 'rxjs';
import { NotificationService } from '../../common/services/notification.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService } from '../../services/auth.service';
import { UserService } from '../../services/user.service';

@Component({
  selector: 'app-manufacture-profile',
  templateUrl: './manufacture-profile.component.html',
  styleUrls: ['./manufacture-profile.component.css']
})
export class ManufactureProfileComponent implements OnInit {
  // tabIndex = 1;
  dto: any = [];
  loading: boolean = false;
  imageSrc: any = null;
  submittedChangePassword = false;
  changePasswordForm: FormGroup;
  constructor(
    private userService: UserService,
    private manufactureProjectService: ManufactureProjectService,
    private router: Router,
    private authService: AuthService,
    private notifyService: NotificationService,
    private formBuilder: FormBuilder  ) { }

  ngOnInit() {
    this.changePasswordForm = this.formBuilder.group({
      oldpassword: ['', Validators.required],
      newpassword: ['', Validators.required],
      confirmpassword: ['', Validators.required]
    });
    this.getData(true);
  }

  getData(isShowLoading: boolean) {

    if (isShowLoading) {
      this.loading = true;
    }

    this.userService.getUserExtendedInfo()
      .pipe(first())
      .subscribe(
        (result: any) => {
          this.dto = result;
          this.loading = false;
        },
        () => {
          this.loading = false;
        });
  }

  //public onChangeTab(index) {
  //  this.tabIndex = index;
  //}

  profileChange() {
    this.loading = true;
    this.userService.saveUserExtendedInfo(this.dto).subscribe(
      (result: any) => {
        this.loading = false;
        this.notifyService.showInfo("Saved", null);
      },
      () => {
        this.loading = false;
      });
  }

  logoFileChangeEvent(fileInput: any) {
    if (fileInput.target.files && fileInput.target.files[0]) {
      const formData = new FormData();
      formData.append(fileInput.target.files[0].name, fileInput.target.files[0]);
      this.manufactureProjectService.saveUserLogo(formData).subscribe(event => {
        if (event['status'] == 200) {
          this.notifyService.showInfo("The company logo was success saved", null)
        }
      });
    }
  }

  deleteLogo() {
    this.manufactureProjectService.deleteUserLogo().subscribe(event => {
      if (event['status'] == 200) {
        this.notifyService.showInfo("The company logo was success deleted", null)
        this.imageSrc = null;
        this.dto.logoPath = '/assets/images/no-image.png';
      }
    });
  }

  readURL(event: any): void {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];

      const reader = new FileReader();
      reader.onload = e => this.imageSrc = reader.result;

      reader.readAsDataURL(file);
      this.logoFileChangeEvent(event);
    }
  }

  get changePasswordFormControl() { return this.changePasswordForm.controls; }

  onChangePassword() {
    this.submittedChangePassword = true;

    if (this.changePasswordForm.invalid) {
      return;
    }
    this.changePasswordForm.setErrors({
      invalidLogin: false,
      errors: []
    });

    this.loading = true;
    this.authService.changePassword(this.changePasswordForm.value)
      .pipe(first())
      .subscribe(
        (result: string) => {
          this.loading = false;
          if (result) {
            this.changePasswordForm.setErrors({
              invalidLogin: true,
              errors: result
            });
          }
          else {
            this.submittedChangePassword = false;

            this.changePasswordForm.reset();
            this.changePasswordForm.setErrors({
              invalidLogin: false,
              sucessLogin: true,
              errors: result
            });

          }
        },
        () => {
          this.loading = false;
          this.changePasswordForm.setErrors({
            invalidLogin: true
          });
        });
  }

}
