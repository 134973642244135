import { Component, OnInit } from '@angular/core';
import { ManufactureProjectService } from '../manufacture-services/manufacture-project.service';
import { first } from 'rxjs/operators';
import { Router, ActivatedRoute } from '@angular/router';
import { GetManufactureProjectDetailsModel } from '../../models/manufacture-project-details';
import { ActionType, DownloadProgressService, RequestType } from '../../services/download-service';
import { ProjectService } from '../../services/project.service';
import { Zip } from '../../common/utils/zip';
import { DomSanitizer } from '@angular/platform-browser';
declare function STLViewerEnable(name, partListPaths, isAsembly, heigth, width, blobs): any;
declare var $: any;

@Component({
  selector: 'app-manufacture-assembly-parts',
  templateUrl: './manufacture-assembly-parts.component.html',
  styleUrls: ['./manufacture-assembly-parts.component.css']
})
export class ManufactureAssemblyPartsComponent implements OnInit {

  dto: GetManufactureProjectDetailsModel;
  projectId: number;
  loading: boolean = true;
  blobs: any[];
  progress: number;
  headerName: any;

  constructor(private manufactureProjectService: ManufactureProjectService, private router: Router, private route: ActivatedRoute,
    private downloadService: DownloadProgressService, private projectService: ProjectService, private domSanitizer: DomSanitizer) { }

  ngOnInit() {
    this.route.params.subscribe(params => {
      this.projectId = params['id'];
      this.getProjectDetail();
    });
  }

  domSanitize(imagePath) {
    return this.domSanitizer.bypassSecurityTrustUrl(this.downloadService.getImage(imagePath));
  }

  hideModal(): void {
    $("#myModal").modal('hide');
  }

  showModal(part): void {
    this.projectService.getStlPrtFile(this.dto.uid, part.name, part.id, null)
      .subscribe(
        (result: any) => {
          this.headerName = part.name;
          var filePath = this.downloadService.getStl(result.path);
          $(".stl-modal-viewer").empty();
          if (result.path && filePath) {
            $(".stl-modal-viewer").append("<div class='stlviewer' data-src='" + filePath + "' data-color='" + result.color + "' width='600px' height='600px' style='display:none'></div>")
            const height = $(window).height() - 360;
            STLViewerEnable("stlviewer", undefined, false, height, $(window).width() - (parseInt($(".content-wrapper").css("padding-left")) + 180), this.blobs);
          }
          else {
            $(".stl-modal-viewer").append("<img src='/assets/images/no-image.png' />")
          }

          $("#myModal").modal('show');
          $(".stl-modal-viewer .stlviewer").show();
        },
        () => {
        });
  }

  isExistsStls(partName: string) {
    if (this.blobs) {
      if (this.blobs.filter((v) => v.name == partName)[0]) {
        return true;
      }
    }

    return false;
  }

  getProjectDetail() {
    this.manufactureProjectService.getProjectDetails(this.projectId)
      .pipe(first())
      .subscribe(
        (result: any) => {
          this.dto = result;
          this.loading = false;
        },
        () => {

        });
  }
}
