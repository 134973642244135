import { Component, OnInit } from '@angular/core';
import { ProjectService } from '../services/project.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-design',
  templateUrl: './design.component.html',
  styleUrls: ['./design.component.css']
})
export class DesignComponent implements OnInit {

  adminData: string;
  id: any;
  buyItems: [];
  makeItems: [];
  nonStandardBuyItems: [];
  cableItems: [];
  pcbaItems: [];
  unidentifiedItems: [];
  constructor(private projectService: ProjectService, private route: ActivatedRoute, private router: Router) {

    this.route.params.subscribe(params => {
      if (params['id']) {
        this.id = +params['id'];
      }
    });
  }

  ngOnInit() {
    this.getDetails();
  }

  getDetails() {
    this.projectService.getDetails(this.id).subscribe(
      (result: string) => {
        this.buyItems = result['buy'];
        this.makeItems = result['make'];
        this.nonStandardBuyItems = result['nonStandardBuy'];
        this.cableItems = result['cable'];
        this.pcbaItems = result['pcba'];
        this.unidentifiedItems = result['unidentified'];
      }
    );
  }

}
