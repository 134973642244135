import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { first } from 'rxjs/operators';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css', '../login-common.css']
})
export class ForgotPasswordComponent implements OnInit {

  loading = false;
  loginForm: FormGroup;
  submitted = false;
  returnUrl: string;
  info = false;

  constructor(private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private authService: AuthService) { }

  ngOnInit() {
    this.loginForm = this.formBuilder.group({
      email: ['', Validators.required]
    });
  }

  get loginFormControl() { return this.loginForm.controls; }

  onSubmit() {
    this.submitted = true;

    if (this.loginForm.invalid) {
      return;
    }

    this.info = false;

    this.loginForm.setErrors({
      invalidLogin: false
    });

    this.loading = true;
    this.authService.forgotPassword(this.loginForm.value)
      .pipe(first())
      .subscribe(
        (result: string) => {
          this.loading = false;

          if (result) {
            this.loginForm.setErrors({
              invalidLogin: true,
              errors: result
            });
          }
          else {
            this.info = true;
          }
        },
        () => {
          this.loading = false;
          //this.loginForm.reset();
          this.loginForm.setErrors({
            invalidLogin: true
          });
        });
  }

}
