import { ChangeDetectorRef, Component, forwardRef, Input, Output, EventEmitter } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, AbstractControl } from '@angular/forms';
import { v4 as uuid } from 'uuid';

const NgSwitchProvider = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => NgSwitchComponent),
  multi: true,
};

@Component({
  selector: 'app-switcher',
  templateUrl: './switcher.component.html',
  styleUrls: ['./switcher.component.scss'],
  providers: [NgSwitchProvider],
})
export class NgSwitchComponent implements ControlValueAccessor {
  disabled: boolean;

  name = `switch3-${uuid()}`;

  @Input()
  ngModel: boolean;

  @Input() ngReadonly: boolean;
  @Input() inline = true;
  @Input() is3State = false;
  @Input() description?: 'full' | 'selected';
  @Input() formControl: AbstractControl;
  @Input() valueDescription = ['Enabled', 'Disabled'];
  @Input() labelClass: string;
  @Output() changed = new EventEmitter<boolean>();

  onChange: (value: boolean) => void = (value: boolean) => {
    if (!this.formControl) return;
    this.formControl.setValue(value);
  };

  onTouched: () => void;

  constructor(private changeDetectorRef: ChangeDetectorRef) {
    
  }

  get value(): boolean {
    return this.ngModel;
  }

  set value(value: boolean) {
    if (value !== this.ngModel) {
      this.ngModel = value;
      this.changed.emit(value);
    }
  }

  writeValue(value: boolean): void {
    this.value = this.is3State ? value : !!value;
    this.changeDetectorRef.markForCheck();
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  registerOnChange(fn: (value: boolean) => void): void {
    this.onChange = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  change(value: boolean): void {
    this.value = value;
    this.onTouched && this.onTouched();
    this.onChange(value);
  }
}
