import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpEventType } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Router } from '@angular/router';
import { BehaviorSubject, pipe, Observable, of } from 'rxjs';
import { GetManufactureProjectDetailsModel, SubAssemblyModel } from '../../models/manufacture-project-details';
import { ProjectStatistic } from '../../models/manufacture-project-statistic';
import { GetManufactureProjectStationsModel } from '../../models/manufacture-project-stations';
import { ProjectStatisticMonth } from '../../models/manufacture-project-statistic-month';

@Injectable({
  providedIn: 'root'
})
export class ManufactureProjectService {

  myAppUrl = '';
  projectDetails: GetManufactureProjectDetailsModel = null;
  manufactureProjectData = new BehaviorSubject<GetManufactureProjectDetailsModel>(new GetManufactureProjectDetailsModel());

  constructor(private http: HttpClient, private router: Router) {
  }

  getProjects(name, sort, skip, take) {
    return this.http.post<any>('/api/manufactureProject/GetProjects?name=' + name + '&sort=' + sort + '&skip=' + skip + "&take=" + take, {})
      .pipe(map(response => {

        return response;
      }));
  }

  getArchiveProjects(name, sort, skip, take) {
    return this.http.post<any>('/api/manufactureProject/getArchiveProjects?name=' + name + '&sort=' + sort + '&skip=' + skip + "&take=" + take, {})
      .pipe(map(response => {

        return response;
      }));
  }

  getSubAssemblies(projectId: number): Observable<SubAssemblyModel[]>
  {
    return this.http.get<any>('/api/manufactureProject/getSubAssemblies?projectId=' + projectId)
      .pipe(map(response => {

        return response;
      }));
  }

  getProjectDetails(id, reload = false): Observable<GetManufactureProjectDetailsModel> {
    if (this.projectDetails) {
      if (this.projectDetails.id != id) {
        this.projectDetails = null;
      }
    }

    if (reload) {
      this.projectDetails = null;
    }

    return !!this.projectDetails ? of(this.projectDetails) :
      this.http.post<any>('/api/manufactureProject/getProjectDetails/', { projectId: +id })
        .pipe(map(response => {
          this.manufactureProjectData.next(response);
          this.projectDetails = response;
          return response;
        })
        );
  }

  getProjectDetailsForStations(id, reload = false): Observable<GetManufactureProjectStationsModel> {
    

    return this.http.post<any>('/api/manufactureProject/getProjectDetailsForStations/', { projectId: +id })
        .pipe(map(response => {
          //this.manufactureProjectData.next(response);
          
          return response;
        })
        );
  }

  saveProjectStatus(projectId: number, projectStatus: number) {
    return this.http.post<any>('/api/manufactureProject/saveProjectStatus/', { projectId: +projectId, projectStatus: projectStatus })
      .pipe(map(response => {
        this.projectDetails = null;
        return response;
      }));
  }

  saveProjectInfo(projectId: number, specialRequirements: string, cosmeticInspectionRequirements: string, packagingRequirements: string, functionalTestingrequirements: string) {
    return this.http.post<any>('/api/manufactureProject/saveProjectInfo/', {
      projectId: +projectId,
      specialRequirements: specialRequirements,
      cosmeticInspectionRequirements: cosmeticInspectionRequirements,
      packagingRequirements: packagingRequirements,
      functionalTestingrequirements: functionalTestingrequirements
    })
      .pipe(map(response => {

        return response;
      }));
  }

  getPrices(projectId: number) {
    return this.http.post<any>('/api/manufactureProject/getPrices/', { projectId: +projectId })
      .pipe(map(response => {
        return response;
      }));
  }

  saveSelectManufacture(projectDetailId: number, selected: boolean) {
    return this.http.post<any>('/api/manufactureProject/saveSelectManufacture/', { projectDetailId: +projectDetailId, selected: selected })
      .pipe(map(response => {
        return response;
      }));
  }

  saveProjectAvailableStationPersons(projectId: number, availableStationPersons: number) {
    return this.http.post('/api/manufactureProject/saveProjectAvailableStationPersons/', { projectId: +projectId, availableStationPersons: +availableStationPersons })
  }

  getProjectStatistics() {
    return this.http.get<ProjectStatistic[]>('/api/manufactureProject/getProjectStatistics')
      .pipe(map(response => {
        return response;
      }));
  }

  getProjectStatisticsMonth() {
    return this.http.get<ProjectStatisticMonth[]>('/api/manufactureProject/getProjectStatisticsMonth')
      .pipe(map(response => {
        return response;
      }));
  }

  getUserSettings() {
    return this.http.get<any>('/api/manufactureProject/getUserSettings')
      .pipe(map(response => {
        return response;
      }));
  }

  saveUserSetting(dto: any) {
    return this.http.post('/api/manufactureProject/saveUserSetting', dto)
      .pipe(map(response => {
        return response;
      }));
  }


  saveUserLogo(formData: any) {
    const uploadReq = new HttpRequest('POST', 'api/manufactureProject/saveUserLogo/', formData, {
      reportProgress: true,
    });
    return this.http.request(uploadReq);
  }

  deleteUserLogo() {
    const uploadReq = new HttpRequest('DELETE', 'api/manufactureProject/deleteUserLogo/', {
      reportProgress: true,
    });
    return this.http.request(uploadReq);
  }

  getProjectZipFile(id: number, name: string) {
    return this.http.post('/api/manufactureProject/getProjectZipFile/', { id: +id, name: name }, { responseType: 'blob' })
  }

}
