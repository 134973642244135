import { Component, Inject, OnInit} from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { fromEvent } from 'rxjs';

@Component({
  selector: 'app-scroll-top',
  templateUrl: './scroll-top.component.html',
  styleUrls: ['./scroll-top.component.css'],

})

export class ScrollTopComponent implements OnInit {

  scrollTop = window.document.getElementById('content');
  showScroll$: boolean = false;

  constructor(@Inject(DOCUMENT) private readonly document: Document) { }

  scrollToTop()
  {
     this.scrollTop.scrollTo(0, 0);
  }

  ngOnInit() {
    fromEvent(window.document.getElementById('content'), 'scroll').subscribe(
      (e: Event) =>
      {
        if (e.target['scrollTop'] > 20) {
          this.showScroll$ = true;
        }
        else
        {
          this.showScroll$ = false;
        }
      });
  }
}
