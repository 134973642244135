import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { first } from 'rxjs/operators';
import { debug } from 'util';
import { NotificationService } from '../../common/services/notification.service';
import { ManufactureProjectService } from '../../manufacture/manufacture-services/manufacture-project.service';
import { User } from '../../models/user';
import { AuthService } from '../../services/auth.service';
import { SignalRService } from '../../services/signal-r.service';
import { UserService } from '../../services/user.service';

@Component({
  selector: 'app-account-settings',
  templateUrl: './account-settings.component.html',
  styleUrls: ['./account-settings.component.css']
})
export class AccountSettingsComponent implements OnInit {

  loading = false;
  changePasswordForm: FormGroup;
  detailsForm: FormGroup;
  submittedChangePassword = false;
  returnUrl: string;
  tabIndex = 1;
  userDataSubscription: any;
  userData = new User();
  imageSrc: any = null;
  dto: any = [];

  constructor(private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private authService: AuthService,
    private notifyService: NotificationService,
    private userService: UserService,
    private signalR: SignalRService) {
  }

  ngOnInit() {
    this.changePasswordForm = this.formBuilder.group({
      oldpassword: ['', Validators.required],
      newpassword: ['', Validators.required],
      confirmpassword: ['', Validators.required]
    });

    this.detailsForm = this.formBuilder.group({
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      companyName: ['', Validators.nullValidator],
      email: ['', Validators.email],
      phoneNumber: ['', Validators.nullValidator],
      contactRole: ['', Validators.nullValidator],
    });
    this.getData(true);
  }

  getData(isShowLoading: boolean) {
    if (isShowLoading) {
      this.loading = true;
    }
    
    this.userService.getUserExtendedInfo()
      .pipe(first())
      .subscribe(
        (result: any) => {
          this.userData = result;
          this.userData.isFbUserId = !!this.userData.fbUserId;
          
          this.detailsForm.setValue({
            firstName: this.userData.firstName,
            lastName: this.userData.lastName,
            companyName: this.userData.companyName,
            phoneNumber: this.userData.phoneNumber,
            email: this.userData.email,
            contactRole: this.userData.contactRole
          });
          this.loading = false;
        },
        () => {
          this.loading = false;
        });
  }

  get changePasswordFormControl() { return this.changePasswordForm.controls; }

  public onChangeTab(index) {
    this.tabIndex = index;
  }

  onChangePassword() {
    this.submittedChangePassword = true;

    if (this.changePasswordForm.invalid) {
      return;
    }
    this.changePasswordForm.setErrors({
      invalidLogin: false,
      errors: []
    });

    this.loading = true;
    this.authService.changePassword(this.changePasswordForm.value)
      .pipe(first())
      .subscribe(
        (result: string) => {
          this.loading = false;
          if (result) {
            this.changePasswordForm.setErrors({
              invalidLogin: true,
              errors: result
            });
          }
          else {
            this.submittedChangePassword = false;
          
            this.changePasswordForm.reset();
            this.changePasswordForm.setErrors({
              invalidLogin: false,
              sucessLogin: true,
              errors: result
            });

          }
        },
        () => {
          this.loading = false;
          //this.changePasswordForm.reset();
          this.changePasswordForm.setErrors({
            invalidLogin: true
          });
        });
  }

  onDetailsSubmit() {
    if (this.detailsForm.invalid) {
      this.notifyService.showWarning("Not all required fields are filled", null)
      return;
    }
    this.detailsForm.setErrors({
      invalidLogin: false,
      errors: []
    });
    this.loading = true;
    this.userService.saveUserExtendedInfo(this.detailsForm.value).pipe(first())
      .subscribe(
        (result: string) => {

          this.loading = false;
          if (result) {
            this.detailsForm.setErrors({
              invalidLogin: true,
              errors: result
            });
          }
          else {
            this.submittedChangePassword = false;

            this.detailsForm.reset();
            this.detailsForm.setErrors({
              invalidLogin: false,
              sucessLogin: true,
              errors: result
            });
          }
          this.notifyService.showInfo("Profile data was successfully updated", null)
        },
        () => {
          this.loading = false;
          //this.changePasswordForm.reset();
          this.changePasswordForm.setErrors({
            invalidLogin: true
          });
        });
    
  }

  deleteLogo() {
    this.authService.deleteUserLogo().subscribe(isDelete => {
      if (isDelete) {
        this.notifyService.showInfo("The avater image was success deleted", null)
        this.imageSrc = null;
        this.userData.avatarPath = '/assets/images/no-image.png';
      }
    });
  }

  logoFileChangeEvent(fileInput: any) {
    if (fileInput.target.files && fileInput.target.files[0]) {
      const formData = new FormData();
      formData.append(fileInput.target.files[0].name, fileInput.target.files[0]);
      this.authService.saveUserLogo(formData).subscribe(src => {
        if (src) {
          this.authService.changeavatar(src);
          this.notifyService.showInfo("The avater image  was success saved", null)
        }
      });
    }
  }

  readURL(event: any): void {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];

      const reader = new FileReader();
      reader.onload = e => this.imageSrc = reader.result;

      reader.readAsDataURL(file);
      this.logoFileChangeEvent(event);
    }
  }
}
