import { Component, OnInit } from '@angular/core';
import { ManufactureProjectService } from '../manufacture-services/manufacture-project.service';
import { first } from 'rxjs/operators';
import { Router, ActivatedRoute } from '@angular/router';
import { GetManufactureProjectDetailsModel, SubAssemblyModel } from '../../models/manufacture-project-details';
import { ActionType, DownloadProgressResult, DownloadProgressService, RequestType } from '../../services/download-service';
import { Zip } from '../../common/utils/zip';
import { ProjectService } from '../../services/project.service';

declare var $: any;

declare function asmClick(): any;
declare function disamClick(): any;
declare function pauseClick(): any;
declare function repeatClick(): any;
declare function continueClick(): any;
declare function nextStepClick(): any;
declare function prevStepClick(): any;
declare function isNextPrevEnabled(isNext): any;
declare function STLViewerEnable(name, partListPaths, isAsembly, heigth, width, blobs): any;
declare function restartAnimation(): any;

@Component({
  selector: 'app-manufacture-assembly',
  templateUrl: './manufacture-assembly.component.html',
  styleUrls: ['./manufacture-assembly.component.css']
})
export class ManufactureAssemblyComponent implements OnInit {


  dto: GetManufactureProjectDetailsModel;
  projectId: number;
  asmId: number;
  show3dModelView: boolean = false;
  isLoaded3dModelView: boolean = false;
  is3dModelViewLoading: boolean = true;
  HideDisassem: boolean = false;
  HideAssem: boolean = false;
  HidePause: boolean = false;
  HideContinue: boolean = false;
  HideRepeat: boolean = false;
  doDisasmEnabled: boolean;
  doAsmEnabled: boolean;
  doPauseEnabled: boolean;
  doContinueEnabled: boolean;
  doRepeatEnabled: boolean;
  doPrevStepClickEnabled: boolean;
  doNextStepClickEnabled: boolean;
  
  showPlay: boolean = false;
  subAssemblies: SubAssemblyModel[];
    progress: number;
    blobs: any[];

  constructor(private manufactureProjectService: ManufactureProjectService, private router: Router, private route: ActivatedRoute,
    private downloadService: DownloadProgressService, private projectService: ProjectService) {

    this.downloadService.stlsLoadData.asObservable().subscribe((data: DownloadProgressResult) => {
      if (data) {
        this.progress = data.progress;
        if (data.finished) {
          this.blobs = data.blobs;
          if (this.progress == 100 && this.dto) {
            this.fillData();
          }
        }
      }
    });
}

  ngOnInit() {
    this.route.params.subscribe(params => {
      this.projectId = +params['id'];
      this.asmId = this.projectId;
      this.loadData(this.projectId);

      this.manufactureProjectService.getSubAssemblies(this.projectId).pipe(first())
        .subscribe((result: SubAssemblyModel[]) => {
          this.subAssemblies = result;
          if (this.subAssemblies.length) {
            this.subAssemblies[0]['name'] = 'Main Assembly';
          }
        });
    });
    
  }

  loadData(projectId: number) {
    this.asmId = projectId;
    this.showPlay = false;

    this.manufactureProjectService.getProjectDetails(this.projectId)
      .pipe(first())
      .subscribe(
        (result: any) => {

          this.dto = result;
          if (this.progress == 100 && this.dto) {
            this.fillData();
          }
         
        })
  }

  fillData() {
    this.isLoaded3dModelView = false;
    restartAnimation();

    let object3dModels = [];

    for (var i = 0; i < this.dto.project3dModels.length; i++) {
      if (this.dto.project3dModels[i].projectId == this.asmId) {
        object3dModels = this.dto.project3dModels[i].models;
      }
    }

    this.setButtonsStatus(null);
    $(".stl-modal-viewer-all").empty();
    $(".stl-modal-viewer-all").append("<div style='text-align: center; height: 100%; display: flex; align-items: center; justify-content: center; height: 100 %;' class='stlviewerAll manufacture' width='10000px' height='10000px' style='display:none'></div>")

    const height = $(window).height() - 360;
    const width = $(window).width() > 768 ? $(window).width() - 274 : $(window).width() - 50;
    STLViewerEnable("stlviewerAll", object3dModels, true, height, width, this.blobs);

    let asmInterval;
    clearInterval(asmInterval);
    asmInterval = setInterval(() => {
      if (window['isReadyDisassembled'] == true) {
        this.show3dModelView = !this.show3dModelView;
        this.isLoaded3dModelView = true;

        this.is3dModelViewLoading = true;
        clearInterval(asmInterval);
        this.showPlay = true;
        setTimeout(() => {
          $(".stl-modal-viewer-all-alt").attr('style', 'height: calc(100vh - 350px); border: 1px solid rgba(0,0,0,.1);');
          $(".stl-modal-viewer-all").attr('style', 'height: calc(100vh - 350px); border: 1px solid rgba(0,0,0,.1);');
        }, 1)
      }
    }, 10);
  }

  doAsm() {
    this.showPlay = false;
    this.setButtonsStatus('doAsm');
    asmClick();
  }

  doDisasm() {
    this.setButtonsStatus('doDisasm');
    if (disamClick()) {
    }
  }

  doPause() {
    this.setButtonsStatus('doPause');
    pauseClick();
  }

  doRepeat() {
    this.setButtonsStatus('doRepeat');
    repeatClick();
  }

  doContinue() {
    this.setButtonsStatus('doContinue');
    continueClick();
  }

  doNextStepClick() {
    nextStepClick();
    this.setButtonsStatus('doNextStepClick');
  }

  doPrevStepClick() {
    prevStepClick();
    this.setButtonsStatus('doPrevStepClick');
  }

  setButtonsStatus(mode = null) {
    this.doDisasmEnabled = false;
    this.doAsmEnabled = false;
    this.doPauseEnabled = false;
    this.doContinueEnabled = false;
    this.doRepeatEnabled = false;
    this.doPrevStepClickEnabled = false;
    this.doNextStepClickEnabled = false;

    if (mode == null) {
      this.doAsmEnabled = true;
    }
    else if (mode == 'doAsm' || mode == 'doDisasm' || mode == 'doContinue' || mode == 'doRepeat') {
      this.doDisasmEnabled = true;
      this.doAsmEnabled = true;
      this.doPauseEnabled = true;
      this.doRepeatEnabled = true;
      this.doPrevStepClickEnabled = isNextPrevEnabled(false);
      this.doNextStepClickEnabled = isNextPrevEnabled(true);
    }
    else if (mode == 'doPause' || mode == 'doPrevStepClick' || mode == 'doNextStepClick') {
      this.doDisasmEnabled = true;
      this.doAsmEnabled = true;
      this.doContinueEnabled = true;
      this.doRepeatEnabled = true;
      this.doPrevStepClickEnabled = isNextPrevEnabled(false);
      this.doNextStepClickEnabled = isNextPrevEnabled(true);
    }
  }
}
