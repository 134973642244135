import { Component } from '@angular/core';
import { Location } from '@angular/common';
import { AuthService } from '../services/auth.service';
import { User } from '../models/user';
import { UserRole } from '../models/roles';
import { Router, ActivatedRoute } from '@angular/router';
import { GetManufactureProjectDetailsModel } from '../models/manufacture-project-details';
import { ManufactureProjectService } from '../manufacture/manufacture-services/manufacture-project.service';
import { Subscription, timer } from 'rxjs';
import { first } from 'rxjs/operators';
import { NotificationService } from 'src/app/common/services/notification.service';
import { ProjectService } from '../services/project.service';
import { SignalRService } from '../services/signal-r.service';
import { AdminProjectService } from '../admin/admin-services/admin-project.service';
import { ISelectItemDto } from '../admin/models/ISelectItemDto';
import { IPriceApiStatusDto } from '../admin/models/IPriceApiStatusDto';
import { DownloadProgressService } from '../services/download-service';

@Component({
  selector: 'app-nav-menu',
  templateUrl: './nav-menu.component.html',
  styleUrls: ['./nav-menu.component.css']
})
export class NavMenuComponent {
  isExpanded = false;
  userDataSubscription: any;
  userData = new User();
  countDown: Subscription;

  manufactureProjectDataSubscription: any;
  manufactureProjectData: GetManufactureProjectDetailsModel;

  userRole = UserRole;
  showWelcomeBack = false;
  showNextButton = false;
  showBackButton = false;
  showProjectMenu = false;
  manufactureTextBackScreen: string;
  manufactureTextNextScreen: string;
  isManufactureTextScreen = false;
  projectId: number;
  showMenuProjectUserCM = false;
  projectIdFromUrl?= null;
  showLeftMenu: boolean = false;
  issueCount: number = 0;
  impersonateUser: string = null;

  tick = 1000;
  userListImpersonate: ISelectItemDto[];
  priceApiStatuses: IPriceApiStatusDto[];
  subscription: Subscription;
  photoUrl = "";

  constructor(private authService: AuthService, private projectService: ProjectService, private adminProjectService: AdminProjectService,
    private router: Router, private manufactureProjectService: ManufactureProjectService, private route: ActivatedRoute,
    private location: Location, private notifyService: NotificationService, private signalR: SignalRService, private downloadService: DownloadProgressService) {

    this.photoUrl = localStorage.getItem("photoUrl");

    this.manufactureProjectDataSubscription = this.manufactureProjectService.manufactureProjectData.asObservable().subscribe(data => {
      this.manufactureProjectData = data;
    });

    this.countDown = timer(0, this.tick).subscribe(() => {
      if (this.manufactureProjectData) {
        this.manufactureProjectData.secondsLeft = --this.manufactureProjectData.secondsLeft;
      }
    });

    this.userDataSubscription = this.authService.userData.asObservable().subscribe(data => {
      console.log(" NavMenuComponent userDataSubscription");
      this.userData = data;
      this.userData.isFbUserId = !!this.userData.fbUserId;

      if (this.userData.isFbUserId && this.userData.avatarPath) {
        this.userData.avatarPath = decodeURIComponent(this.userData.avatarPath);
      }

      if (this.userData.role) {
        if (this.userData.role == 'Admin') {
          this.adminProjectService.getUsersForImpersonate().subscribe(data => {
            this.userListImpersonate = data;
          });

          this.adminProjectService.getPriceApiStatuses().subscribe(data => {
            this.priceApiStatuses = data;
          });
        }

        this.signalR.startConnection(this.userData.userId);
        if (this.subscription != null) { this.subscription.unsubscribe(); }
        this.subscription = this.signalR.addListenerRefreshIssues().subscribe((data: any) => {
          if (this.userData.role != 'ManufactureUser') {
            let id = window.location.href.substring(window.location.href.lastIndexOf('/') + 1);
            if (!isNaN(+id)) {
              if (localStorage.getItem('projectId') && localStorage.getItem('authToken')) {
                this.projectId = +localStorage.getItem('projectId');
                console.log(this.projectId);
                this.getProjectIssues();
              }
            }
          }
        });
      
      }
    });
    
    router.events.subscribe((val) => {
      if (val["url"]) {
        this.projectId = val["url"].replace(/^\D+/g, '');

        if (val["url"].indexOf('dashboard') != -1) { 
          this.showWelcomeBack = true;
        }
        else {
          this.showWelcomeBack = false;
        }

        this.projectIdFromUrl = val["url"].replace(/^\D+/g, '');
        if (val["url"].indexOf('edit-project') != -1 ||
          val["url"].indexOf('assembly-project') != -1 ||
          val["url"].indexOf('issues') != -1 ||
          val["url"].indexOf('details') != -1 ||
          val["url"].indexOf('notes') != -1 ||
          val["url"].indexOf('order-summary') != -1) {
          this.showLeftMenu = true;
        }
        else {
          this.showLeftMenu = false;
        }

        if (val["url"].indexOf('edit-project') != -1 ||
          val["url"].indexOf('assembly-project') != -1 ||
          val["url"].indexOf('issues') != -1 ||
          val["url"].indexOf('details') != -1 ||
          val["url"].indexOf('notes') != -1 ||
          val["url"].indexOf('order-summary') != -1 ||
          val["url"].indexOf('manufacture-assembly-parts') != -1 ||
          val["url"].indexOf('create-project') != -1 ||
          val["url"].indexOf('manufacture-product-assembly') != -1 ||
          val["url"].indexOf('manufacture-assembly') != -1 ||
          val["url"].indexOf('manufacture-other-requirments') != -1 ||
          val["url"].indexOf('manufacture-view-price') != -1 ||
          val["url"].indexOf('manufacture-product-overview') != -1 ||
          val["url"].indexOf('account-settings') != -1 ||
          (this.userData && this.userData.role == 'ManufactureUser' && val["url"].indexOf('manufacture-dashboard') == -1  )) {
          this.showNextButton = true;
        }
        else {
          this.showNextButton = false;
        }

        if (val["url"].indexOf('manufacture-archive') != -1 ||
          val["url"].indexOf('manufacture-settings') != -1 ||
          val["url"].indexOf('manufacture-statistics') != -1 ||
          val["url"].indexOf('manufacture-profile') != -1

        ) {
          this.showProjectMenu = false;
        }
        else {
          this.showProjectMenu = true;
        }

        if (val["url"].indexOf('manufacture-product-overview') != -1 ||
          val["url"].indexOf('manufacture-assembly-parts') != -1 ||
          val["url"].indexOf('manufacture-assembly') != -1 ||
          val["url"].indexOf('manufacture-product-assembly') != -1 ||
          val["url"].indexOf('manufacture-other-requirments') != -1 ||
          val["url"].indexOf('manufacture-view-price') != -1 ||
          val["url"].indexOf('manufacture-summary') != -1) {
          this.showMenuProjectUserCM = true;
        }
        else {
          this.showMenuProjectUserCM = false;
        }


        if (val["url"].indexOf('manufacture-assembly-parts') != -1 ||
          val["url"].indexOf('manufacture-product-assembly') != -1 ||
          val["url"].indexOf('manufacture-assembly') != -1 ||
          val["url"].indexOf('manufacture-other-requirments') != -1 ||
          val["url"].indexOf('manufacture-view-price') != -1 ||
          val["url"].indexOf('manufacture-summary') != -1) {
          this.showBackButton = true;
          
        }
        else {
          this.showBackButton = false;
          this.isManufactureTextScreen = false;
        }

        // chat init
        if (this.userData.role == this.userRole.User && !this.userData.impersonatedUserName) {
          if (val["url"].indexOf('issues') != -1 || val["url"].indexOf('notes') != -1) {
            window.Intercom('shutdown');
          } else if (this.userData && this.userData.firstName) {
            let fullName = localStorage.getItem("fullName");
            fullName = fullName ? fullName : this.userData.firstName;
            window["initChat"](this.userData.firstName, fullName);
          }
        }


        //if (val["url"].indexOf('manufacture-product-overview') != -1) {
        //  this.manufactureTextNextScreen = "Go to Assembly";
        //} else if (val["url"].indexOf('manufacture-assembly-parts') != -1) {
        //  this.manufactureTextNextScreen = "View Assembly Animation";
        //} else if (val["url"].indexOf('manufacture-assembly') != -1) {
        //  this.manufactureTextNextScreen = "Product Assembly – Line Layout";
        //}
        //else if (val["url"].indexOf('manufacture-product-assembly') != -1) {
        //  this.manufactureTextNextScreen = "General notes & other requirements";
        //}
        //else if (val["url"].indexOf('manufacture-other-requirments') != -1) {
        //  this.manufactureTextNextScreen = "Your price";
        //}
        //else if (val["url"].indexOf('manufacture-view-price') != -1) {
        //  this.manufactureTextNextScreen = "Summary & Acceptance";
        //} else if (val["url"].indexOf('manufacture-summary') != -1) {
        //  this.manufactureTextNextScreen = '';
        //}

        //if (val["url"].indexOf('manufacture-product-overview') != -1) {
        //  this.manufactureTextBackScreen = "";
        //} else if (val["url"].indexOf('manufacture-assembly-parts') != -1) {
        //  this.manufactureTextBackScreen = "Product Overview";
        //} else if (val["url"].indexOf('manufacture-assembly') != -1) {
        //  this.manufactureTextBackScreen = "Go to Assembly";
        //}
        //else if (val["url"].indexOf('manufacture-product-assembly') != -1) {
        //  this.manufactureTextBackScreen = "View Assembly Animation";
        //}
        //else if (val["url"].indexOf('manufacture-other-requirments') != -1) {
        //  this.manufactureTextBackScreen = "Product Assembly – Line Layout";
        //}
        //else if (val["url"].indexOf('manufacture-view-price') != -1) {
        //  this.manufactureTextBackScreen = "General notes & other requirements";
        //} else if (val["url"].indexOf('manufacture-summary') != -1) {
        //  this.manufactureTextBackScreen = 'Your price';
        //}
      }
    });
  }
  ngOnDestroy(){
    if (this.subscription != null) { this.subscription.unsubscribe(); }
  }
  ngOnInit() {
    let id = window.location.href.substring(window.location.href.lastIndexOf('/') + 1);
    if (!isNaN(+id)) {
      this.projectService.getProjectFileZipped(+id).subscribe(
        (data) => {
          this.downloadService.getStls(data.stlFiles);
          this.downloadService.getImages(data.imageFiles);
        });
    }
  }

  isActiveMenu(menuName) {
    return window.location.pathname.indexOf(menuName) != -1
  }

  collapse() {
    this.isExpanded = false;
  }

  toggle() {
    this.isExpanded = !this.isExpanded;
  }

  logout(event) {
    event.preventDefault();
    event.stopPropagation();
    this.authService.logout();
    localStorage.removeItem('fullName');
    if (window && window.Intercom) {
      window.Intercom('shutdown');
    }
  }

  //goToNextmanufactureScreen(event) {
  //  event.preventDefault();
  //  event.stopPropagation();
  //  if (this.manufactureTextNextScreen == "Go to Assembly") {
  //    this.router.navigate(['/manufacture-assembly-parts/' + this.projectId]);
  //  } else if (this.manufactureTextNextScreen == "View Assembly Animation") {
  //    this.router.navigate(['/manufacture-assembly/' + this.projectId]);
  //  } else if (this.manufactureTextNextScreen == "Product Assembly – Line Layout") {
  //    this.router.navigate(['/manufacture-product-assembly/' + this.projectId]);
  //  }
  //  else if (this.manufactureTextNextScreen == "General notes & other requirements") {
  //    this.router.navigate(['/manufacture-other-requirments/' + this.projectId]);
  //  }
  //  else if (this.manufactureTextNextScreen == "Your price") {
  //    this.router.navigate(['/manufacture-view-price/' + this.projectId]);
  //  }
  //  else if (this.manufactureTextNextScreen == "Summary & Acceptance") {
  //    this.router.navigate(['/manufacture-summary/' + this.projectId]);
  //  }
  //}

  goToManufactureScreen(event, router) {
    event.preventDefault();
    event.stopPropagation();
    if (router == "/manufacture-product-overview/") {
      this.router.navigate(['/manufacture-product-overview/' + this.projectId]);
    } else if (router == "/manufacture-assembly-parts/") {
      this.router.navigate(['/manufacture-assembly-parts/' + this.projectId]);
    } else if (router == "/manufacture-assembly/") {
      this.router.navigate(['/manufacture-assembly/' + this.projectId]);
    } else if (router == "/manufacture-product-assembly/") {
      this.router.navigate(['/manufacture-product-assembly/' + this.projectId]);
    } else if (router == "/manufacture-other-requirments/") {
      this.router.navigate(['/manufacture-other-requirments/' + this.projectId]);
    } else if (router == "/manufacture-view-price/") {
      this.router.navigate(['/manufacture-view-price/' + this.projectId]);
    } else if (router == "/manufacture-summary/") {
      this.router.navigate(['/manufacture-summary/' + this.projectId]);
    }
  }

  goCustomerScreen(event, router) {
    event.preventDefault();
    event.stopPropagation();
    if (router == "notes") {
      this.router.navigate(['/notes/' + this.projectId]);
    } else if (router == "issues") {
      this.router.navigate(['/issues/' + this.projectId]);
    } else if (router == "order-summary") {
      this.router.navigate(['/order-summary/' + this.projectId]);
    } else if (router == "details") {
      this.router.navigate(['/details/' + this.projectId]);
    } else if (router == "assembly-project") {
      this.router.navigate(['/assembly-project/' + this.projectId]);
    } else if (router == "edit-project") {
      this.router.navigate(['/edit-project/' + this.projectId]);
    } 
  } 

  goToBackmanufactureScreen(event) {
    event.preventDefault();
    event.stopPropagation();
    this.location.back();
  }

  updateProjectStatus(status) {
    this.manufactureProjectService.saveProjectStatus(this.projectId, status)
      .pipe(first())
      .subscribe(
        (result: any) => {
          this.notifyService.showInfo("Status changed", null);
          this.router.navigate(['/manufacture-dashboard']);
        },
        () => {

        });
  }

  getProjectIssues() {
    this.projectService.getProjectIssues(this.projectId)
      .pipe(first())
      .subscribe(
        (result: any) => {
          this.issueCount = result.length;
        },
        () => {
          this.issueCount = 0
        });
  }

  imprsonateIn() {
    this.authService.imprsonateIn(this.impersonateUser)
      .subscribe(
        (result: any) => {
          this.impersonateUser = null;
          if (window && window.Intercom) {
            window.Intercom('shutdown');
          }
        },
        () => {

        });
  }

  imprsonateOut() {
    this.authService.imprsonateOut(this.userData.impersonatedUserId)
      .subscribe(
        (result: any) => {
          if (window && window.Intercom) {
            window.Intercom('shutdown');
          }
        },
        () => {

        });
  }
}
