import { ProjectDetailTypeEnum } from "../enums/project-detail-type-enum";
import { KeyValuePair } from "../model/key-value-pair";

export abstract class EnumUtils {

  static getProjectDetailTypes(): KeyValuePair [] {
    let arr = [];
    for (var enumMember in ProjectDetailTypeEnum) {
      var isValueProperty = parseInt(enumMember, 10) >= 0
      if (isValueProperty) {
        let val = ProjectDetailTypeEnum[enumMember];
        if (val === 'Buy') {
          val = 'Standard Buy';
        }
        else if (val === 'Unidentified') {
          continue;
        }
        else if (val === 'NonStandardBuy') {
          val = 'Non-Standard Buy';
        }

        arr.push({ key: enumMember, value: val });
      }
    }

    return arr.sort((a, b) => a.value.localeCompare(b.value));
  }

  //static getProjectDetailTypeEnum(val: string): ProjectDetailTypeEnum {
    
  //}
}
