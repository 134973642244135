import { Component, OnInit, Output } from '@angular/core';
import { ManufactureProjectService } from '../manufacture-services/manufacture-project.service';
import { first, ignoreElements } from 'rxjs/operators';
import { Router, ActivatedRoute } from '@angular/router';
import { GetManufactureProjectDetailsModel } from '../../models/manufacture-project-details';
import { NotificationService } from '../../common/services/notification.service';
import { ProductLineTaskEnum } from '../../common/enums/product-line-task-enum';
import { Input, EventEmitter } from '@angular/core';
import { GetManufactureProjectStationsModel } from '../../models/manufacture-project-stations';
import { DomSanitizer } from '@angular/platform-browser';
import { DownloadProgressService } from '../../services/download-service';

@Component({
  selector: 'app-manufacture-product-assembly-stations',
  templateUrl: './manufacture-product-assembly-stations.component.html',
  styleUrls: ['./manufacture-product-assembly.component.css']
})
export class ManufactureProductAssemblyStationsComponent implements OnInit {

  dto: GetManufactureProjectStationsModel;
  @Input() projectId: number;
  @Output() totalAreaRequiredProdFloorChange: EventEmitter<number> = new EventEmitter();
  headersStations: string[] = [];
  headersStationIcons: string[] = [];
  headersStationPartImages: string[][];
  footersStations: number[] = [];
  footerSum: number = 0;
  bodyStationsMaxLenght: number = 0;
  constructor(private manufactureProjectService: ManufactureProjectService, private router: Router, private route: ActivatedRoute,
    private notifyService: NotificationService, private downloadService: DownloadProgressService, private domSanitizer: DomSanitizer) { }

  ngOnInit() {
    this.getProjectDetail();
  }


  getProjectDetail(reload = true) {
    this.headersStations = [];
    this.headersStationIcons = [];
    this.footerSum = 0;
    this.footersStations = [];
    this.bodyStationsMaxLenght = 0;
    this.manufactureProjectService.getProjectDetailsForStations(this.projectId, reload)
      .pipe(first())
      .subscribe(
        (result: GetManufactureProjectStationsModel) => {
          this.dto = result;
          this.totalAreaRequiredProdFloorChange.emit(result.assembly.totalAreaRequiredProdFloor);
          for (var i = 0; i < this.dto.assembly.stationItems.length; i++) {
            if (this.dto.assembly.stationItems[i].length > 0) {
              
              var existAuto = this.dto.assembly.stationItems[i].filter(x => x.type == 0).length>0;
              var existManual = this.dto.assembly.stationItems[i].filter(x => x.type == 1).length > 0;
              this.headersStationIcons.push((existAuto && existManual) ? "manualAuto" : (existAuto ? "auto" : "manual"));
              this.headersStationPartImages
              this.headersStations.push(this.dto.assembly.stationItems[i][0].stationNumber);

              this.footersStations.push(this.dto.assembly.stationItems[i][0].time);
              this.footerSum += this.dto.assembly.stationItems[i][0].time;
              for (var j = 0; j < this.dto.assembly.stationItems[i].length; j++) {
                if (this.bodyStationsMaxLenght < this.dto.assembly.stationItems[i].length) {
                  this.bodyStationsMaxLenght = this.dto.assembly.stationItems[i].length;
                }
              }

            }
          }
        },
        () => {

        });
  }

  domSanitize(image) {
    if (image) {
      return this.domSanitizer.bypassSecurityTrustUrl(this.downloadService.getImage(image));
    }
  }

  counter(i: number) {
    return new Array(i);
  }

  getStationCellValue(r: number, c: number): {} {
    if (this.dto.assembly.stationItems[c]) {
      if (this.dto.assembly.stationItems[c][r]) {
        return {
          text: '<img height=40 src="assets/images/' + this.dto.assembly.stationItems[c][r].description +'.png"/> &nbsp;&nbsp;' + this.dto.assembly.stationItems[c][r].partName,
          color: (this.dto.assembly.stationItems[c][r].descriptionType == ProductLineTaskEnum.pickAndPlace ? '#00FFFF' : '#00FF00'),
          title: this.dto.assembly.stationItems[c][r].partName
        };
      }
    }

    return { text: '', color: '' };
  }

  fixtureRequired(c: number): boolean {
    if (this.dto.assembly.stationItems[c]) {
      return this.dto.assembly.stationItems[c].filter(x => x.fixtureRequired).length > 0;
    }
    return false;
  }

  getStationPartsImages(c: number): string[] {
    if (this.dto.assembly.stationItems[c]) {
      var res = [];
      var list = this.dto.assembly.stationItems[c].map(x => x.printImagePath);
      for (var img in list) {
        if (res.indexOf(list[img]) == -1) {
          res.push(list[img]);
        }
      }
      return res;
    }
    return [];
  }
}
