import { Component, OnInit } from '@angular/core';
import { ManufactureProjectService } from '../manufacture-services/manufacture-project.service';
import { first, repeat } from 'rxjs/operators';
import { Router, ActivatedRoute } from '@angular/router';
import { GetManufactureProjectDetailsModel } from '../../models/manufacture-project-details';
import { ChartDataSets, ChartOptions } from 'chart.js';
import { Color, Label } from 'ng2-charts';
import { ProjectStatistic } from '../../models/manufacture-project-statistic';
import { ProjectStatisticMonth } from '../../models/manufacture-project-statistic-month';

declare var $: any;

declare function asmClick(): any;
declare function disamClick(): any;
declare function pauseClick(): any;
declare function repeatClick(): any;
declare function continueClick(): any;
declare function nextStepClick(): any;
declare function prevStepClick(): any;
declare function isNextPrevEnabled(isNext): any;
declare function STLViewerEnable(name, partListPaths, isAsembly): any;

@Component({
  selector: 'app-manufacture-statistics',
  templateUrl: './manufacture-statistics.component.html',
  styleUrls: ['./manufacture-statistics.component.css']
})
export class ManufactureStatisticsComponent implements OnInit {


  sums: number[] = [];
  sumsMonth: number[] = [];
  constructor(private manufactureProjectService: ManufactureProjectService, private router: Router, private route: ActivatedRoute) { }

  ticksMax = 0;
  ticksMaxMonth = 0;

  getTicksMax(data: ProjectStatistic[]) {
    let ticksMax = 0;
    data.forEach((p) => {
      if (p.accepted > ticksMax) {
        ticksMax = p.accepted
      }
      if (p.delivered > ticksMax) {
        ticksMax = p.delivered
      }
      if (p.submitted > ticksMax) {
        ticksMax = p.submitted
      }
      if (p.rejected > ticksMax) {
        ticksMax = p.rejected
      }
    });
    return ticksMax;
  }

  getTicksMaxMonth(data: ProjectStatisticMonth[]) {
    let totalCostOfMonth = 0;
    data.forEach((p) => {
      if (p.totalCostOfMonth > totalCostOfMonth) {
        totalCostOfMonth = p.totalCostOfMonth
      }
    });
    return totalCostOfMonth;
  }

  ngOnInit() {
    this.manufactureProjectService.getProjectStatistics().subscribe((data: any) => {
      var allValues = [];

      this.ticksMax = this.getTicksMax(data);

      this.lineChartLabels = [];
      this.chartData1[0].data = [];
      this.chartData1[1].data = [];
      this.chartData1[2].data = [];
      this.chartData1[3].data = [];
      this.sums = [0, 0, 0, 0];
      var max = 0;
      data.forEach((item: any) => {
        this.lineChartLabels.push(item.label);
        this.chartData1[0].data.push(item.submitted);
        this.sums[0] += item.submitted;
        this.chartData1[1].data.push(item.accepted);
        this.sums[1] += item.accepted;
        this.chartData1[2].data.push(item.delivered);
        this.sums[2] += item.delivered;
        this.chartData1[3].data.push(item.rejected);
        this.sums[3] += item.rejected;
        allValues.push[item.submitted];
        allValues.push[item.accepted];
        allValues.push[item.delivered];
        allValues.push[item.rejected];
      });
      const maxValue = Math.max(...allValues);

      this.barChartOptions = {
        scaleShowVerticalLines: true,
        responsive: true,
        maintainAspectRatio: false,
        animation: {
          onComplete: function () {
            var chartInstance = this.chart,
              ctx = chartInstance.ctx;

            ctx.textAlign = 'center';
            ctx.textBaseline = 'bottom';

            this.data.datasets.forEach(function (dataset, i) {
              var meta = chartInstance.controller.getDatasetMeta(i);
              meta.data.forEach(function (bar, index) {
                var data = dataset.data[index];
                ctx.fillText(data, bar._model.x, bar._model.y - 5);
              });
            });
          }
        },
        plugins: {
          datalabels: {
            display: true,
            align: 'center',
            anchor: 'center'
          }
        },
        scales: {
          xAxes: [{
            stacked: false,
            stackLabels: {
              enabled: true
            }
          }],
          yAxes: [{
            display: true,
            stacked: false,
            ticks: {
              beginAtZero: true,
              max: Math.ceil(maxValue * 1.05)
            }
          }]
        }
      };


      this.chartData1[0].label = "Submitted " + this.sums[0];
      this.chartData1[1].label = "Accepted " + this.sums[1];
      this.chartData1[2].label = "Delivered " + this.sums[2];
      this.chartData1[3].label = "Rejected " + this.sums[3];

      this.barChartOptions.scales.yAxes[0].ticks.max = this.ticksMax;
    })

    this.manufactureProjectService.getProjectStatisticsMonth().subscribe((data: ProjectStatisticMonth[]) => {
      var allValues = [];

      this.ticksMaxMonth = this.getTicksMaxMonth(data);

      this.lineChartLabels2 = [];
      this.chartData2[0].data = [];

      this.sumsMonth = [0];

      data.forEach((item: ProjectStatisticMonth) => {
        this.lineChartLabels2.push(item.label);
        this.chartData2[0].data.push(item.totalCostOfMonth);
        this.sumsMonth[0] += item.totalCostOfMonth;
        allValues.push[item.totalCostOfMonth];
      });
      const maxValue = Math.max(...allValues);

      this.barChartOptions2 = {
        scaleShowVerticalLines: true,
        responsive: true,
        maintainAspectRatio: false,
        animation: {
          onComplete: function () {
            var chartInstance = this.chart,
              ctx = chartInstance.ctx;

            ctx.textAlign = 'center';
            ctx.textBaseline = 'bottom';

            this.data.datasets.forEach(function (dataset, i) {
              var meta = chartInstance.controller.getDatasetMeta(i);
              meta.data.forEach(function (bar, index) {
                var data = dataset.data[index];
                ctx.fillText(data, bar._model.x, bar._model.y - 5);
              });
            });
          }
        },
        plugins: {
          datalabels: {
            display: true,
            align: 'center',
            anchor: 'center'
          }
        },
        scales: {
          xAxes: [{
            stacked: false,
            stackLabels: {
              enabled: true
            }
          }],
          yAxes: [{
            display: true,
            stacked: false,
            ticks: {
              beginAtZero: true,
              max: Math.ceil(maxValue * 1.05)
            }
          }]
        }
      };

      this.chartData2[0].label = "Total Cost";
      this.barChartOptions2.scales.yAxes[0].ticks.max = this.ticksMaxMonth;
    })
  }

  chartData1: ChartDataSets[] = [
    { data: [65, 59, 80, 81, 56, 55, 40], label: 'Submitted' },
    { data: [28, 48, 40, 19, 86, 52, 26], label: 'Accepted' },
    { data: [12, 63, 13, 19, 13, 13, 90], label: 'Delivered' },
    { data: [72, 35, 26, 19, 32, 27, 13], label: 'Rejected' }
  ];

  chartData2: ChartDataSets[] = [
    { data: [65, 59, 80, 81, 56, 55, 40], label: 'Total Revenues' },
  ];

  //Labels shown on the x-axis
  lineChartLabels: Label[] = ['January', 'February', 'March', 'April', 'May', 'June', 'July'];
  lineChartLabels2: Label[] = [];

  public barChartOptions: any = { legend: { display: true, labels: { fontColor: 'black' } } };
  public barChartOptions2: any = { legend: { display: true, labels: { fontColor: 'black' } } };

  // Define chart options
  lineChartOptions: ChartOptions = {
    responsive: true
  };

  // Define colors of chart segments
  barChartColors: Color[] = [

    { // dark grey
      backgroundColor: 'rgba(175,171,171,1)',
      borderColor: 'rgba(47,83,143,1)',
    },
    {
      backgroundColor: 'rgba(48,114,196,1)',
      borderColor: 'rgba(47,82,143,1)',
    },
    {
      backgroundColor: 'rgba(0,176,80,1)',
      borderColor: 'rgba(47,82,143,1)',
    },
    { // red
      backgroundColor: 'red',
      borderColor: 'rgba(47,82,143,1)',
    }

  ];
  barChartColors2: Color[] = [


    {
      backgroundColor: 'rgba(0,176,80,1)',
      borderColor: 'rgba(47,82,143,1)',
    }

  ];
  // events
  chartClicked({ event, active }: { event: MouseEvent, active: {}[] }): void {
    console.log(event, active);
  }

  chartHovered({ event, active }: { event: MouseEvent, active: {}[] }): void {
    console.log(event, active);
  }
}
