import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { UserRole } from '../models/roles';
import { User } from '../models/user';
import { AuthService } from '../services/auth.service';

@Component({
  selector: 'access-denied',
  templateUrl: './access-denied.component.html',
  styleUrls: ['./access-denied.component.css']
})

export class AccessDeniedComponent {
  userData = new User();

  constructor(private authService: AuthService, private router: Router) {
    this.authService.userData.asObservable().subscribe(data => {
      this.userData = data;
    });
  }

  goToDashboard() {
    if (this.userData.isLoggedIn) {
      if (this.userData.role === UserRole.User) {
        this.router.navigate(['/dashboard']);
      } else if (this.userData.role === UserRole.ManufactureUser) {
        this.router.navigate(['/manufacture-dashboard']);
      } else if (this.userData.role === UserRole.Admin) {
        this.router.navigate(['/admin-dashboard-approve']);
      }
    }
  }
}
