import { Component, OnInit, Renderer, Renderer2 } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { first } from 'rxjs/operators';
import { AuthService } from '../../services/auth.service';
import { CustomvalidationService } from '../../services/customvalidation.service';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css', '../login-common.css']
 
})
export class RegisterComponent implements OnInit {

  loading = false;
  loginForm: FormGroup;
  submitted = false;
  returnUrl: string;
  step = 1;
  agree = false;
  constructor(private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private renderer: Renderer2,
    private customValidator: CustomvalidationService,
    private authService: AuthService) { }

  ngOnInit() {
    this.loginForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]],
      password: ['', Validators.compose([Validators.required, this.customValidator.patternValidator()])],
      confirmpassword: ['', Validators.required],
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      zipCode: [''],
      phoneNumber: ['', Validators.required],
      company: [''],
      agree: [null, Validators.required],
      isCustomer:[true]
    },
      {
        validator: this.customValidator.MatchPassword('password', 'confirmPassword'),
      });
    this.addScriptToElement('https://js.hs-scripts.com/20575033.js').onload = () => {
      console.log('Loaded SkyScanner tag successfully!');
    }
  }

  get registerFormControl() {
    return this.loginForm.controls;
  }

  addScriptToElement(src: string): HTMLScriptElement {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = src;
    this.renderer.appendChild(document.body, script);
    //use removeChild to remove the added tag on your condition
    return script;
  }

  get loginFormControl() { return this.loginForm.controls; }

  get activeContinue() { return !this.loginFormControl.email.valid || !this.loginFormControl.lastName.valid || !this.loginFormControl.firstName.valid; }

  onSubmit() {
    this.submitted = true;

    if (this.loginForm.invalid) {
      return;
    }

    this.loading = true;
    this.authService.register(this.loginForm.value)
      .pipe(first())
      .subscribe(
        (result: string) => {
          this.loading = false;
           if (result) {
            this.loginForm.setErrors({
              invalidLogin: true,
              errors: result
            });
          }
          else {
            this.loginForm.setErrors({
              invalidLogin: false,
              success: true,
              errors: []
            });
             localStorage.setItem("registerEmail", this.loginForm.get('email').value);
             this.router.navigate(['/registered']);
          }
        },
        (result: string) => {
          this.loading = false;
          //this.loginForm.reset();
          this.loginForm.setErrors({
            invalidLogin: true
          });
        });
  }
  goToPage(pageRour: string) {
    this.router.navigate([pageRour]);
  }

  toNextStep() {
    if (this.loginForm.get('firstName').value && this.loginForm.get('lastName').value && this.loginForm.get('email').value && this.loginForm.get('email').valid) {
      this.step = 2;
      this.authService.register1(this.loginForm.value)
        .subscribe(
          (result: string) => { });
        
    }
  }
  keyDownFunction() {
    this.loginForm.get("agree").setValue(this.loginForm.get('agree').value ? false : true);
    this.agree = this.loginForm.get('agree').value;
  }
  back() {
    this.step = 1;
  }
}
