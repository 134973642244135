import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { NotificationService } from 'src/app/common/services/notification.service';
import { debug } from 'util';

@Injectable({
  providedIn: 'root'
})
export class ErrorInterceptorService implements HttpInterceptor {

  constructor(private authService: AuthService, private notifyService: NotificationService) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(catchError(err => {

      if (err.status === 200) {
        return;
      }

      if (err.status === 403) {
        this.notifyService.showError("You don't have permission for this action", null);
        return;
      }

      
      if (err.status === 409) {
        this.notifyService.showWarning(err.error, null)
      }
      else if (err.status === 401) {
        this.authService.logout();
      }
      else {
        this.notifyService.showError("something went wrong", null);
      }

      console.log(err);
      return throwError(err);
    }));
  }
}
