import { Injectable } from '@angular/core';
import * as signalR from "@aspnet/signalr";  // or from "@microsoft/signalr" if you are using a new library
import { Observable, BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SignalRService {
  private callbackProjects: BehaviorSubject<any>;
  private callbackIssues: BehaviorSubject<any>;
  private onScroll: BehaviorSubject<any>;
  private hubConnection: signalR.HubConnection;
  private inteval: any;
  private userId: any;
  private connected: boolean;
  private isSockets: boolean = true;
  private timeout: number = 10000;
  public startConnection = (userId) => {
    this.userId = userId;
    this.startOldLogic();
    if (this.hubConnection == null) {
      const options: any = {
        transport: signalR.HttpTransportType.LongPolling,
        accessTokenFactory: () => 'my token'
      };
      this.callbackProjects;
      this.callbackIssues;
      this.hubConnection = new signalR.HubConnectionBuilder()
        .withUrl('/eventHub', options)
        .build();
      var hub = this.hubConnection;
      hub.onclose((er) => {
        this.connected = false;
        console.log('Connection closed')
        

        this.hubConnection
          .start()
          .then(() => {
            this.connected = true;
            console.log('Connection started'); clearInterval(this.inteval);
            this.hubConnection.invoke("Subscripbe", userId );
          })
          .catch(err => {
            this.connected = false;
            console.log('Error while starting connection: ' + err)
          })
      });
      
      
    }
    if (!this.connected) {
      this.hubConnection
        .start()
        .then(() => {
          this.connected = true;
          console.log('Connection started');
          if (userId) {
            this.hubConnection.invoke("Subscripbe", userId);
          }
          clearInterval(this.inteval);
        })
        .catch(err => {
          this.connected = false;
          console.log('Error while starting connection: ' + err)
        })
    }
  }
  public addListenerRefreshProjects = () => {
    if (this.callbackProjects == null) {
      this.callbackProjects = new BehaviorSubject<any>(null);
      this.hubConnection.on('RefreshProjects', (data) => {
        if (this.userId) {
          this.callbackProjects.next(data);
        }
        console.log(data);
      });
    }
    return this.callbackProjects.asObservable();
  }
  
  public addListenerRefreshIssues = () => {
    if (this.callbackIssues == null) {
      this.callbackIssues = new BehaviorSubject<any>(null);
      this.hubConnection.on('RefreshIssues', (userId, isNewMessenge) => {
        if (this.userId) {
          this.callbackIssues.next({ userId: userId, isNewMessenge: isNewMessenge });
        }
      });
    }
    return this.callbackIssues.asObservable();
  }

  public addScrollEvent = () => {
    if (this.onScroll == null) {
      this.onScroll = new BehaviorSubject<any>(null);
    }
    return this.onScroll.asObservable();
  }

  public scroll = ($event) => {
    if (this.onScroll != null) {
      this.onScroll.next($event);
    }
  }

  public startOldLogic = () => {
    
    this.inteval = setTimeout(() => {

      if (this.userId && !this.connected) {
        this.callbackIssues.next(null);
        this.callbackProjects.next(null);
        this.timeout += 10;
      }
      this.startOldLogic();
    }, this.timeout);
    
  }

  
}
