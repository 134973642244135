import { ClassifierTypeEnum } from "../common/enums/classifier-type-enum";
import { IssueTypeEnum } from "../common/enums/issue-type-enum";
import { ProjectDetailFieldTypeEnum } from "../common/enums/project-detail-field-type-enum";


export interface ProjectFileZippedModel {
  projectId: number;
  stlFiles: string;
  imageFiles: string;
}

export interface ProjectDetailsFullModel {
  details: GetProjectDTO;
  subProjects: GetProjectDTO[];
  partDetails: ProjectDetailPageDto;
  projectIssues: ProjectIssueDto[];
  prices: PriceCalculatorDto;
  orderSummary: OrderSummaryDto;
  models3d: any;
  blobs: ProjectDetailBlob[]
}

export interface ProjectDetailBlob {
  name: string;
  blobUrl: string
}

export interface OrderSummaryDto {
  projectId?: number;
  volume: number;
  totalCost: number | null;
  lastUpdatedDatePrice: string;
  costPerUnit: number;
  zipCode: string;
  deliveryMethod: number;
  deliveryIn: string;
  priceStatus: number;
  priceProgress: number;
  readonly: boolean;
  notIncludePrices: string;
  approvedByAdmin: boolean;
}

export interface GetProjectDTO {
  id: number;
  name: string;
  uid: string;
  readonly: boolean;
  zipFileName: string;
  parts: ProjectPartDTO[];
  files: FileProjectDTO[];
  assignedManufacturedId: string;
  approvedByAdmin: any;
  projectStatus: number;
  model3d: Object3dModel[];
}

export interface FileProjectDTO {
  name: string;
}

export interface ProjectPartDTO {
  id: number;
  partId: string;
  name: string;
  pathImg: string;
  handling: string;
  inspection: string;
  color: string;
}

export interface Object3dModel {
  pathToSTLFile: string;
  direction: string;
  sequence: number;
  bbox: Bbox;
  groupId: number;
  color: string;
  maxPosition: MaxPosition;
}

export interface MaxPosition {
  x: number;
  y: number;
  z: number;
}

export interface Lower {
  x: number;
  y: number;
  z: number;
}

export interface Upper {
  x: number;
  y: number;
  z: number;
}

export interface Bbox {
  dims: number[];
  lower: Lower;
  upper: Upper;
}

export interface ProjectDetailPageDto {
  buy: ProjectDetailDto[];
  nonStandardBuy: ProjectDetailDto[];
  make: ProjectDetailDto[];
  pcba: ProjectDetailDto[];
  cable: ProjectDetailDto[];
  unidentified: ProjectDetailDto[];
  other: ProjectDetailDto[];
  makeDropDownDefaultValues: DefaultMakeDropDownValueDto[];
  userId: string;
  projectStatus: number;
  cmUserName: string;
  acceptedAt: string | null;
  projectName: string;
}


export interface ProjectDetailDto {
  vendor: string;
  partNo: string;
  material: string;
  method: string;
  finish: string;
  description: string;
  name: string;
  type: ClassifierTypeEnum;
  projectDetailId: number;
  bomFileName: string;
  bomFile: string;
  gerberFile: string;
  d2Drawing: string;
  d2DrawingFile: string;
  d2DrawingFileName: string;
  process: string;
  color: string;
  infill: string;
  resolution: string;
  threadsAndTappedHole: string;
  insert: string;
  welding: string;
  tolerance: string;
  surfaceRoughness: string;
  partMarking: string;
  inspection: string;
  materialCOC: string;
  cISACertificationRequired: string;
  price: number | null;
  priceUpdated: string;
  measurements: string;
  sizeWidth: string;
  sizeHeight: string;
  layers: string;
  mintTraceSpacing: string;
  minHoleSize: string;
  quantity: string;
  fr4Tg: string;
  thickness: string;
  silkScreen: string;
  soldermaskColor: string;
  surfaceFinish: string;
  copperWeight: string;
  turnaround: string;
  gerberFileName: string;
  partImagePath: string;
  otherParnNo: string;
  otherVendor: string;
  otherPrice: number;
  otherFileName: string;
  otherFile: string;
  otherNotes: string;
  otherSuppliedByCustomer: boolean;
  pickPlaceFileName: string;
  pickPlaceFile: string;
  cableDrawingFileName: string;
  cableDrawingFile: string;
  maxTightningTorgue: string;
  maxSpeed: string;
  locktite_GlueRequired: string;
  cofactorBoardId: string;
  cofactorPcbaId: string;
}

export interface DefaultMakeDropDownValueDto {
  material: string;
  process: string;
  color: string;
  infill: string;
  finish: string;
  threadsAndTappedHole: string;
  insert: string;
  welding: string;
  surfaceRoughness: string;
  tolerance: string;
  partMarking: string;
  inspection: string;
  materialCOC: string;
  resolution: string;
  cISACertificationRequired: string;
}

export interface ProjectIssueDto {
  type: IssueTypeEnum;
  message: string;
  projectDetailOptionType: ProjectDetailFieldTypeEnum | null;
  classifierType: ClassifierTypeEnum;
  projectDetailId: number | null;
  partName: string;
  partImagePath: string;
  vendor: string;
  cofactorBoardId: string;
  intercomConversationId: string;
  countMessage: number | null;
}

export interface PriceCalculatorDto {
  makeItems: ItemCalculatorDto[];
  buyItems: ItemCalculatorDto[];
  nonStandartBuyItems: ItemCalculatorDto[];
  cableItems: ItemCalculatorDto[];
  pcbaItems: ItemCalculatorDto[];
  assembly: AssemblyCalculatorDto;
  otherExpenses: OtherExpensesDto;
  customerProposal: CustomerPriceProposalDto;
  cMProposal: CMProposalDto;
  schedule: ScheduleDto;
  shipping: ShippingDto;
  priceStatus: number;
  quantity: number;
  status: number;
  adminNotes: string;
  bom: any;
  name: string;
  quoteStatus: ProjectQuoteStatusEnum;
}

export interface ItemCalculatorDto {
  projectDetailId: number;
  name: string;
  partNumber: string;
  unitPrice: number;
  quantity: number;
  customerUnitPrice: number;
  customerUnitPriceDetails: string;
  customerTotalPrice: number;
  customerTotalPriceDetails: string;
  customerDeliveryDays: number;
  cMUnitPrice: number;
  cMUnitPriceDetails: string;
  cMTotalPrice: number;
  cMTotalPriceDetails: string;
  cMDeliveryDays: number;
  shippingPrice: number;
  priceStatus: string;
  priceApiResponse: string;
  unitPriceAuto: number;
  unitPriceManual: number;
  deliveryDays: number;
  totalPrice: number;
}

export interface AssemblyCalculatorDto {
  totalAssemblyTimeHrs: number;
  totalAssemblyTimeShifts: number;
  totalAssemblyTimeDays: number;
  totalAssemblyCost: any;
  totalAssemblyCostDetails: string;
  lPTotalAssemblyCost: number;
  fixtureRequiredCount: number;
}

export interface OtherExpensesDto {
  totalHours: number;
  warehousing: number;
  iCI: number;
  lineSetup: number;
  materialhandling: number;
  finalGoodsPackaging: number;
  projectManagement: number;
  warranty: number;
  areaOverhead: number;
  totalCost: any;
  totalCostDetails: string;
}

export interface CustomerPriceProposalDto {
  makeItemsPrice: number;
  makeItemsPriceDetails: string;
  buyItemsPrice: number;
  buyItemsPriceDetails: string;
  buyNonStandartItemsPrice: number;
  pcbaItemsPrice: number;
  cableItemsPrice: number;
  assemblyDetails: string;
  assembly: number;
  customerProposalSummaryOtherExpensesPercent: number;
  otherExpenses: number;
  otherExpensesDetails: string;
  cmMargin: any;
  cmMarginDetails: string;
  warrenty: number;
  warrentyDetails: string;
  shipping: number;
  adminBomOverride: number | null;
  totalCost: number;
  totalCostDetails: string;
  unitCost: number;
  unitCostDetails: string;
  quantity: number;
}

export interface CMProposalDto {
  makeItemsPrice: number;
  makeItemsPriceDetails: string;
  buyItemsPrice: number;
  buyItemsPriceDetails: string;
  buyNonStandartItemsPrice: number;
  pcbaItemsPrice: number;
  cableItemsPrice: number;
  assembly: number;
  assemblyDetails: string;
  otherExpenses: number;
  otherExpensesDetails: string;
  shipping: number;
  adminBomOverride: number | null;
  adminCMMarginOverride: number | null;
  total: number;
  totalDescription: string;
  cMMarginSetting: number;
  cMMargin: number;
  cMMarginDetails: string;
  totalCost: number;
  totalCostDetails: string;
  unitCost: number;
  unitCostDescription: string;
  quantity: number;
  desiredGrossMarginSetting: number;
  assignedManufacturedId: string;
}

export interface ScheduleDto {

}

export interface ShippingDto {
  makeItemsDays: number;
  makeItemsShippingPrice: number;
  buyItemsDays: number;
  buyItemsShippingPrice: number;
  buyNonStandartItemsDays: number;
  buyNonItemsShippingPrice: number;
  pcbaItemsDays: number;
  pcbaItemsShippingPrice: number;
  cableItemsDays: number;
  cableItemsShippingPrice: number;
  productionPreparation: number;
  productionAndAssembly: number;
  shipping: any;
  shippingPrice: any;
}

export enum ProjectQuoteStatusEnum {
  InProgress = 0,
  Released
}
