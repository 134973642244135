import { Component, OnInit } from '@angular/core';
import { ManufactureProjectService } from '../manufacture-services/manufacture-project.service';
import { first } from 'rxjs/operators';
import { Router, ActivatedRoute } from '@angular/router';
import { GetManufactureProjectDetailsModel } from '../../models/manufacture-project-details';
import { NotificationService } from 'src/app/common/services/notification.service';

@Component({
  selector: 'app-manufacture-other-requirments',
  templateUrl: './manufacture-other-requirments.component.html',
  styleUrls: ['./manufacture-other-requirments.component.css']
})
export class ManufactureOtherRequirmentsComponent implements OnInit {

  dto: GetManufactureProjectDetailsModel;
  projectId: number;

  constructor(private manufactureProjectService: ManufactureProjectService, private router: Router, private route: ActivatedRoute, private notifyService: NotificationService) { }

  ngOnInit() {
    this.route.params.subscribe(params => {
      this.projectId = params['id'];
      this.getProjectDetail();
    });
  }

  getProjectDetail() {
    this.manufactureProjectService.getProjectDetails(this.projectId)
      .pipe(first())
      .subscribe(
        (result: any) => {
          this.dto = result;
        },
        () => {

        });
  }

  onChange() {

    this.manufactureProjectService.saveProjectInfo(this.projectId, this.dto.specialRequirements, this.dto.cosmeticInspectionRequirements, this.dto.packagingRequirements, this.dto.functionalTestingrequirements)
      .pipe(first())
      .subscribe(
        (result: any) => {
          this.notifyService.showInfo("Saved", null);
        },
        () => {

        });
  }
}
