import { HttpClient, HttpEventType, HttpRequest } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { forkJoin, Observable, Subscription } from 'rxjs';
import { AdminProjectService } from '../admin/admin-services/admin-project.service';
import { IGetProjectVisibleScreensDto, IGetProjectVisibleScreensListDto } from '../admin/models/IGetProjectVisibleScreensListDto';
import { ISelectItemDto } from '../admin/models/ISelectItemDto';
import { ISetProjectVisibleScreensDto } from '../admin/models/ISetProjectVisibleScreensDto';
import { ProjectScreenVisibleEnum } from '../common/enums/project-scree-vnisible-enum';
import { NotificationService } from '../common/services/notification.service';
import { ProjectDetailsFullModel } from '../models/project-details-full-model';
import { UserRole } from '../models/roles';
import { User } from '../models/user';
import { AuthService } from '../services/auth.service';
import { ActionType, DownloadProgressResult, DownloadProgressService, RequestType } from '../services/download-service';
import { ProjectService } from '../services/project.service';

declare var $: any;
declare function STLViewerEnable(name, partListPaths, isAsembly, heigth, width, blobs): any;

@Component({
  selector: 'app-edit-project',
  templateUrl: './edit-project.component.html',
  styleUrls: ['./edit-project.component.css']
})
export class EditProjectComponent implements OnDestroy, OnInit {

  name: string = '';
  id: number;
  isEdit: boolean = false;
  loading: boolean = true;
  dto: ProjectDetailsFullModel;
  files: any = [];
  fileNames: any = [];
  headerName: string = '';
  selectedAll: boolean;
  file: any;
  userData: User = new User();
  userDataSubscription: Subscription;
  visibleScreensDto: IGetProjectVisibleScreensListDto;
  userRole = UserRole;
  manufactureList: ISelectItemDto[];
  blobs: any[] = [];
  progress: number = 0;

  constructor(private projectService: ProjectService, private route: ActivatedRoute, private router: Router, private http: HttpClient,
    private authService: AuthService, private adminProjectService: AdminProjectService, private notifyService: NotificationService, private downloadService: DownloadProgressService) {

    this.route.params.subscribe(params => {
      if (params['id']) {
        this.id = +params['id'];
        this.isEdit = true;
        this.loadData();
      }
    });
  }

  ngOnInit(): void {
    this.downloadService.stlsLoadData.asObservable().subscribe((data: DownloadProgressResult )=> {
      if (data) {
        this.progress = data.progress;
        if (data.finished) {
          this.blobs = data.blobs;
          setTimeout(() => {
            this.load3dModel();
          }, 10);
        }
      }
    });

    this.userDataSubscription = this.authService.userData.asObservable().subscribe(data => {
      this.userData = data;
      if (this.userData.role == UserRole.Admin) {
        this.adminProjectService.getProjectVisibleScreens(+this.id).subscribe(
          (result) => {
            this.visibleScreensDto = result;
          },
          (error) => {
          });

        this.adminProjectService.getProjectManufacture().subscribe(
          (result) => {
            this.manufactureList = result;
          },
          (error) => {
          });
      }
    });
  }

  
  loadData() {
    if (isNaN(this.id)) {
      return;
    }

    if (this.isEdit) {
      this.loading = true;
      this.projectService.getProjectDetailsFull(this.id).subscribe(
        (data) => {
          this.initData(data);
          setTimeout(() => {
               this.load3dModel();
          }, 10);
            this.loading = false;
          },
          (error) => {
          });
    }
  }

  initData(data: ProjectDetailsFullModel) {
    this.dto = data;
    this.name = this.dto.details.name;
    this.files = this.dto.details.files;

  }

  save() {

    this.loading = true;
    const formData = new FormData();

    formData.append(this.file.name, this.file);
    var matched = this.files.filter(x => x.replaced == true || x.added == true).map(x => x.name);

    formData.append("name", this.name);
    formData.append("id", this.id + '');
    formData.append("selectedFiles", matched);

    this.projectService.updateProject(formData).subscribe(event => {
      if (event.type === HttpEventType.Response) {
        this.notifyService.showInfo("Project updated", null)
        this.router.navigate(['/dashboard/']);
      }

    },
      error => {
        this.loading = false;
        
      });
  }

  goToAssembly() {
    this.router.navigate(['/assembly-project/' + this.id]);
  }

  download(name: string) {
    const uploadReq = new HttpRequest('POST', `api/project/getProjectZipFile/`, {id: this.id, name }, {
      responseType: 'blob'
    });

   this.http.request(uploadReq).subscribe(event => {
      if (event['status'] === 200) {
        if (event['body']) {
          this.downloadFile(event['body'], name);
        }
      }
    });
  }

  downloadFile(data, name) {
    const contentType = 'application/octet-stream';
    const blob = new Blob([data], { type: contentType });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = name;
    a.click();
    window.URL.revokeObjectURL(url);
  }

  load3dModel() {
    if (!this.blobs || this.blobs.length == 0 || !this.dto) {
      return;
    }

    let files = this.dto.details.model3d.filter(x => x.pathToSTLFile);
    $(".stl-modal-viewer").empty();
    $(".stl-modal-viewer").append("<div class='stlviewer' width='6000px' height='6000px' style='display:none; height: min-content; width: min-content;'></div>")

    const height = ($(window).height() / 100 * 77) - $(".warning-message-block").height();
    const width = ($(window).width() / 100 * 56) > 768 ? $(window).width() / 100 * 56 : ($(window).width() / 100 * 88); // 768 - for mob
    STLViewerEnable("stlviewer", files, false, height, width, this.blobs);
  }

  uploadFile(event) {
    for (let index = 0; index < event.length; index++) {
      this.file = event[index];
    }

    this.save();
  }

  getFileNames(file) {
    const formData = new FormData();
    formData.append(file.name, file);

    const uploadReq = new HttpRequest('POST', `api/project/getProjectZipFileNames/`, formData, {
      reportProgress: true,
    });

    this.fileNames = [];
    this.headerName = file.name;

    this.http.request(uploadReq).subscribe(event => {
      if (event['status'] === 200) {
        if (event['body']) {
          for (var i = 0; i < event['body'].length; i++) {
            this.fileNames.push({ name: event['body'][i], selected: true });
          }

          this.selectedAll = true;
          $("#myModal").modal('show');
        }
      }
    });
  }

  selectedAllFiles() {
    for (var i = 0; i < this.fileNames.length; i++) {
      this.fileNames[i].selected = this.selectedAll;
    }
  }

  OkModal(): void {

    for (var i = 0; i < this.files.length; i++) {
      this.files[i].added = false;
      this.files[i].replaced = false;
    }

    for (var i = 0; i < this.fileNames.length; i++) {
      var match = this.files.find(x => x.name === this.fileNames[i].name);
      if (match) {
        if (this.fileNames[i].selected == true) {
          match.replaced = true;
        }
      }
      else {
        if (match) {
          match.added = true;
        }
      }
    }
    $("#myModal").modal('hide');
    this.save();
  }

  hideModal(): void {
    $("#myModal").modal('hide');
    $('#deleteModal').modal('hide');
    $('#approveModal').modal('hide');
    $('#assignModal').modal('hide');
  }

  isDisabledSave() {
    return !this.files.some((v) => { return v.replaced || v.added });
  }


  setVisibleScreen(item: IGetProjectVisibleScreensDto, $event) {
    $event.stopPropagation();
    $event.preventDefault();

    item.isEnable = !item.isEnable;

    let enabled: ProjectScreenVisibleEnum[] = [];
    for (var i = 0; i < this.visibleScreensDto.items.length; i++) {
      if (this.visibleScreensDto.items[i].isEnable) {
        enabled.push(this.visibleScreensDto.items[i].visibleScreen);
      }
    }
    let data: ISetProjectVisibleScreensDto = { projectId: this.id, enable: enabled };
    this.adminProjectService.setProjectVisibleScreens(data).subscribe((result) => {
      this.notifyService.showInfo("Saved", null);
    });
  }

  openDeleteProjectModal() {
    $('#deleteModal').modal('show');
  }

  openApproveProjectModal() {
    $('#approveModal').modal('show');
  }
  openAssignProjectModal() {
    $('#assignModal').modal('show');
  }

  approveProject() {
    let enabled: ProjectScreenVisibleEnum[] = [];
    for (var i = 0; i < this.visibleScreensDto.items.length; i++) {
      this.visibleScreensDto.items[i].isEnable = true
      enabled.push(this.visibleScreensDto.items[i].visibleScreen);
    }

    let data: ISetProjectVisibleScreensDto = { projectId: this.id, enable: enabled };
    this.adminProjectService.setProjectVisibleScreens(data).subscribe((result) => {
      this.adminProjectService.approveProject(data).subscribe((result) => {
        this.dto.details.approvedByAdmin = result;
        this.notifyService.showInfo("Approved", null);
        this.hideModal();
      });
    });
  }

  deleteProject() {
    this.adminProjectService.deleteProject({ projectId: this.id }).subscribe((result) => {
      this.router.navigate(['/admin-dashboard-approve']);
      this.hideModal();
    });
  }

  assignProject() {
    this.adminProjectService.setProjectManufacture({ manufactureId: this.dto.details.assignedManufacturedId, projectId: this.id, userId: '' }).subscribe((result) => {
      this.notifyService.showInfo("Assigned", null);
      this.hideModal();
    });;
  }

  ngOnDestroy(): void {
    this.projectService.clearProjectDetails();
    this.userDataSubscription.unsubscribe();
  }
}
