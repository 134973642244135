import { Component, OnInit } from '@angular/core';
import { ManufactureProjectService } from '../manufacture-services/manufacture-project.service';
import { first } from 'rxjs/operators';
import { Router, ActivatedRoute } from '@angular/router';
import { GetManufactureProjectDetailsModel } from '../../models/manufacture-project-details';
import { NotificationService } from 'src/app/common/services/notification.service';

@Component({
  selector: 'app-manufacture-view-price',
  templateUrl: './manufacture-view-price.component.html',
  styleUrls: ['./manufacture-view-price.component.css']
})
export class ManufactureViewPricesComponent implements OnInit {

  dto: GetManufactureProjectDetailsModel;
  projectId: number;
  slideIndex = 1;
  currentItemTypeShow: string;
  loading: boolean = true;

  constructor(private manufactureProjectService: ManufactureProjectService, private router: Router, private route: ActivatedRoute, private notifyService: NotificationService) { }

  ngOnInit() {
    this.route.params.subscribe(params => {
      this.projectId = params['id'];
      this.getProjectDetail();
    });
  }

  getProjectDetail() {
    this.manufactureProjectService.getProjectDetails(this.projectId)
      .pipe(first())
      .subscribe(
        (result: any) => {
          this.dto = result;
          this.loading = false;
          setTimeout(() => {
            this.showDivs(this.slideIndex);
          }, 100)
        },
        () => {
          this.loading = false;
        });
  }

  selectedChnaged(item) {
    item.selected = !item.selected;
    this.manufactureProjectService.saveSelectManufacture(item.id, item.selected)
      .pipe(first())
      .subscribe(
        (result: any) => {
          this.notifyService.showInfo("Saved", null);
        },
        () => {

        });
  }

  plusDivs(n) {
    this.showDivs(this.slideIndex += n);
  }

  showDivs(n) {
    let i;
    var x = document.getElementsByClassName("mySlides");
    if (n > x.length) { this.slideIndex = 1 }
    if (n < 1) { this.slideIndex = x.length }
    for (i = 0; i < x.length; i++) {
      x[i]['style'].display = "none";
    }
    x[this.slideIndex - 1]['style'].display = "block";

    if (this.slideIndex == 1) {
      this.currentItemTypeShow = "Make Items";
    } else if (this.slideIndex == 2) {
      this.currentItemTypeShow = "Standard buy Items";
    } else if (this.slideIndex == 3) {
      this.currentItemTypeShow = "Non standard buy Items";
    } else if (this.slideIndex == 4) {
      this.currentItemTypeShow = "PCBA Items";
    } else if (this.slideIndex == 5) {
      this.currentItemTypeShow = "Cable Items";
    }
  }
}
