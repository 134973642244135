import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { forkJoin, Subscription } from 'rxjs';
import { first } from 'rxjs/operators';
import { NotificationService } from 'src/app/common/services/notification.service';
import { AdminProjectService } from '../admin/admin-services/admin-project.service';
import { ProjectDetailOptionFileEnum } from '../common/enums/project-detail-option-file-enum';
import { ProjectDetailTypeEnum } from '../common/enums/project-detail-type-enum';
import { ProjectScreenVisibleEnum } from '../common/enums/project-scree-vnisible-enum';
import { KeyValuePair } from '../common/model/key-value-pair';
import { EnumUtils } from '../common/utils/enum-utils';
import { Utils } from '../common/utils/utils';
import { Zip } from '../common/utils/zip';
import { ProjectTypeSwitcherModel } from '../components/project-type-switcher/project-type-switcher-model';
import { GetProjectDTO, ProjectDetailDto, ProjectDetailsFullModel } from '../models/project-details-full-model';
import { User } from '../models/user';
import { AuthService } from '../services/auth.service';
import { DictionaryService } from '../services/dictionary.service';
import { ActionType, DownloadProgressService, RequestType } from '../services/download-service';
import { ExportService } from '../services/export.service';
import { ProjectService } from '../services/project.service';
import { CableItemModel } from './models/cableItemModel';
import { MakeDropDownDefaultModel } from './models/make-drop-down-default-model';
import { MakeItemModel } from './models/MakeItemModel';
import { PcbaItemModel } from './models/PcbaItemModel';

declare var $: any;
declare function STLViewerEnable(name, partListPaths, isAsembly): any;
declare function runCofactor(element, userId, customerId, boardId): any;

export class MakeItemOptionToShow {
  name: string;
  color: string;
  backgroundColor: string;
  value: string;
}

@Component({
  selector: 'app-details',
  templateUrl: './details.component.html',
  styleUrls: ['./details.component.css']
})
export class DetailsComponent implements OnDestroy, OnInit {
  id: any;
  buyItems: any[] = [];
  dto: GetProjectDTO;
  nonStandardBuyItems: ProjectDetailDto[] = [];
  makeItems: MakeItemModel[] = [];
  cableItems: CableItemModel[] = [];
  pcbaItems: PcbaItemModel[] = [];
  otherItems: ProjectDetailDto[] = [];
  unidentifiedItems: ProjectDetailDto[] = [];
  headerName: string;
  undefinedValues: KeyValuePair[] = [];
  makeDropDownValueDtos: [];
  makeDropDownValueProcess: [];
  makeDataEdit: MakeItemModel = {
    optionsToShow: []
  };

  isAnyChangeMakeDataEdit = false;
  buyDataEdit: any = {};
  makeDropDownDefaultModel: MakeDropDownDefaultModel[] =[];
  makeItemsCopy: MakeItemModel[] = [];
  vendorItemsAll = [{ value: 'digikey', text: 'Digikey' }, { value: 'mcmaster', text: 'McMaster' }] as any;
  loading: boolean = false;
  readonly: boolean;
  changeTypeModel: any = {};
  changeProjectTypeSwitcherModel: ProjectTypeSwitcherModel;
  pageHeader: string = '';
  pageHeaderSub: string = '';
  projectStatus: number;
  userDataSubscription: Subscription;
  userData = new User();
  projectName: string;
  exportExcelInProgress: boolean = false;
  dispayScreewModal = false;
  projectDetailsFullModel: ProjectDetailsFullModel;

  constructor(private authService: AuthService, private projectService: ProjectService,
    private router: Router, private dictionaryService: DictionaryService,
    private fb: FormBuilder, private notifyService: NotificationService,
    private adminProjectService: AdminProjectService, private route: ActivatedRoute,
    private downloadService: DownloadProgressService, private domSanitizer: DomSanitizer  ) {

    this.route.params.subscribe(params => {
      this.id = +params['id'];
      this.loadData(false);
    });

    if (localStorage.getItem('projectId')) {
      this.id = +localStorage.getItem('projectId');
    }
    this.undefinedValues = EnumUtils.getProjectDetailTypes();

    this.userDataSubscription = this.authService.userData.asObservable().subscribe(data => {
      this.userData = data;
      this.adminProjectService.getProjectVisibleScreens(this.id).subscribe(
        (result) => {
          
          this.readonly = result.isDemoMode
        },
        (error) => {

        });
    });
  }

  ngOnInit(): void {
   
  }

  loadData(isReload: boolean = false) {
    !isReload ? this.loading = true : null;
    this.projectService.getProjectDetailsFull(this.id, isReload)
      .subscribe(
        (result: ProjectDetailsFullModel) => {
          !isReload ? this.loading = false : null;
          this.initData(result);
          this.projectDetailsFullModel = result;
          this.readonly = result.details.readonly;
          this.getMakeItemDropDownValues();
        },
        (error) => {
          !isReload ? this.loading = false : null;
        });

  }


  initData(data: ProjectDetailsFullModel) {
    this.dto = data.details;
  }

  getMakeItemDropDownValues() {
    this.dictionaryService.getMakeDdlValues().subscribe(
      (result: string) => {
        this.makeDropDownValueDtos = result["makeDropDownValueDtos"];
        this.makeDropDownValueProcess = result["processes"];
           this.getDetails(false);
      }
    );
  }

  domSanitize(part) {
    if (part) {
      return this.domSanitizer.bypassSecurityTrustUrl(this.downloadService.getImage(part.partImagePath));
    }
  }

  setUndefinedType(model: ProjectTypeSwitcherModel) {
    this.projectService.saveProjectDetailType(model.projectDetailId, model.type, this.id).subscribe(
      (result: string) => {
        this.loadData(true);
      }
    );
  }

  savePart(model, type) {
    var data = { quantity: 1};
    let typeInt = 0;
    const isListFinishLength = model && model.finishs && model.finishs.length > 0 ? true : false;
    if (type == 'buy') {
      typeInt = ProjectDetailTypeEnum.Buy;
      if (model.vendor == "digikey") {
        data["digiKey"] = model.partNo;
      }
      else {
        data["mcMasterKey"] = model.partNo;
      }
    }
    else if (type == 'screw') {
      typeInt = ProjectDetailTypeEnum.Screw;
      data["maxTightningTorgue"] = model.maxTightningTorgue;
      data["maxSpeed"] = model.maxSpeed;
      data["locktite_GlueRequired"] = model.locktite_GlueRequired;
    }
    else if (type == 'other') {
      typeInt = ProjectDetailTypeEnum.Other;
      
      data["otherSuppliedByCustomer"] = model.otherSuppliedByCustomer;
      data["otherVendor"] = model.otherVendor;
      data["otherParnNo"] = model.otherParnNo;
      data["otherPrice"] = model.otherPrice;
      data["otherNotes"] = model.otherNotes;
      data["noRecalcPrices"] = true;
    }

    else if (type == 'make') {
      typeInt = ProjectDetailTypeEnum.Make;
      data["manufacturingProcess"] = model.process;
      data["material"] = model.material;
      data["finish"] = model.finish;
      data["color"] = model.color;
      data["infill"] = model.infill;
      data["threadsAndTappedHoles"] = model.threadsAndTappedHole;
      data["inserts"] = model.insert;
      data["surfaceRoughness"] = model.surfaceRoughness;
      data["tolerance"] = model.tolerance;
      data["welding"] = model.welding;
      data["inspection"] = model.inspection;
      data["coc"] = model.coc;
      data["materialAdditives"] = model.materialAdditives;
      data["partMarking"] = model.partMarking;
    }
    else if (type == 'pcba') {

      typeInt = ProjectDetailTypeEnum.Pcba;
      data["projectDetailId"] = model.projectDetailId;
      data["measurements"] = model.measurements;
      data["sizeWidth"] = model.sizeWidth;
      data["sizeHeight"] = model.sizeHeight;
      data["layers"] = model.layers;
      data["mintTraceSpacing"] = model.mintTraceSpacing;
      data["minHoleSize"] = model.minHoleSize;
      data["quantity"] = model.quantity;
      data["fr4Tg"] = model.fr4Tg;
      data["thickness"] = model.thickness;
      data["silkScreen"] = model.silkScreen;
      data["soldermaskColor"] = model.soldermaskColor;
      data["surfaceFinish"] = model.surfaceFinish;
      data["copperWeight"] = model.copperWeight;
      data["turnaround"] = model.turnaround;
      data["gerberFile"] = model.gerberFile;
      data["bomFile"] = model.bomFile;
    }
    else if (type == 'cable') {
      typeInt = ProjectDetailTypeEnum.Cable;
    }

    model.loading = true;

    this.projectService.savePart({ valueJson: JSON.stringify(data), type: typeInt, projectDetailId: model.projectDetailId, isListFinish: isListFinishLength }).subscribe(
      (result: any) => {
        model.loading = false;
        this.notifyService.showInfo("Saved", null)

        for (var i = 0; i < this.makeItems.length; i++) {
          this.makeItems[i].optionsToShow = this.getOptionsToShow(this.makeItems[i]);
        }

        this.loadData(true);
      }
    );
  }

  getDetails(skipTabChange: boolean) {
    if (this.projectDetailsFullModel) {
      this.buyItems = this.projectDetailsFullModel.partDetails.buy;
      this.makeItems = this.projectDetailsFullModel.partDetails.make.map((x) => {
        return {
          optionsToShow: [],
          projectDetailId: x.projectDetailId,
          name: x.name,
          process: x.process,
          partImagePath: x.partImagePath,
          material: x.material,

          color: x.color,
          infill: x.infill,
          resolution: x.resolution,
          finish: x.finish,
          threadsAndTappedHole: x.threadsAndTappedHole,
          insert: x.insert,
          welding: x.welding,
          tolerance: x.tolerance,
          surfaceRoughness: x.surfaceRoughness,
          partMarking: x.partMarking,
          inspection: x.inspection,
          materialCOC: x.materialCOC,
          cISACertificationRequired: x.cISACertificationRequired,
          d2DrawingFileName: x.d2DrawingFileName,
          price: x.price,

          materials: [],
          colors: [],
          infills: [],
          resolutions: [],
          finishs: [],
          threadsAndTappedHoles: [],
          inserts: [],
          weldings: [],
          tolerances: [],
          surfaceRoughnesses: [],
          partMarkings: [],
          inspections: [],
          materialCOCs: [],
          cISACertificationRequireds: []
        }
      });
      this.nonStandardBuyItems = this.projectDetailsFullModel.partDetails.nonStandardBuy;
      this.makeItemsCopy = JSON.parse(JSON.stringify(this.makeItems));
      for (var i = 0; i < this.makeItems.length; i++) {
        let item = this.makeItems[i];
        this.setMakeddValues(item, item.process, true);
      }

      this.projectName = this.projectDetailsFullModel.partDetails.projectName;
      this.cableItems = this.projectDetailsFullModel.partDetails.cable;
      this.pcbaItems = this.projectDetailsFullModel.partDetails.pcba;
      this.unidentifiedItems = this.projectDetailsFullModel.partDetails.unidentified;
      this.projectStatus = this.projectDetailsFullModel.partDetails.projectStatus;
      this.otherItems = this.projectDetailsFullModel.partDetails.other
      this.makeDropDownDefaultModel = this.projectDetailsFullModel.partDetails.makeDropDownDefaultValues;

      for (var i = 0; i < this.makeItems.length; i++) {
        this.makeItems[i].optionsToShow = this.getOptionsToShow(this.makeItems[i]);
      }

      if (!skipTabChange) {
        this.setActiveTab();
      }
    }
  }

  setActiveTab() {
    if (!this.pageHeader) {
    setTimeout(() => {
      if (this.makeItems && this.makeItems.length > 0) {
        $(".home").click();
        this.setTabHeaderName('Make');
      } else if (this.buyItems && this.buyItems.length > 0) {
        $(".menu1").click();
        this.setTabHeaderName('Standard Buy');
      } else if (this.nonStandardBuyItems && this.nonStandardBuyItems.length > 0) {
        $(".menu2").click();
        this.setTabHeaderName('Non-standard Buy');
      } else if (this.cableItems && this.cableItems.length > 0) {
        $(".menu3").click();
        this.setTabHeaderName('Cables');
      } else if (this.pcbaItems && this.pcbaItems.length > 0) {
        $(".menu4").click();
        this.setTabHeaderName('PCBA');
      } else if (this.otherItems && this.otherItems.length > 0) {
        $(".menu6").click();
        this.setTabHeaderName('Others');
      } else if (this.unidentifiedItems && this.unidentifiedItems.length > 0) {
        $(".menu5").click();
        this.setTabHeaderName('Un-identified');
      } else {
        $(".nav-tabs").hide();
        $(".tab-content").hide();
        $("#noData").css('display', 'block');
        this.setTabHeaderName('');
      }
    }, 100);
  }
  }

  setTabHeaderName(name: string) {
    this.pageHeader = name;
    if (name == 'Make') {
      this.pageHeaderSub = 'These items will be manufactured';
    } else if (name == 'Standard Buy') {
      this.pageHeaderSub = 'These items will be bought ';
    } else if (name == 'Non-standard Buy') {
      this.pageHeaderSub = 'These items will be bought';
    } else if (name == 'Cables') {
      this.pageHeaderSub = 'These cables will be manufactured';
    } else if (name == 'PCBA') {
      this.pageHeaderSub = 'These circuit boards will be manufactured';
    } else if (name == 'Others') {
      this.pageHeaderSub = '';
    } else if (name == 'Un-identified') {
      this.pageHeaderSub = '';
    }
  }

    getScrewImage(item) {
        if (item.maxTightningTorgue || item.maxSpeed) {
            if (item.maxTightningTorgue != 0 || item.maxSpeed != 0) {
                return 'assets/images/screew_blue.png';
    }
  }

        return 'assets/images/sreew_red.png';
            }

    //item.maxTightningTorgue != 0 && item.maxSpeed != 0? 'assets/images/screew_blue.png' : 'assets/images/sreew_red.png'"
    openScrewModal(item): void {
       
        this.headerName = item.name;
            if (item.locktite_GlueRequired) {
              if (item.locktite_GlueRequired == 'true' || item.locktite_GlueRequired == true) {
                item.locktite_GlueRequired = true;
              }
              else {
                item.locktite_GlueRequired = false;
              }
            }
            else {
              item.locktite_GlueRequired = false;
            }
            this.buyDataEdit = JSON.parse(JSON.stringify(item));

        if (!this.buyDataEdit.maxTightningTorgue) {
            this.buyDataEdit.maxTightningTorgue = 0;
          }

        if (!this.buyDataEdit.maxSpeed) {
            this.buyDataEdit.maxSpeed = 0;
        }

      $("#screewModal").modal('show');
      this.dispayScreewModal = true;
    }

  showImage3dModal(item): void {
   
    this.projectService.getStlPrtFile(this.dto.uid, item.name, item.id, null)
      .pipe(first())
      .subscribe(
        (result: any) => {
          this.headerName = item.name;
          var filePath = this.downloadService.getStl(result.path);
          $(".stl-modal-viewer").empty();
          if (result.path) {
            $(".stl-modal-viewer").append("<div class='stlviewer' data-src='" + filePath + "' data-color='" + result.color + "' width='600px' height='600px'></div>")
            STLViewerEnable("stlviewer", undefined, false);
          }
          else {
            $(".stl-modal-viewer").append("<img src='/assets/images/no-image.png' />")
          }
          $("#image3dModal").modal('show');
        },
        () => {
          //  this.loading = false;
        });
  }

  savePcbaItem(): void {
    $("#pcbaModal").modal('hide');
  }

  otherFileChangeEvent(fileInput: any, item: any) {
    if (fileInput.target.files && fileInput.target.files[0]) {
      const formData = new FormData();
      formData.append(fileInput.target.files[0].name, fileInput.target.files[0]);
      formData.append("projectDetailId", item.projectDetailId + '');
      formData.append("fileType", ProjectDetailOptionFileEnum.other + '');
      this.projectService.saveProjectDetailFile(formData).subscribe(event => {
        if (event['status'] == 200) {
          item.otherFileName = fileInput.target.files[0].name;
        }
      });
    }
  }

  cableDrawingFileChangeEvent(fileInput: any, item: CableItemModel) {
    if (fileInput.target.files && fileInput.target.files[0]) {
      const formData = new FormData();
      formData.append(fileInput.target.files[0].name, fileInput.target.files[0]);
      formData.append("projectDetailId", item.projectDetailId + '');
      formData.append("fileType", ProjectDetailOptionFileEnum.cableDrawing + '');
      this.projectService.saveProjectDetailFile(formData).subscribe(event => {
        if (event['status'] == 200) {
          item.cableDrawingFileName = fileInput.target.files[0].name;
        }
      });
    }
  }

  d2DrawingFileChangeEvent(fileInput: any, item: any) {
    if (fileInput.target.files && fileInput.target.files[0]) {
      const formData = new FormData();
      formData.append(fileInput.target.files[0].name, fileInput.target.files[0]);
      formData.append("projectDetailId", item.projectDetailId + '');
      formData.append("fileType", ProjectDetailOptionFileEnum.d2Drawing + '');
      this.projectService.uploadProjectDetailFile(formData).subscribe(event => {
        if (event['status'] == 200) {
          item.d2DrawingFileName = fileInput.target.files[0].name;
        }
      });
    }
  }
  
  hideModal(): void {
    $("#makeModal").modal('hide');
    $("#image3dModal").modal('hide');
    $("#changeTypeModal").modal('hide');
    $("#screewModal").modal('hide');
    this.dispayScreewModal = false;
    this.changeTypeModel = {};
  }

  checkAnyChangeForMakeModal() {
    this.isAnyChangeMakeDataEdit = true;
  }

  saveMakeItem(): void {
    if (!this.isAnyChangeMakeDataEdit) {
      return;
    }
    for (var i = 0; i < this.makeItems.length; i++) {
      if (this.makeItems[i].projectDetailId === this.makeDataEdit.projectDetailId) {
        this.makeItems[i] = JSON.parse(JSON.stringify(this.makeDataEdit));
      }
    }

    this.savePart(this.makeDataEdit, 'make');

    this.makeDataEdit = {
      optionsToShow: []
    };
    $("#makeModal").modal('hide');
  }

  saveScreewItem(): void {
      for (var i = 0; i < this.buyItems.length; i++) {
      if (this.buyItems[i]['projectDetailId'] === this.buyDataEdit.projectDetailId) {
        this.buyItems[i] = JSON.parse(JSON.stringify(this.buyDataEdit));
        break;
      }
    }

    this.savePart(this.buyDataEdit, 'screw');

    this.buyDataEdit = {};

    $("#screewModal").modal('hide');
    this.dispayScreewModal = false;
  }

  openMakeModal(model: MakeItemModel): void {
    this.isAnyChangeMakeDataEdit = false;
    //event.preventDefault();
    event.stopPropagation();
    this.headerName = model.name;
    this.makeDataEdit = JSON.parse(JSON.stringify(model));
    this.makeDataEdit.tolerance = this.makeDataEdit.optionsToShow && this.makeDataEdit.optionsToShow.length > 0 && this.makeDataEdit.optionsToShow[0].value ? this.makeDataEdit.optionsToShow[0].value : this.makeDataEdit.tolerance;

    //if (this.makeDataEdit && this.makeDataEdit.tolerances.length > 0) {
    //  this.makeDataEdit.tolerances.forEach((t, i) => {
    //    t = t.replace(/(\\|\/)/g, '');
    //  })
    //}

    $("#makeModal").modal('show');
  }
    
  openChangeTypeModal(model: any): void {
    //event.preventDefault();
    event.stopPropagation();
    this.changeTypeModel = model;
    $("#changeTypeModal").modal('show');
  }

  setProjectTypeSwitcherModel(model: ProjectTypeSwitcherModel) {
    this.changeProjectTypeSwitcherModel = model;
    this.saveChangeTypeModal();
  }

  saveChangeTypeModal() {
    if (this.changeProjectTypeSwitcherModel.type != this.changeTypeModel.type) {
      this.projectService.saveProjectDetailType(this.changeProjectTypeSwitcherModel.projectDetailId, this.changeProjectTypeSwitcherModel.type, this.id).subscribe(
        (result: string) => {
          this.hideModal();
          this.loadData(true);
        }
      );
    }
  }

  makeItemMaterialChanged(model, newMaterial, isSavePart: boolean = false) {
    if (newMaterial) {
      var resultList = this.makeDropDownDefaultModel.filter(
        item => item.material === newMaterial && item.process === model.process);
      let result;
      if (resultList.length) {
        result = resultList[0];
      }
      else {
        result = {material: model.material};
        
      }   
        model.process = model.process;
        model.material = result.material;
        model.color = result.color;
        model.infill = result.infill;
        model.resolution = result.resolution;
        model.finish = !model.finish ? (!result.finish ? "Select finish" : result.finish) : model.finish;
        model.threadsAndTappedHole = result.threadsAndTappedHole;
        model.insert = result.insert
        model.welding = result.welding;
        model.tolerance = result.tolerance;
        model.surfaceRoughness = result.surfaceRoughness;
        model.partMarking = result.partMarking;
        model.inspection = result.inspection;
        model.materialCOC = result.materialCOC;
        model.cISACertificationRequired = result.cISACertificationRequired;

        let activeRow = this.getActiveMakeValuesRow(model);

        this.fillMaterialDdl(model);
        model.colors = activeRow.colors;
        model.infills = activeRow.infills;
        model.resolutions = activeRow.resolutions;
        model.finishs = activeRow.finishs ? this.checkModelFinishs(activeRow.finishs) : [];
        model.threadsAndTappedHoles = activeRow.threadsAndTappedHoles;
        model.inserts = activeRow.inserts;
        model.weldings = activeRow.weldings;
        model.tolerances = activeRow.tolerances;
        model.surfaceRoughnesses = activeRow.surfaceRoughnesses;
        model.partMarkings = activeRow.partMarkings;
        model.inspections = activeRow.inspections;
        model.materialCOCs = activeRow.materialCOCs;
        model.cISACertificationRequireds = activeRow.cISACertificationRequireds;

        if (!isSavePart) {
          this.savePart(model, 'make');
        }

        this.setAvailableMaterial(model);
     
      return;
    }
  }

  finishChanged(model, newFinish, isSavePart: boolean = false) { 
    if (newFinish) {
      this.checkAnyChangeForMakeModal();
      model.finish = newFinish;
      if (!isSavePart) {
        this.savePart(model, 'make');
      }
    };
    return;
  }
  setMakeddValues(model, process, isSavePart: boolean = false) {
    this.checkAnyChangeForMakeModal();
    if (process) {

      if (!isSavePart) {
        model.material = "Select material";
      }

      var resultList = this.makeDropDownDefaultModel.filter(
        item => item.material === model.material && item.process === model.process);
      let result;
      if (resultList.length) {
        result = resultList[0];
      }
      else {
        result = { material: model.material };

      }
        model.process = process;
        model.material = result.material;
        model.color = result.color;
        model.infill = result.infill;
        model.resolution = result.resolution;
        model.finish = !model.finish ? (!result.finish ? "Select finish" : result.finish) : model.finish;
        model.threadsAndTappedHole = model.threadsAndTappedHole || result.threadsAndTappedHole;
        model.insert = model.insert || result.insert;
        model.welding = result.welding;
        model.tolerance = result.tolerance;
        model.surfaceRoughness = model.surfaceRoughness || result.surfaceRoughness;
        model.partMarking = result.partMarking;
        model.inspection = result.inspection;
        model.materialCOC = result.materialCOC;
        model.cISACertificationRequired = result.cISACertificationRequired;
    
        let activeRow = this.getActiveMakeValuesRow(model);

        this.fillMaterialDdl(model);
        model.colors = activeRow.colors;
        model.infills = activeRow.infills;
        model.resolutions = activeRow.resolutions;
        model.finishs = activeRow.finishs ? this.checkModelFinishs(activeRow.finishs) : [];
        model.threadsAndTappedHoles = activeRow.threadsAndTappedHoles;
        model.inserts = activeRow.inserts;
        model.weldings = activeRow.weldings;
        model.tolerances = activeRow.tolerances;
        model.surfaceRoughnesses = activeRow.surfaceRoughnesses;
        model.partMarkings = activeRow.partMarkings;
        model.inspections = activeRow.inspections;
        model.materialCOCs = activeRow.materialCOCs;
        model.cISACertificationRequireds = activeRow.cISACertificationRequireds;
        this.setAvailableMaterial(model);
        this.setAvailableProcess(model);
 

      if (!isSavePart) {
        this.savePart(model, 'make');
        return;
        }
    }
  }

  checkModelFinishs(modelFinishs) {
    const finishs = modelFinishs.filter(f => f == "Select finish");
    if (modelFinishs && modelFinishs.length != 0 && finishs && finishs.length == 0) {
      modelFinishs.unshift("Select finish");
    }
    return modelFinishs;
  }

  isNonDefaultMakeItemOptionValue(id: any, filedtype) {
    var originalValue = this.makeItemsCopy.filter(v => v["projectDetailId"] == id)[0][filedtype];
    var currentValue = this.makeItems.filter(v => v["projectDetailId"] == id)[0][filedtype];
    if (originalValue != currentValue) {
      return true;
    }
    else {
      return false;
    }
  }

  
  getOptionsToShow(modelMakeItem: MakeItemModel): MakeItemOptionToShow[] {
    if (!modelMakeItem.material) {
      return;
    }

    let data = [];
    let keysToCheck = ['color', 'infill', 'resolution', 'threadsAndTappedHole', 'insert', 'welding', 'tolerance', 'surfaceRoughness', 'partMarking', 'inspection', 'materialCOC', 'cISACertificationRequired'];
    let keysNames = ['Color', 'Infill', 'Resolution', 'Threads and tapped Hole', 'Insert', 'Welding', 'Tolerance', 'Surface Roughness', 'Part Marking', 'Inspection', 'Material COC', 'CISA Certification Required']
    let defaultValue = this.makeDropDownDefaultModel.filter((i) => {
      return i.material == modelMakeItem.material
    });

    if (defaultValue.length == 0) {
      return;
    }

    for (var i = 0; i < keysToCheck.length; i++) {
      if (modelMakeItem[keysToCheck[i]]) {
        if (modelMakeItem[keysToCheck[i]] == defaultValue[0][keysToCheck[i]] && defaultValue[0][keysToCheck[i]]) {
          data.push({
            name: keysNames[i],
            color: '#ffffff',
            backgroundColor: '#001b26',
            value: modelMakeItem[keysToCheck[i]]
          })
        }
        else {
          data.push({
            name: keysNames[i],
            color: '#007bff',
            backgroundColor: '#e8f0fa',
            value: modelMakeItem[keysToCheck[i]]
          })
        }
      }
    }

    data.sort((a, b) => (a.color > b.color) ? 1 : ((b.color > a.color) ? -1 : 0))
    let result = [];

    for (var i = 0; i < data.length; i++) {
      if (data[i].color == '#007bff') {
        result.push(data[i]);
      }
      else {
        if (result.length < 3 ) {
          result.push(data[i]);
        }
      }
    }

    return result;
  }

    getActiveMakeValuesRow(model): any {

    for (var i = 0; i < this.makeDropDownValueDtos.length; i++) {
      var item = this.makeDropDownValueDtos[i];
      if (item["material"] === model.material) {
        return item;
      }
    }

    return "";
  }

  getProcess(model): string {

    for (var i = 0; i < this.makeDropDownValueDtos.length; i++) {
      var item = this.makeDropDownValueDtos[i];
      if (item["material"] === model.material) {
        return item["process"];
      }
    }

    return "";
  }

  setAvailableMaterial(model): void {
    for (var i = 0; i < this.makeDropDownValueDtos.length; i++) {
      var item = this.makeDropDownValueDtos[i];
      if (item["material"] === model["material"] && item["process"] === model["process"]) {
        model.materialSelectedAvailable = item["available"];
      }
    }
  }

  setAvailableProcess(model): void {
    for (var i = 0; i < this.makeDropDownValueProcess.length; i++) {
      var item = this.makeDropDownValueProcess[i];
      if (item["name"] === model.process) {
        model.processSelectedAvailable = item["available"];
      }
    }
  }

  getFirstMaterialByProcess(process): string {
    for (var i = 0; i < this.makeDropDownValueDtos.length; i++) {
      var item = this.makeDropDownValueDtos[i];
      if (item["process"] === process) {
        return item["material"];
      }
    }

    return '';
  }

  fillMaterialDdl(model): void {
    var materials = [{ name: "Select material", available: false }];
    for (var i = 0; i < this.makeDropDownValueDtos.length; i++) {
      var item = this.makeDropDownValueDtos[i];

      if (item["process"] === model.process) {
        //if (!materials.includes(item["material"])) {
          materials.push({
            name: item["material"], available: item["available"]
          });
        //}
      }
    }

    model["materials"] = materials;
  }

  onOtherSuppliedByCustomerChanged($event, item) {
    item.otherSuppliedByCustomer = $event;
    this.savePart(item, 'other');
  }

  onLocktite_GlueRequiredChanged($event, item) {
    item.locktite_GlueRequired = $event;
  }

  updateAdminPrice(item: any) {
    this.adminProjectService.saveAdminPriceDetails({
      projectDetailId: item.projectDetailId,
      unitPrice: +item.price,
      deliveryDays: null
    }).subscribe(
      (result: any) => {
        this.loadData(true);
        this.notifyService.showInfo("Saved new price", null)
      });
  }

  ngOnDestroy(): void {
    this.userDataSubscription.unsubscribe();
  }

  pickPlaceFileChangeEvent(fileInput: any, item: PcbaItemModel) {
    if (fileInput.target.files && fileInput.target.files[0]) {
      const formData = new FormData();
      formData.append(fileInput.target.files[0].name, fileInput.target.files[0]);
      formData.append("projectDetailId", item.projectDetailId + '');
      formData.append("fileType", ProjectDetailOptionFileEnum.pickPlace + '');
      this.projectService.saveProjectDetailFile(formData).subscribe(event => {
        if (event['status'] == 200) {
          item.pickPlaceFileName = fileInput.target.files[0].name;
        }
      });
    }
  }


  bomFileChangeEvent(fileInput: any, item: PcbaItemModel) {
    if (fileInput.target.files && fileInput.target.files[0]) {
      const formData = new FormData();
      formData.append(fileInput.target.files[0].name, fileInput.target.files[0]);
      formData.append("projectDetailId", item.projectDetailId + '');
      formData.append("fileType", ProjectDetailOptionFileEnum.bom + '');
      this.projectService.saveProjectDetailFile(formData).subscribe(event => {
        if (event['status'] == 200) {
          item.bomFileName = fileInput.target.files[0].name;
        }
      });
    }
  }

  gerberFileChangeEvent(fileInput: any, item: PcbaItemModel) {
    if (fileInput.target.files && fileInput.target.files[0]) {
      const formData = new FormData();
      formData.append(fileInput.target.files[0].name, fileInput.target.files[0]);
      formData.append("projectDetailId", item.projectDetailId + '');

      formData.append("fileType", ProjectDetailOptionFileEnum.gerber + '');
      this.projectService.saveProjectDetailFile(formData).subscribe(event => {
        if (event['status'] == 200) {
          item.gerberFileName = fileInput.target.files[0].name;
        }
      });
    }
  }
}
