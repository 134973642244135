import { Component } from '@angular/core';
import { AuthService } from '../services/auth.service';
import { User } from '../models/user';
import { Router } from '@angular/router';
import { UserRole } from '../models/roles';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
})
export class HomeComponent {
  userDataSubscription: any;
  userData = new User();

  constructor(private authService: AuthService, private router: Router) {

    this.userDataSubscription = this.authService.userData.asObservable().subscribe(data => {
      this.authService.openDefaultPage();
    });
  }
}
