import { Component, OnInit, PipeTransform, Pipe, OnDestroy } from '@angular/core';
import { ManufactureProjectService } from '../manufacture-services/manufacture-project.service';
import { first } from 'rxjs/operators';
import { Router } from '@angular/router';
import { Subscription, timer } from 'rxjs';
import { User } from '../../models/user';
import { AuthService } from '../../services/auth.service';
import { SignalRService } from '../../services/signal-r.service';

@Component({
  selector: 'app-manufacture-archive',
  templateUrl: './manufacture-archive.component.html',
  styleUrls: ['./manufacture-archive.component.css']
})
export class ManufactureArchiveComponent implements OnInit, OnDestroy {
  countDown: Subscription;
  dto: any = [];
  loading: boolean = false;

  filter: any = { name: "", sort: "date desc" };
  filterApplied: any = { name: "", sort: "date desc" };
  scrollProjects: Subscription;
  userData = new User();
  userDataSubscription: Subscription;
  processingPaging: boolean = false;
  constructor(private authService: AuthService, private manufactureProjectService: ManufactureProjectService, private router: Router, private signalr: SignalRService) {
   
  }

  tick = 1000;
  ngOnInit() {
    this.userDataSubscription = this.authService.userData.asObservable().subscribe(data => {
      this.userData = data;
    });

    this.getProjects(true, 0, 30);
    this.scrollProjects = this.signalr.addScrollEvent().subscribe((data: any) => {
      if (data && (data.target.scrollTop + data.target.offsetHeight) * 1.5 > data.target.scrollHeight)
        if (this.userData.userId) {
          this.getProjects(false, this.dto.length, 10);
        }
    });
  }


  ngOnDestroy() {
    if (this.scrollProjects != null) {
      this.scrollProjects.unsubscribe();
    }

    this.userDataSubscription.unsubscribe();
  }

  filterProjects() {
    this.filterApplied.name = this.filter.name;
    this.filterApplied.sort = this.filter.sort;
    this.getProjects(true, 0, 30);
  }

  filterProjectsRefresh() {
    this.filterApplied.name = "";
    this.filterApplied.sort = "";
    this.filter.name = "";
    this.filter.sort = "date desc";
    this.getProjects(true, 0, 30);
  }

  getProjects(isShowLoading: boolean, skip, take) {

    if (isShowLoading) {
      this.loading = true;
    }
    else if (this.processingPaging) {
      return
    }
    this.processingPaging = true;

    this.countDown = timer(0, this.tick).subscribe(() => {
      if (this.dto) {
        for (var i = 0; i < this.dto.length; i++) {
          this.dto[i].secondsLeft = --this.dto[i].secondsLeft;
        }
      }
    });
    this.manufactureProjectService.getArchiveProjects(this.filterApplied.name, this.filterApplied.sort, skip, take)
      .pipe(first())
      .subscribe(
        (result: any) => {
          this.processingPaging = false;
          if (isShowLoading && skip == 0) {
            this.dto = result;
          }
          else {
            var index = skip;
            for (var item in result) {
              var project = result[item];
              var existProject = this.dto.filter(x => x.id == project.id);
              if (existProject.length > 0) {
                var existIndex = this.dto.indexOf(existProject[0]);
                this.dto[existIndex] = project
              }
              else {
                this.dto[index++] = result[item];
              }
            }
          }
          this.loading = false;
        },
        () => {
          this.loading = false;
        });
  }


  goToEditPage(id) {
    if (id) {
      this.router.navigate(['/manufacture-product-overview/' + id]);
    }
  }
}
